export const headerKeysToFilter = ['x-api-key', 'keyid'];

// filter certain `headers` properties from being logged
export const filterOptionsDetails = (options) => {
  if (!options) return {};

  try {
    const { headers = {}, ...restOptions } = options;

    const filteredHeader = Object.keys(headers).reduce((filteredObj, key) => {
      if (!headerKeysToFilter.includes(key?.toLowerCase())) {
        return { ...filteredObj, [key]: headers[`${key}`] };
      }
      return filteredObj;
    }, {});

    return { headers: { ...filteredHeader }, ...restOptions };
  } catch {
    return {};
  }
};

// organise error for logging
export const getErrorMessages = (error) => {
  if (!error) return {};

  const errorMessages = {
    status: error?.status,
    statusCode: error?.statusCode,
    statusText: error?.statusText,
    message: error?.message,
    stack: error?.stack,
  };

  return errorMessages;
};
