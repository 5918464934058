export const ROOT_ELEMENT_ID = 'root';
export const LEAD_DATA_SESSION_STORAGE_KEY = 'offlineLeadData';
export const QUESTION_ANSWERS_SESSION_STORAGE_KEY = 'offlineQuestionAnswers';
export const TYRION_GLOBAL_PARAMETERS_KEY = 'tyrionParams';
export const TYRION_GLOBAL_PARAMETERS_ORGANISATION_KEY = 'organisation';
export const TYRION_GLOBAL_PARAMETERS_PRODUCT_KEY = 'product';
export const TYRION_GLOBAL_PARAMETERS_FLOW_KEY = 'flow';
export const TYRION_GLOBAL_PARAMETERS_THEME_KEY = 'theme';
export const TYRION_GLOBAL_PARAMETERS_POLICY_CATEGORY_KEY = 'policy_category';
export const TYRION_GLOBAL_PARAMETERS_COUNTRY_KEY = 'country';
export const TYRION_GLOBAL_PARAMETERS_SIGN_UP_SOURCE_KEY = 'signUpSource';
export const TYRION_GLOBAL_PARAMETERS_SCROLL_TOP_VALUE_KEY = 'scrollTopValue';

export const VOUCHER_DESCRIPTION_SESSION_STORAGE_KEY = 'voucherDescription';

export const MODAL_FORM_PORTAL = 'modal_form';

export const ORGANISATION_DA_DIREKT = 'da-direkt';
export const ORGANISATIONS_FRESSNAPF = 'fressnapf';

export const ORGANISATIONS = [ORGANISATION_DA_DIREKT, ORGANISATIONS_FRESSNAPF];

export const THEME = {
  DENTOLO_THEME: 'f8-theme-v1',
  PETOLO_THEME: 'petolo-theme',
  DA_DIREKT_THEME: 'da-direkt',
  DENTOLO_LEFT_ALIGN_THEME: 'dentolo-left-align-theme',
  FRESSNAPF_THEME: 'fressnapf-theme',
  VITOLO_THEME: 'vitolo-theme',
};

export const SUCCESS_PAGE_IDS = ['success'];

export const SIGN_UP_SOURCE_TYRION = 'tyrion';
export const SIGN_UP_SOURCE_DENTOLO_WEB = 'dtl_web';
export const SIGN_UP_SOURCE_PETOLO_WEB = 'ptl_web';
export const SIGN_UP_SOURCE_VITOLO_WEB = 'vtl_web';
export const SIGN_UP_SOURCE_DA_DIREKT = 'da_direkt';
export const SIGN_UP_SOURCE_DA_DIREKT_CRM = 'da_direkt_crm';
export const SIGN_UP_SOURCE_FRESSNAPF_WEB = 'fressnapf_web';
export const SIGN_UP_SOURCES_WEB = [
  SIGN_UP_SOURCE_TYRION,
  SIGN_UP_SOURCE_DENTOLO_WEB,
  SIGN_UP_SOURCE_PETOLO_WEB,
  SIGN_UP_SOURCE_VITOLO_WEB,
];
export const SIGN_UP_SOURCES_GOOGLE_TRACKING_PETOLO = [
  ...SIGN_UP_SOURCES_WEB,
  SIGN_UP_SOURCE_FRESSNAPF_WEB,
];

export const SUBMIT_EVENTS_TABOOLA = 'taboola';

export const INSURANCE_FOR_ME = 'me';
export const INSURANCE_FOR_ANOTHER = 'another';
// only used for DA Direkt tracking
export const INSURANCE_FOR_ME_LABEL = 'Ich selbst';
export const INSURANCE_FOR_ANOTHER_LABEL = 'Eine andere Person';

// #TODO: implement it with i18n
export const SALUTATION_GENDER_LIST = ['male', 'female'];
export const SALUTATIONS = {
  male: 'Herr',
  female: 'Frau',
};

export const NO_POLICY_ADDON_SELECTED = 'no-addons';

export const MONTHS = [
  'month.january',
  'month.february',
  'month.march',
  'month.april',
  'month.may',
  'month.june',
  'month.july',
  'month.august',
  'month.september',
  'month.october',
  'month.november',
  'month.december',
];

export const UTM_SOURCE_REFERRAL_CAMPAIGN = 'referral-campaign';
export const COOKIE_DA_DIREKT_DA_CAMP = 'da_camp';

// only used for DA Direkt tracking
export const PAYMENT_METHOD_SEPA = 'sepa lastschriftmandat';
export const PAYMENT_PERIOD_MONTHLY = 'monatlich';

export const PROGRESS_BAR_VARIANT_1 = 'progress-bar-1';
export const PROGRESS_BAR_VARIANT_2 = 'progress-bar-2';
export const PROGRESS_BAR_VARIANT_3 = 'progress-bar-3';
export const PROGRESS_BAR_VARIANT_4 = 'progress-bar-4';
export const PROGRESS_BAR_VARIANT_5 = 'progress-bar-5';
export const PROGRESS_BAR_VARIANT_6 = 'progress-bar-6';
export const PROGRESS_BAR_VARIANT_7 = 'progress-bar-7';

export const LEFT = 'left';
export const CENTER = 'center';
export const RIGHT = 'right';
export const BLACKLISTED_EMAILS = ['test@zurich.com', 'test@dentolo.de'];
export const CUSTOMER_ALREADY_CREATED_ERROR = 'Customer was already created';

export const PRODUCT_NAME_DENTOLO = 'dentolo';
export const PRODUCT_NAME_PETOLO = 'petolo';
export const PRODUCT_NAME_VITOLO = 'vitolo';

export const INSURANCE_CATEGORY_DENTAL = 'dental';
export const INSURANCE_CATEGORY_HEALTH = 'health';
export const INSURANCE_CATEGORY_PET = 'pet';
export const INSURANCE_CATEGORY_PET_HEALTH = 'pet_health';
export const INSURANCE_CATEGORY_PET_LIABILITY = 'pet_liability';

export const PRODUCT_OPTIONS_DENTOLO = [PRODUCT_NAME_DENTOLO, INSURANCE_CATEGORY_DENTAL];
export const PRODUCT_OPTIONS_PETOLO = [
  PRODUCT_NAME_PETOLO,
  INSURANCE_CATEGORY_PET,
  INSURANCE_CATEGORY_PET_HEALTH,
];
export const PRODUCT_OPTIONS_VITOLO = [PRODUCT_NAME_VITOLO, INSURANCE_CATEGORY_HEALTH];

export const OPTIN_EMAIL_ZURICH = 'email_offer_zurich';
export const OPTIN_TELEPHONE_ZURICH = 'telephone_offer_zurich';
export const OPTIN_EMAIL_GROUP_ZURICH = 'email_group_zurich';
export const OPTIN_TELEPHONE_GROUP_ZURICH = 'telephone_group_zurich';

export const KEY_DOCUMENTS_ACCEPTED_AT = 'documents_accepted_at';

export const ERROR_KEY_DUPLICATE_CONTRACT = 'contracts.duplicate';

export const PHONE_COUNTRY_CODE = {
  DE: '+49',
  FR: '+33',
};
