import { useEffect } from 'react';
import useDtlTracker from '../tracking/useDtlTracker';

const useTrackPageLoad = (schemaDownloadedAndVerified) => {
  const [, /* trackDtlEvents */ trackDtlPageLoad] = useDtlTracker();

  useEffect(() => {
    if (schemaDownloadedAndVerified) {
      trackDtlPageLoad();
    }
  }, [schemaDownloadedAndVerified, trackDtlPageLoad]);
};

export default useTrackPageLoad;
