import styled from 'styled-components';
import { applyTheme, MIN_WIDTH_LG, palette } from '../theme/Theme';
import { THEME } from '../../constants/Constants';
import { APP_ENV } from '../../app/environment';

const fontFamily = applyTheme({
  [THEME.DENTOLO_THEME]: palette.font_family_1,
  [THEME.PETOLO_THEME]: palette.font_family_1,
  [THEME.DA_DIREKT_THEME]: palette.font_family_2,
  [THEME.DENTOLO_LEFT_ALIGN_THEME]: palette.font_family_1,
  [THEME.FRESSNAPF_THEME]: palette.font_family_skolar_sans,
});

const marginTopBottom = applyTheme({
  [THEME.DENTOLO_THEME]: '2.5em',
  [THEME.PETOLO_THEME]: '2.5em',
  [THEME.DA_DIREKT_THEME]: '1.5em',
  [THEME.DENTOLO_LEFT_ALIGN_THEME]: '2.5em',
});

const marginLeftRight = applyTheme({
  [THEME.DENTOLO_THEME]: '0',
  [THEME.PETOLO_THEME]: '0',
  [THEME.DA_DIREKT_THEME]: '0',
  [THEME.DENTOLO_LEFT_ALIGN_THEME]: '1em',
});

const marginDesktop = applyTheme({
  [THEME.DENTOLO_THEME]: '3em',
  [THEME.PETOLO_THEME]: '3em',
  [THEME.DA_DIREKT_THEME]: '1.5em',
  [THEME.DENTOLO_LEFT_ALIGN_THEME]: '3em',
});

export const StyledSchemaContainer = styled.div`
  font-family: ${fontFamily};
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: ${marginTopBottom};
  margin-right: ${marginLeftRight};
  margin-bottom: ${APP_ENV === 'beta' ? '5em' : marginTopBottom};
  margin-left: ${marginLeftRight};

  @media only screen and (min-width: ${MIN_WIDTH_LG}) {
    margin-top: ${marginDesktop};
    margin-bottom: ${APP_ENV === 'beta' ? '5em' : marginDesktop};
  }
`;

export const StyledLoadingAnimationContainer = styled.div`
  position: relative;
  width: 16em;
  height: 16em;
  margin: 0 auto;
`;

export const StyledBranchName = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  padding: 0.5em;
  font-family: ${fontFamily};
  color: ${palette.product_blue};
  background: ${palette.white};
  text-align: right;
`;
