import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import useS2STrackingAPI from '../../../api/useS2STrackingAPI';
import { extractQueryParams } from '../../../utils/URLHelper';

const useTaboolaIdentifier = () => {
  const location = useLocation();
  const [storeAdServiceIdentifierAPI] = useS2STrackingAPI();

  const storeTaboolaIdentifier = useCallback(
    (uuid, email) => {
      const { tbclid = null } = extractQueryParams(location);
      if (tbclid) {
        const data = { tbclid };
        storeAdServiceIdentifierAPI(uuid, email, data);
      }
    },
    [location, storeAdServiceIdentifierAPI]
  );

  return storeTaboolaIdentifier;
};

export default useTaboolaIdentifier;
