import { useLayoutEffect } from 'react';
import useWindow from '../../hooks/useWindow';
import {
  TYRION_GLOBAL_PARAMETERS_KEY,
  TYRION_GLOBAL_PARAMETERS_SCROLL_TOP_VALUE_KEY,
} from '../../constants/Constants';

const useScrollToTopOfPage = (pageId, shouldScrollToTopOfPage) => {
  const [getFromWindow] = useWindow();

  useLayoutEffect(() => {
    if (shouldScrollToTopOfPage) {
      const scrollToPosition = getFromWindow('scrollTo');
      const tyrionParamsFromPage = getFromWindow(TYRION_GLOBAL_PARAMETERS_KEY);

      const scrollTopValueFromPage =
        tyrionParamsFromPage?.[`${TYRION_GLOBAL_PARAMETERS_SCROLL_TOP_VALUE_KEY}`];
      const scrollTopValue = Number.isInteger(scrollTopValueFromPage) ? scrollTopValueFromPage : 0;

      if (typeof scrollToPosition === 'function') {
        scrollToPosition({
          top: scrollTopValue,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [pageId, shouldScrollToTopOfPage, getFromWindow]);
};

export default useScrollToTopOfPage;
