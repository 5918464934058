import { useCallback } from 'react';

import { useFetch } from '../hooks/useFetch';
import useLocale from '../locales/useLocale';
import { SCHEMA_BASE_URL } from '../app/environment';
import { LOCALES } from '../locales/locale.helper';
import {
  PRODUCT_NAME_DENTOLO,
  PRODUCT_NAME_PETOLO,
  PRODUCT_NAME_VITOLO,
} from '../constants/Constants';

const countryFolders = {
  [LOCALES.DE]: '',
  [LOCALES.FR]: 'fr',
};

const productFolders = {
  [LOCALES.DE]: {
    [PRODUCT_NAME_DENTOLO]: 'dentolo',
    [PRODUCT_NAME_PETOLO]: 'petolo',
    [PRODUCT_NAME_VITOLO]: 'vitolo',
  },
  [LOCALES.FR]: {
    [PRODUCT_NAME_PETOLO]: 'patolo',
  },
};

const schemaConfigFile = 'schemaConfig.json';

const useSchemaAPI = () => {
  const [, /* executeFetchRequest */ executeFetchRequestWithRetry] = useFetch();

  // Using `country` as variable because schemas are distinguished by country
  const country = useLocale();

  const fetchSchemaAPI = useCallback(
    (flow, product, organisation) => {
      const countryFolder = countryFolders[`${country}`] || '';
      const productFolder = product ? productFolders[`${country}`][`${product}`] : '';

      const organisationPath = organisation ? `${organisation}/` : '';
      const countryPath = countryFolder ? `${countryFolder}/` : '';
      const productPath = productFolder ? `${productFolder}/` : '';
      const schemaFile = `${flow}.json`;

      const fetchSchemaUrl = `${SCHEMA_BASE_URL}/${organisationPath}${countryPath}${productPath}${schemaFile}`;

      return executeFetchRequestWithRetry(fetchSchemaUrl, {});
    },
    [country, executeFetchRequestWithRetry]
  );

  const fetchSchemaConfigAPI = useCallback(
    (product, organisation) => {
      const countryFolder = countryFolders[`${country}`] || '';
      const productFolder = product ? productFolders[`${country}`][`${product}`] : '';

      const organisationPath = organisation ? `${organisation}/` : '';
      const countryPath = countryFolder ? `${countryFolder}/` : '';
      const productPath = productFolder ? `${productFolder}/` : '';

      const fetchSchemaConfigUrl = `${SCHEMA_BASE_URL}/${organisationPath}${countryPath}${productPath}${schemaConfigFile}`;

      return executeFetchRequestWithRetry(fetchSchemaConfigUrl, {});
    },
    [country, executeFetchRequestWithRetry]
  );

  return [fetchSchemaAPI, fetchSchemaConfigAPI];
};

export default useSchemaAPI;
