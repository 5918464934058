import { THEME } from '../constants/Constants';

const fr = {
  [`app_outage.message.${THEME.DENTOLO_THEME}`]:
    'Cette page est actuellement indisponible, veuillez réessayer dans quelques instants.',
  [`app_outage.message.${THEME.PETOLO_THEME}`]:
    'Cette page est actuellement indisponible, veuillez réessayer dans quelques instants.',
  [`app_outage.message.${THEME.VITOLO_THEME}`]:
    'Cette page est actuellement indisponible, veuillez réessayer dans quelques instants.',
  [`app_outage.message.${THEME.DA_DIREKT_THEME}`]:
    'Cette page est actuellement indisponible, veuillez réessayer dans quelques instants.',
  [`app_outage.message.${THEME.FRESSNAPF_THEME}`]:
    'Cette page est actuellement indisponible, veuillez réessayer dans quelques instants.',

  'billing_day.title': 'Prélèvement de la mensualité',
  'billing_day.first_of_month': 'Le 1ᵉʳ du mois',
  'billing_day.fifteenth_of_month': 'Le 15 du mois',

  'digital_payment.default.fetching_link_message': 'Chargement en cours',

  'error.default.validation.generic': 'Il y a une erreur',
  'error.default.validation.date': 'Veuillez indiquer une date valide',
  'error.default.validation.input': 'Veuillez indiquer une valeur valide',
  'error.duplicate_pet': "Dupliquer les détails de l'animal",
  'error.iban.invalid': 'Format IBAN invalide. Veuillez vérifier votre saisie.',
  'error.iban.non_sepa':
    "Cet IBAN n'est pas valide. Veuillez entrer un IBAN d'un pays officiel de la zone SEPA pour continuer.",

  'month.january': 'janvier',
  'month.february': 'février',
  'month.march': 'mars',
  'month.april': 'avril',
  'month.may': 'mai',
  'month.june': 'juin',
  'month.july': 'juillet',
  'month.august': 'août',
  'month.september': 'septembre',
  'month.october': 'octobre',
  'month.november': 'novembre',
  'month.december': 'décembre',

  'multiple_pets.default.delete_confirmation.message': 'Voulez-vous vraiment supprimer cet animal?',
  'multiple_pets.default.label.button.add_pet': 'Assurer un autre animal',
  'multiple_pets.default.label.button.remove_pet': 'Supprimer cet animal',
  'multiple_pets.default.label.button.delete_pet.yes': 'Oui',
  'multiple_pets.default.label.button.delete_pet.no': 'Non',
  'multiple_pets.default.label.individual_price': 'Tarif pour {{contracts.insured_pet.name}}',
  'multiple_pets.default.section.details.title': 'Votre animal',
  'multiple_pets.default.section.review.title':
    'Informations concernant {{contracts.insured_pet.name}}',

  'payment.link.use_iban': 'Je préfère utiliser mon IBAN.',
  'payment.link.use_bank_account': 'Je préfère utiliser mon numéro de compte et mon code banque.',

  'pet.gender.male': 'Mâle',
  'pet.gender.female': 'Femelle',
  'pet.type.dog': 'Chien',
  'pet.type.cat': 'Chat',

  'referral_email.subject': 'Dentolo Referral',

  'review_modal.title': 'Modifier vos informations',

  'salutation.male': 'Mr.',
  'salutation.female': 'Mme.',

  'sepa_mandate.title': 'Mandat SEPA ',
  'sepa_mandate.text':
    "*J'autorise le prélèvement SEPA pour le débit des primes d'assurance depuis mon compte et confirme être titulaire de ce compte.",
  'sepa_mandate.info_01':
    "*En signant ce formulaire de mandat, j'autorise DA Direkt Versicherung, avec l'identifant créancier DE81ZZZ00000023246, à prélever des paiements depuis mon compte par prélèvement SEPA. J'autorise également mon établissement bancaire à honorer les prélèvements effectués par DA Direkt Versicherung sur mon compte.",
  'sepa_mandate.info_02':
    'Veuillez noter : le délai de pré-notification pour le prélèvement SEPA est réduit à un jour.',
  'sepa_mandate.info_03':
    "Je peux demander le remboursement du montant prélevé dans un délai de huit semaines à compter de la date de débit. Les conditions convenues avec mon établissement bancaire s'appliquent.",

  'text.schema_loading': "Merci d'attendre un instant'",
};

export default fr;
