import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import usePolicyCategory from '../../policy/usePolicyCategory';
import useInsuranceCategory from '../../insuranceCategory/useInsuranceCategory';
import useSignupSource from '../../schema/useSignupSource';
import useSurveyId from '../../schema/useSurveyId';
import useGduid from '../../tracking/useGduid';
import useReferredId from '../../referral/useReferredId';
import useCookie from '../../../hooks/useCookie';
import useTrackingParameterValues from '../../tracking/useTrackingParameterValues';
import useMarketingTestTrackingParameters from '../../tracking/useMarketingTestTrackingParameters';
import usePolicyAddonsAPI from '../../widgetsAPIData/usePolicyAddonsAPI';
import { processWebhooksAndEmailTriggers } from '../leadActionUtils';
import { extractQueryParams, getPageIdFromUrl } from '../../../utils/URLHelper';
import { DEFAULT_INSURANCE_FOR } from '../../../constants/DefaultValues';
import { updateWebhookPayload } from '../../../utils/WebhookPayloadHelper';
import useMultipleContracts from '../../schema/useMultipleContracts';
import useParameters from '../../schema/useParameters';
import useCookieConsentStatus from '../../tracking/useCookieConsentStatus';
import usePartnerData from '../../partnerData/usePartnerData';
import { COOKIE_DA_DIREKT_DA_CAMP } from '../../../constants/Constants';

const useUpdateLeadPayloadForPetolo = () => {
  const policyCategory = usePolicyCategory();
  const insuranceCategory = useInsuranceCategory();
  const signupSource = useSignupSource();
  const surveyId = useSurveyId();
  const getGduid = useGduid();
  const referredId = useReferredId();
  const partnerData = usePartnerData();

  const location = useLocation();
  const {
    utm_source: utmSource = null,
    utm_medium: utmMedium = null,
    utm_campaign: utmCampaign = null,
    utm_term: utmTerm = null,
    utm_content: utmContent = null,
    ex_agency_id: exAgencyId = null,
    channel = null,
    partner = null,
    sales_agent_id: salesAgentId = null,
    sales_agent_source: salesAgentSource = null,
  } = extractQueryParams(location);

  const [getCookie] = useCookie();
  const cookieDaCamp = getCookie(COOKIE_DA_DIREKT_DA_CAMP);

  const { getCookieConsentList } = useCookieConsentStatus();
  const cookieConsentList = getCookieConsentList();

  const leadData = useSelector((state) => state?.lead?.leadData);
  const questions = useSelector((state) => state?.questions?.answers);

  const { flow, organisation } = useParameters();
  const trackingParameters = useTrackingParameterValues();
  const marketingTestTrackingParameters = useMarketingTestTrackingParameters();

  const [selectedAddonsDetails] = usePolicyAddonsAPI();
  const selectedPolicyAddons = selectedAddonsDetails.map((addon) => addon?.key);

  const daDirektSignUpSources = useSelector(
    (state) => state?.schema?.schemaConfig?.signUpSourcesDaDirekt
  );
  const validatedDaDirektSignUpSource = Array.isArray(daDirektSignUpSources)
    ? daDirektSignUpSources
    : [];

  const isMultipleContracts = useMultipleContracts();

  const prepareUpdateLeadPayloadForPetolo = (webhooks, emailTriggers) => {
    const payload = {};

    const { leadActions, webhookIds, actionPayloads } = processWebhooksAndEmailTriggers(
      webhooks,
      emailTriggers
    );

    if (Array.isArray(leadActions)) {
      payload.actions = leadActions;
    } else {
      payload.actions = [];
    }

    const webhookPayload =
      Object.keys(actionPayloads)?.length > 0
        ? updateWebhookPayload(actionPayloads, organisation)
        : {};

    payload.additional = {
      survey_id: surveyId,
      webhook_ids: webhookIds || {},
      action_payloads: webhookPayload,
      ...trackingParameters,
      marketing_test_params: marketingTestTrackingParameters,
      cookie_consents: cookieConsentList,
    };

    payload.insurance_category = insuranceCategory;

    if (leadData?.bank_account) {
      payload.bank_account = leadData.bank_account;
    } else {
      payload.bank_account = {};
    }

    if (leadData?.communication_list) {
      payload.communication_list = leadData.communication_list;
    } else {
      payload.communication_list = {};
    }

    payload.multiple_contracts = isMultipleContracts;

    const insuredPet = {
      name: leadData?.pet?.name,
      pet_type: leadData?.pet?.pet_type,
      date_of_birth: leadData?.pet?.date_of_birth,
      gender: leadData?.pet?.gender,
      breed_id: leadData?.pet?.breed_id,
      transponder_code: leadData?.pet?.transponder_code,
    };

    if (isMultipleContracts) {
      // make `contract` null and add `contracts` values if multiple_pets
      payload.contract = {};
      const contractsFromLead = leadData?.contracts || [];

      const updatedContracts = contractsFromLead.map((contract) => ({
        ...contract,
        signed: false,
        policy_addons: selectedPolicyAddons,
        policy_category: policyCategory,
      }));

      payload.contracts = [...updatedContracts];
    } else {
      // make `contracts` null and add `contract` values if NOT multiple_pets
      payload.contracts = [];
      payload.contract = {
        ...leadData?.contract,
        insured_pet: insuredPet,
        signed: false,
        policy_addons: selectedPolicyAddons,
        policy_category: policyCategory,
      };
    }

    if (!isMultipleContracts && !payload?.contract?.insurance_for) {
      payload.contract = { ...payload.contract, insurance_for: DEFAULT_INSURANCE_FOR };
    }

    if (!leadData?.customer) {
      payload.customer = {};
    } else {
      payload.customer = leadData.customer;
      if (payload?.customer?.email === '') {
        const { email, ...customerDetailsWithoutEmail } = payload.customer;
        payload.customer = customerDetailsWithoutEmail;
      }
      if (payload?.customer?.phone_number === '') {
        const { phone_number: phoneNumber, ...customerDetailsWithoutPhone } = payload.customer;
        payload.customer = customerDetailsWithoutPhone;
      }
    }

    payload.customer = { ...payload.customer, sign_up_source: signupSource };

    // Digital payments
    if (leadData?.digital_payment_method) {
      payload.digital_payment_method = { ...leadData.digital_payment_method };
    }

    payload.gduid = getGduid();

    if (!payload?.previous_insurance) {
      payload.previous_insurance = {};
    }
    payload.previous_insurance.has_insurance = !!(leadData?.contract?.previously_insured === 'yes');
    payload.previous_insurance.company = '';
    payload.previous_insurance.policy_name = '';

    if (leadData?.sales_channel) {
      payload.sales_channel = leadData.sales_channel;
    } else {
      payload.sales_channel = {};
    }

    payload.sales_channel = {
      ...payload.sales_channel,
      flow: payload?.flow || flow,
      utm_source:
        !!utmSource && utmSource !== payload.sales_channel?.utm_source
          ? utmSource
          : payload.sales_channel?.utm_source,
      utm_medium:
        !!utmMedium && utmMedium !== payload.sales_channel?.utm_medium
          ? utmMedium
          : payload.sales_channel?.utm_medium,
      utm_campaign:
        !!utmCampaign && utmCampaign !== payload.sales_channel?.utm_campaign
          ? utmCampaign
          : payload.sales_channel?.utm_campaign,
      utm_term:
        !!utmTerm && utmTerm !== payload.sales_channel?.utm_term
          ? utmTerm
          : payload.sales_channel?.utm_term,
      utm_content:
        !!utmContent && utmContent !== payload.sales_channel?.utm_content
          ? utmContent
          : payload.sales_channel?.utm_content,
      ex_agency_id:
        !!exAgencyId && exAgencyId !== payload.sales_channel?.ex_agency_id
          ? exAgencyId
          : payload.sales_channel?.ex_agency_id,
      channel:
        !!channel && channel !== payload.sales_channel?.channel
          ? channel
          : payload.sales_channel?.channel,
      partner:
        !!partner && partner !== payload.sales_channel?.partner
          ? partner
          : payload.sales_channel?.partner,
      sales_agent: {
        id: salesAgentId || leadData?.sales_channel?.sales_agent?.id,
        source: salesAgentSource || leadData?.sales_channel?.sales_agent?.source,
      },
    };

    if (!!referredId && leadData?.sales_channel?.referred_id !== referredId) {
      payload.sales_channel = { ...payload.sales_channel, referred_id: referredId };
    }

    // Add `mediacode` to `sales_channel` from cookie only for DA-Direkt
    if (validatedDaDirektSignUpSource.includes(signupSource)) {
      payload.sales_channel = { ...payload.sales_channel, mediacode: cookieDaCamp };
    }

    payload.questions = questions;
    payload.partner_data = leadData?.partner_data || partnerData;

    payload.voucher_codes = leadData?.voucher_codes;

    payload.uuid = leadData?.uuid;

    payload.page_id = getPageIdFromUrl();

    return payload;
  };

  return prepareUpdateLeadPayloadForPetolo;
};

export default useUpdateLeadPayloadForPetolo;
