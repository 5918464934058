import { useCallback } from 'react';
import { safeParseJSON } from '../utils/GeneralHelper';
import useWindow from './useWindow';

export default function useSessionStorage() {
  const [getFromWindow] = useWindow();
  const storage = getFromWindow('sessionStorage');

  const getFromSessionStorage = useCallback(
    (key) => {
      try {
        const value = storage ? storage.getItem(key) : null;
        return safeParseJSON(value);
      } catch (error) {
        return '';
      }
    },
    [storage]
  );

  const saveToSessionStorage = useCallback(
    (key, value) => {
      try {
        if (storage) {
          storage.setItem(key, JSON.stringify(value));
        }
        return null;
      } catch (error) {
        return null;
      }
    },
    [storage]
  );

  const removeFromSessionStorage = useCallback(
    (key) => {
      try {
        if (storage) {
          storage.removeItem(key);
        }
        return null;
      } catch (error) {
        return null;
      }
    },
    [storage]
  );

  return [getFromSessionStorage, saveToSessionStorage, removeFromSessionStorage];
}
