import { DateTime } from 'luxon';

// build pet contractData for GTM event
export const generatePetContractDataForGTM = (
  policyAddons,
  contract = {},
  petBreedsList = [],
  policyPriceDetails = {}
) => {
  const {
    policy_category: policyCategory,
    starting_at: startingAt,
    insured_pet: pet,
  } = contract || {};

  const genderPet = pet?.gender || null;

  const dateOfBirthPet = pet?.date_of_birth;
  const birthYearPet = dateOfBirthPet ? DateTime.fromISO(dateOfBirthPet).get('year') : null;

  const petBreedId = pet?.breed_id || null;
  const breedDetails = petBreedsList?.find((breed) => breed?.id === petBreedId) || {};
  const petBreedName = breedDetails?.name || null;
  const petType = breedDetails?.pet_type || null;
  const petBreedCategory = breedDetails?.category || null;

  const policyPrice = policyPriceDetails?.policy_price || 0;
  const policyPriceYearly = (policyPrice * 1200) / 100 || 0; // Round off to two decimal places
  const policyAddonsPrice = policyPriceDetails?.addon_price || 0;
  const policyPriceWithAddons = policyPrice + policyAddonsPrice || 0;
  const policyPriceWithAddonsYearly = (policyPriceWithAddons * 1200) / 100 || 0; // Round off to two decimal places

  return {
    contract_start_date: startingAt,
    policy_category: policyCategory,
    policy_addons: policyAddons,
    gender_pet: genderPet,
    birthYear_pet: birthYearPet,
    pet_type: petType,
    pet_breed_id: petBreedId,
    pet_breed: petBreedName,
    pet_breed_category: petBreedCategory,
    priceStandard: policyPrice,
    priceStandardYearly: policyPriceYearly,
    price: policyPriceWithAddons,
    priceYearly: policyPriceWithAddonsYearly,
    priceExtra: policyAddonsPrice,
  };
};

// function to get single pet contract data for GTM event payload
export const getPetContractDataForSingleContract = (
  leadData,
  policyCategory,
  policyAddons,
  policyPriceDetails,
  petBreedsList
) => {
  const { contract, pet } = leadData || {};

  const contractObj = {
    ...contract,
    policy_category: policyCategory,
    insured_pet: pet,
  };

  const petInfo = generatePetContractDataForGTM(
    policyAddons,
    contractObj,
    petBreedsList,
    policyPriceDetails
  );

  return petInfo ? [petInfo] : [];
};

// function to get multiple pet contract data for GTM event payload
export const getPetContractDataForMultipleContracts = (
  leadData,
  policyAddons,
  multiplePriceDetails,
  petBreedsList
) => {
  const contracts = leadData?.contracts || [];

  if (!contracts) return [];

  const { policy_prices: policyPrices } = multiplePriceDetails || {};

  const policyPriceMap = {};
  policyPrices?.forEach((price) => {
    const key = `${price.date_of_birth}_${price.breed_id}_${price.pet_name}`;
    policyPriceMap[`${key}`] = price;
  });

  const petInfo = contracts?.map((contract) => {
    const { insured_pet: currentPet } = contract;

    const key = `${currentPet?.date_of_birth}_${currentPet?.breed_id}_${currentPet?.name}`;
    const currentPetPolicyPrice = policyPriceMap[`${key}`];

    const policyPriceDetails = {
      policy_price: currentPetPolicyPrice?.policy_price || 0,
      addon_price: currentPetPolicyPrice?.addon_price || 0,
    };

    const singleContractInfo = generatePetContractDataForGTM(
      policyAddons,
      contract,
      petBreedsList,
      policyPriceDetails
    );

    return singleContractInfo || {};
  });

  return petInfo || [];
};
