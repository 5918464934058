import {
  PRODUCT_OPTIONS_DENTOLO,
  PRODUCT_OPTIONS_PETOLO,
  PRODUCT_NAME_DENTOLO,
  PRODUCT_NAME_PETOLO,
  INSURANCE_CATEGORY_DENTAL,
  INSURANCE_CATEGORY_HEALTH,
  INSURANCE_CATEGORY_PET_HEALTH,
  PRODUCT_NAME_VITOLO,
  PRODUCT_OPTIONS_VITOLO,
} from '../../constants/Constants';

export const getProductName = (product) => {
  if (PRODUCT_OPTIONS_PETOLO.includes(product)) {
    return PRODUCT_NAME_PETOLO;
  }
  if (PRODUCT_OPTIONS_DENTOLO.includes(product)) {
    return PRODUCT_NAME_DENTOLO;
  }
  if (PRODUCT_OPTIONS_VITOLO.includes(product)) {
    return PRODUCT_NAME_VITOLO;
  }
  return null;
};

export const getInsuranceCategoryFromProductName = (productName) => {
  switch (productName) {
    case PRODUCT_NAME_DENTOLO:
      return INSURANCE_CATEGORY_DENTAL;
    case PRODUCT_NAME_PETOLO:
      return INSURANCE_CATEGORY_PET_HEALTH;
    case PRODUCT_NAME_VITOLO:
      return INSURANCE_CATEGORY_HEALTH;
    default:
      return INSURANCE_CATEGORY_DENTAL;
  }
};
