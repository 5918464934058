import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { NODE_ENV } from './environment';

const store = configureStore({
  reducer: rootReducer,
});

if (NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
}

export default store;
