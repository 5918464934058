import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearAllErrorsOnPage } from '../errorValidation/errorValidationSlice';
import useUpdateLeadData from '../customer/useUpdateLeadData';
import useCreateCustomer from '../customer/useCreateCustomer';
import usePageTracking from '../tracking/usePageTracking';
import useDynamicFlows from '../questions/useDynamicFlows';
import useExternalPage from './useExternalPage';

const usePageNavigation = (page, setCurrentPage) => {
  const dispatch = useDispatch();

  const {
    createLead,
    createCustomer: shouldCreateCustomer,
    webhooks,
    sendEmail: emailTriggers,
  } = page || {};

  // Start showing errors on the page only when user submits the page
  const [startShowingErrorsOnPage, setStartShowingErrorsOnPage] = useState(false);

  // Do not proceed to next page if there are form validation errors
  const widgetValidationErrors = useSelector((state) => state?.errorValidation?.widgetErrors);
  const backendValidationErrors = useSelector((state) => state?.errorValidation?.backendErrors);

  // Check whether lead has to be updated at this phase and do it
  const updateLeadData = useUpdateLeadData(createLead);

  // Check whether customer has to be created at this phase and do it
  const createCustomer = useCreateCustomer(shouldCreateCustomer);

  // Send tracking data to all the providers
  const sendTrackingData = usePageTracking(page);

  const redirectToExternalPage = useExternalPage();

  // If page has dynamicFlow rules, proceed to next page according to those rules
  const evaluateDynamicFlows = useDynamicFlows(page, setCurrentPage, redirectToExternalPage);

  // All tracking and other side effects that run on clicking the next button happens here
  const performOperationsAndProceed = async (proceed, awaitTrackingResponse) => {
    const noWidgetErrors =
      !!widgetValidationErrors && Object.keys(widgetValidationErrors).length === 0;
    const noBackendErrors = Array.isArray(backendValidationErrors)
      ? backendValidationErrors.length === 0
      : true;
    const pageHasNoErrors = noWidgetErrors && noBackendErrors;

    if (pageHasNoErrors) {
      setStartShowingErrorsOnPage(false);

      // Update lead data if required
      const updatedLeadData = await updateLeadData(webhooks, emailTriggers);

      // Backend validation failed, so start showing the error on the page
      if (!updatedLeadData) {
        setStartShowingErrorsOnPage(true);
      }

      // Create customer if required, only after updating the lead information
      await createCustomer(updatedLeadData);

      // Execute all tracking activities related to the page
      if (awaitTrackingResponse) {
        await sendTrackingData();
      } else {
        sendTrackingData();
      }

      // Continue to next page even if customer creation fails (this will be handled later manually)
      if (updatedLeadData) {
        proceed();
      }
    } else {
      setStartShowingErrorsOnPage(true);
    }
  };

  const goToNextPage = async (newPageId, queryParams) => {
    const [hasDynamicFlow, proceedAccordingToDynamicFlow] = await evaluateDynamicFlows();
    if (hasDynamicFlow) {
      performOperationsAndProceed(proceedAccordingToDynamicFlow);
    } else {
      const proceedToNextPage = () => setCurrentPage(newPageId, queryParams);
      performOperationsAndProceed(proceedToNextPage, false);
    }
  };

  const goToExternalURL = async (externalUrl, attachUuid, attachAllParams) => {
    const [hasDynamicFlow, proceedAccordingToDynamicFlow] = await evaluateDynamicFlows();
    if (hasDynamicFlow) {
      performOperationsAndProceed(proceedAccordingToDynamicFlow);
    } else {
      const proceedToExternalUrl = () =>
        redirectToExternalPage(externalUrl, attachUuid, attachAllParams);
      performOperationsAndProceed(proceedToExternalUrl, true);
    }
  };

  const goBackToPreviousPage = (newPageId) => {
    dispatch(clearAllErrorsOnPage());
    setStartShowingErrorsOnPage(false);
    setCurrentPage(newPageId);
  };

  const goBackToExternalURL = (externalUrl, attachUuid, attachAllParams) => {
    dispatch(clearAllErrorsOnPage());
    setStartShowingErrorsOnPage(false);
    redirectToExternalPage(externalUrl, attachUuid, attachAllParams);
  };

  return [
    startShowingErrorsOnPage,
    goToNextPage,
    goToExternalURL,
    goBackToPreviousPage,
    goBackToExternalURL,
  ];
};

export default usePageNavigation;
