import { useCallback } from 'react';
import { useFetch } from '../hooks/useFetch';
import useFaircareBaseUrl from '../features/schema/useFaircareBaseUrl';

const useCustomerAPI = () => {
  const [executeFetchRequest] = useFetch();
  const [baseUrl, defaultHeaders] = useFaircareBaseUrl();

  const createCustomerAPI = useCallback(
    (uuid) => {
      if (uuid) {
        const createCustomerUrl = `${baseUrl}/api/v1/customers`;
        const body = JSON.stringify({ uuid });
        const options = {
          method: 'POST',
          headers: defaultHeaders,
          body,
        };
        return executeFetchRequest(createCustomerUrl, options).then((data) => {
          if (data?.status === 'success') {
            return uuid;
          }
          if (data?.status === 'fail') {
            return null;
          }
          return null;
        });
      }
      return null;
    },
    [baseUrl, defaultHeaders, executeFetchRequest]
  );

  return [createCustomerAPI];
};

export default useCustomerAPI;
