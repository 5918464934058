import React from 'react';
import Widget from './Widget';

function WidgetsManager({
  uiSchema,
  dataSchema,
  pageId,
  pageDescription,
  setCurrentPage,
  goToExternalURL,
  goBackToExternalURL,
  goBackToPreviousPage,
  startShowingErrorsOnPage,
  pageFormRef,
  modalFormRef,
}) {
  if (Array.isArray(uiSchema)) {
    return uiSchema.map((uiWidget) => {
      const widgetId = uiWidget?.name;
      const widgetData = dataSchema ? dataSchema[`${widgetId}`] : null;

      return (
        <Widget
          key={`${pageId}_${widgetId}`}
          widgetName={uiWidget?.widget}
          pageId={pageId}
          pageDescription={pageDescription}
          widgetId={widgetId}
          widgetData={widgetData}
          widgetOptions={uiWidget?.options}
          setCurrentPage={setCurrentPage}
          goToExternalURL={goToExternalURL}
          goBackToExternalURL={goBackToExternalURL}
          goBackToPreviousPage={goBackToPreviousPage}
          startShowingErrorsOnPage={startShowingErrorsOnPage}
          pageFormRef={pageFormRef}
          modalFormRef={modalFormRef}
        />
      );
    });
  }
  return null;
}

export default WidgetsManager;
