import { DENTOLO, PETOLO, VITOLO } from '../../insuranceType/InsuranceTypes';
import useInsuranceType from '../../insuranceType/useInsuranceType';
import useCreateLeadPayloadForDentolo from './useCreateLeadPayloadForDentolo';
import useCreateLeadPayloadForPetolo from './useCreateLeadPayloadForPetolo';

const useCreateLeadDataPayload = () => {
  const insuranceType = useInsuranceType();

  const prepareCreateLeadPayloadForDentolo = useCreateLeadPayloadForDentolo();
  const prepareCreateLeadPayloadForPetolo = useCreateLeadPayloadForPetolo();

  switch (insuranceType) {
    case DENTOLO:
      return prepareCreateLeadPayloadForDentolo;
    case PETOLO:
      return prepareCreateLeadPayloadForPetolo;
    case VITOLO:
      return prepareCreateLeadPayloadForDentolo;
    default:
      return prepareCreateLeadPayloadForDentolo;
  }
};

export default useCreateLeadDataPayload;
