import { useCallback } from 'react';
import { useFetch } from '../hooks/useFetch';
import { SURVEY_BASE_URL } from '../app/environment';

const defaultHeaders = {
  'Content-type': 'application/json',
};

const useSurveyAPI = () => {
  const [executeFetchRequest] = useFetch();

  const sendSurveyDataAPI = useCallback(
    (uuid, gduid, surveyId, surveyData) => {
      const sendSurveyDataAPIUrl = `${SURVEY_BASE_URL}/api/v1/store_data`;
      const payload = { uuid, gduid, survey_id: surveyId, surveyData };
      const options = {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify(payload),
      };
      return executeFetchRequest(sendSurveyDataAPIUrl, options);
    },
    [executeFetchRequest]
  );

  const getSurveyDataAPI = useCallback(
    (uuid, surveyId) => {
      const getSurveyDataAPIUrl = `${SURVEY_BASE_URL}/api/v1/survey_info/user/${uuid}/survey/${surveyId}`;
      const options = {
        headers: defaultHeaders,
      };
      return executeFetchRequest(getSurveyDataAPIUrl, options);
    },
    [executeFetchRequest]
  );

  return [sendSurveyDataAPI, getSurveyDataAPI];
};

export default useSurveyAPI;
