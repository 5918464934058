import 'whatwg-fetch';
import useSWR from 'swr';

import { safeParseJSON } from '../../utils/GeneralHelper';
import { executeFetchAndHandleError } from '../../hooks/useFetch';
import { useDataDog } from '../../hooks/useDataDog';
import useConsole from '../../hooks/useConsole';

const swrFetcher = (notifyErrorToService, consoleError) => (swrKey) => {
  const { url = '', headersString = '' } = swrKey || {};

  const parsedHeaders = safeParseJSON(headersString);
  const headers = parsedHeaders || {};

  const options = { headers };

  return executeFetchAndHandleError(url, options, notifyErrorToService, consoleError);
};

const useWidgetsAPIData = (url, headers) => {
  const { notifyErrorToDataDog } = useDataDog();
  const [consoleError] = useConsole();

  const headersString = headers ? JSON.stringify(headers) : null;
  const swrKey = url ? { url, headersString } : null;

  const fetcher = swrFetcher(notifyErrorToDataDog, consoleError);

  const { data, error } = useSWR(swrKey, fetcher, { revalidateOnFocus: false });

  return {
    data,
    loading: !error && !data && !!url,
    error,
  };
};

export default useWidgetsAPIData;
