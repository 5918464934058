import React from 'react';
import { promiseWithRetry } from '../../utils/GeneralHelper';

const AddressOfInsuredPerson = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/AddressOfInsuredPerson'))
);
const AnswerButtons = React.lazy(() => promiseWithRetry(() => import('../widgets/AnswerButtons')));
const AnswerImageButtons = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/AnswerImageButtons'))
);
const BackButton = React.lazy(() => promiseWithRetry(() => import('../widgets/BackButton')));
const BankAccountIdentifierSepa = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/BankAccountIdentifierSepa'))
);
const Button = React.lazy(() => promiseWithRetry(() => import('../widgets/Button')));
const ButtonForSpecialPetBreed = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/ButtonForSpecialPetBreed'))
);
const CalendarDatepicker = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/CalendarDatepicker'))
);
const ChatBox = React.lazy(() => promiseWithRetry(() => import('../widgets/ChatBox')));
const ChoosePersonToBeInsured = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/ChoosePersonToBeInsured'))
);
const ContactAgreementText = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/ContactAgreementText'))
);
const ContactConsent = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/ContactConsent'))
);
const DaDirektCode = React.lazy(() => promiseWithRetry(() => import('../widgets/DaDirektCode')));
const Datepicker = React.lazy(() => promiseWithRetry(() => import('../widgets/Datepicker')));
const DatepickerForInsuredPerson = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/DatepickerForInsuredPerson'))
);
const DatepickerInput = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/DatepickerInput'))
);
const DatepickerInputOrMonthAndYear = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/DatepickerInputOrMonthAndYear'))
);
const DigitalPayment = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/DigitalPayment'))
);
const DigitalPaymentSuccess = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/DigitalPaymentSuccess'))
);
const DocumentsAccepted = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/DocumentsAccepted'))
);
const DocumentsAcceptedWithoutModal = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/DocumentsAcceptedWithoutModal'))
);
const DocumentsAutoAccepted = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/DocumentsAutoAccepted'))
);
const Email = React.lazy(() => promiseWithRetry(() => import('../widgets/Email')));
const Error = React.lazy(() => promiseWithRetry(() => import('../widgets/Error')));
const GeneralSurvey = React.lazy(() => promiseWithRetry(() => import('../widgets/GeneralSurvey')));
const HeaderWithPrice = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/HeaderWithPrice'))
);
const Iban = React.lazy(() => promiseWithRetry(() => import('../widgets/Iban')));
const SwitchableBankDetails = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/SwitchableBankDetails'))
);
const IbanSepa = React.lazy(() => promiseWithRetry(() => import('../widgets/IbanSepa')));
const IllnessAndDisabilityConfirmation = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/IllnessAndDisabilityConfirmation'))
);
const ImageBox = React.lazy(() => promiseWithRetry(() => import('../widgets/ImageBox')));
const InformationModal = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/InformationModal'))
);
const InformationText = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/InformationText'))
);
const Input = React.lazy(() => promiseWithRetry(() => import('../widgets/Input')));
const List = React.lazy(() => promiseWithRetry(() => import('../widgets/List')));
const MonthAndYear = React.lazy(() => promiseWithRetry(() => import('../widgets/MonthAndYear')));
const MonthAndYearInput = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/MonthAndYearInput'))
);
const MultiplePetsDetails = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/MultiplePetsDetails'))
);
const MultiplePolicyDetailsCard = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/MultiplePolicyDetailsCard'))
);
const Name = React.lazy(() => promiseWithRetry(() => import('../widgets/Name')));
const NotificationBanner = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/NotificationBanner'))
);
const OptInCommunication = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/OptInCommunication'))
);
const OptInMarketing = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/OptInMarketing'))
);
const PageHeader = React.lazy(() => promiseWithRetry(() => import('../widgets/PageHeader')));
const PaymentMethodSelector = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/PaymentMethodSelector'))
);
const Phone = React.lazy(() => promiseWithRetry(() => import('../widgets/Phone')));
const PolicyAddonSelector = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/PolicyAddonSelector'))
);
const PolicyDetailsCard = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/PolicyDetailsCard'))
);
const PolicyDetailsContents = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/PolicyDetailsContents'))
);
const PolicyDetailsExtendedContent = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/PolicyDetailsExtendedContent'))
);
const PolicyDetailsHeader = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/PolicyDetailsHeader'))
);
const PolicyName = React.lazy(() => promiseWithRetry(() => import('../widgets/PolicyName')));
const PolicySelector = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/PolicySelector'))
);
const PolicySelectorForSpecialPetBreeds = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/PolicySelectorForSpecialPetBreeds'))
);
const PostalCodeCity = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/PostalCodeCity'))
);
const PostCode = React.lazy(() => promiseWithRetry(() => import('../widgets/PostCode')));
const PriceAndPolicyInformation = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/PriceAndPolicyInformation'))
);
const PriceButton = React.lazy(() => promiseWithRetry(() => import('../widgets/PriceButton')));
const PriceDisplay = React.lazy(() => promiseWithRetry(() => import('../widgets/PriceDisplay')));
const ProductDetails = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/ProductDetails'))
);
const ProgressBar = React.lazy(() => promiseWithRetry(() => import('../widgets/ProgressBar')));
const PromotionCode = React.lazy(() => promiseWithRetry(() => import('../widgets/PromotionCode')));
const Radio = React.lazy(() => promiseWithRetry(() => import('../widgets/Radio')));
const RadioWithErrorMessage = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/RadioWithErrorMessage'))
);
const RecurringLeadButton = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/RecurringLeadButton'))
);
const Review = React.lazy(() => promiseWithRetry(() => import('../widgets/Review')));
const ReviewBankDetailsAndAcceptDocuments = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/ReviewBankDetailsAndAcceptDocuments'))
);
const ReviewCustomerDetails = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/ReviewCustomerDetails'))
);
const ReviewCustomerDetailsInfo = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/ReviewCustomerDetailsInfo'))
);
const ReviewDentalCustomerAndPolicyInfo = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/ReviewDentalCustomerAndPolicyInfo'))
);
const ReviewHealthInformation = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/ReviewHealthInformation'))
);
const ReviewInsuredPersonDetails = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/ReviewInsuredPersonDetails'))
);
const ReviewMultiplePetsDetails = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/ReviewMultiplePetsDetails'))
);
const ReviewPetCustomerAndPolicyInfo = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/ReviewPetCustomerAndPolicyInfo'))
);
const ReviewPetDetails = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/ReviewPetDetails'))
);
const ReviewPetDetailsInfo = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/ReviewPetDetailsInfo'))
);
const ReviewPolicyDocuments = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/ReviewPolicyDocuments'))
);
const Select = React.lazy(() => promiseWithRetry(() => import('../widgets/Select')));
const SelectPetBreedWithAutocomplete = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/SelectPetBreedWithAutocomplete'))
);
const SepaMandate = React.lazy(() => promiseWithRetry(() => import('../widgets/SepaMandate')));
const SingleStepPayment = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/SingleStepPayment'))
);
const SpecialPetBreedPolicyCategory = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/SpecialPetBreedPolicyCategory'))
);
const StartingDate = React.lazy(() => promiseWithRetry(() => import('../widgets/StartingDate')));
const Street = React.lazy(() => promiseWithRetry(() => import('../widgets/Street')));
const Success = React.lazy(() => promiseWithRetry(() => import('../widgets/Success')));
const SuccessReferral = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/SuccessReferral'))
);
const TriggerWebHookButton = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/TriggerWebHookButton'))
);
const TriggerWebHookModal = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/TriggerWebHookModal'))
);
const VoucherCode = React.lazy(() => promiseWithRetry(() => import('../widgets/VoucherCode')));
const VoucherCodeLink = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/VoucherCodeLink'))
);
const WidgetsForAnotherInsuredPerson = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/WidgetsForAnotherInsuredPerson'))
);
const WidgetHeader = React.lazy(() => promiseWithRetry(() => import('../widgets/WidgetHeader')));

function Widget({
  widgetName,
  pageId,
  pageDescription,
  widgetId,
  widgetData,
  widgetOptions,
  setCurrentPage,
  goToExternalURL,
  goBackToExternalURL,
  goBackToPreviousPage,
  startShowingErrorsOnPage,
  pageFormRef,
  modalFormRef,
}) {
  const props = {
    pageId,
    pageDescription,
    widgetId,
    widgetData,
    widgetOptions,
    setCurrentPage,
    goToExternalURL,
    goBackToExternalURL,
    goBackToPreviousPage,
    startShowingErrorsOnPage,
    pageFormRef,
    modalFormRef,
  };

  switch (widgetName) {
    case 'AddressOfInsuredPerson':
      return <AddressOfInsuredPerson {...props} />;
    case 'AnswerButtons':
      return <AnswerButtons {...props} />;
    case 'AnswerImageButtons':
      return <AnswerImageButtons {...props} />;
    case 'BackButton':
      return <BackButton {...props} />;
    case 'BankAccountIdentifierSepa':
      return <BankAccountIdentifierSepa {...props} />;
    case 'Button':
      return <Button {...props} />;
    case 'ButtonForSpecialPetBreed':
      return <ButtonForSpecialPetBreed {...props} />;
    case 'CalendarDatepicker':
      return <CalendarDatepicker {...props} />;
    case 'ChatBox':
      return <ChatBox {...props} />;
    case 'ChoosePersonToBeInsured':
      return <ChoosePersonToBeInsured {...props} />;
    case 'ContactAgreementText':
      return <ContactAgreementText {...props} />;
    case 'ContactConsent':
      return <ContactConsent {...props} />;
    case 'DaDirektCode':
      return <DaDirektCode {...props} />;
    case 'Datepicker':
      return <Datepicker {...props} />;
    case 'DatepickerForInsuredPerson':
      return <DatepickerForInsuredPerson {...props} />;
    case 'DatepickerInput':
      return <DatepickerInput {...props} />;
    case 'DatepickerInputOrMonthAndYear':
      return <DatepickerInputOrMonthAndYear {...props} />;
    case 'DigitalPayment':
      return <DigitalPayment {...props} />;
    case 'DigitalPaymentSuccess':
      return <DigitalPaymentSuccess {...props} />;
    case 'DocumentsAccepted':
      return <DocumentsAccepted {...props} />;
    case 'DocumentsAcceptedWithoutModal':
      return <DocumentsAcceptedWithoutModal {...props} />;
    case 'DocumentsAutoAccepted':
      return <DocumentsAutoAccepted {...props} />;
    case 'Email':
      return <Email {...props} />;
    case 'Error':
      return <Error {...props} />;
    case 'GeneralSurvey':
      return <GeneralSurvey {...props} />;
    case 'HeaderWithPrice':
      return <HeaderWithPrice {...props} />;
    case 'Iban':
      return <Iban {...props} />;
    case 'SwitchableBankDetails':
      return <SwitchableBankDetails {...props} />;
    case 'IbanSepa':
      return <IbanSepa {...props} />;
    case 'IllnessAndDisabilityConfirmation':
      return <IllnessAndDisabilityConfirmation {...props} />;
    case 'ImageBox':
      return <ImageBox {...props} />;
    case 'InformationModal':
      return <InformationModal {...props} />;
    case 'InformationText':
      return <InformationText {...props} />;
    case 'Input':
      return <Input {...props} />;
    case 'List':
      return <List {...props} />;
    case 'MonthAndYear':
      return <MonthAndYear {...props} />;
    case 'MonthAndYearInput':
      return <MonthAndYearInput {...props} />;
    case 'MultiplePetsDetails':
      return <MultiplePetsDetails {...props} />;
    case 'MultiplePolicyDetailsCard':
      return <MultiplePolicyDetailsCard {...props} />;
    case 'Name':
      return <Name {...props} />;
    case 'NotificationBanner':
      return <NotificationBanner {...props} />;
    case 'OptInCommunication':
      return <OptInCommunication {...props} />;
    case 'OptInMarketing':
      return <OptInMarketing {...props} />;
    case 'PageHeader':
      return <PageHeader {...props} />;
    case 'PaymentMethodSelector':
      return <PaymentMethodSelector {...props} />;
    case 'Phone':
      return <Phone {...props} />;
    case 'PolicyAddonSelector':
      return <PolicyAddonSelector {...props} />;
    case 'PolicyDetailsCard':
      return <PolicyDetailsCard {...props} />;
    case 'PolicyDetailsContents':
      return <PolicyDetailsContents {...props} />;
    case 'PolicyDetailsExtendedContent':
      return <PolicyDetailsExtendedContent {...props} />;
    case 'PolicyDetailsHeader':
      return <PolicyDetailsHeader {...props} />;
    case 'PolicyName':
      return <PolicyName {...props} />;
    case 'PolicySelector':
      return <PolicySelector {...props} />;
    case 'PolicySelectorForSpecialPetBreeds':
      return <PolicySelectorForSpecialPetBreeds {...props} />;
    case 'PostalCodeCity':
      return <PostalCodeCity {...props} />;
    case 'PostCode':
      return <PostCode {...props} />;
    case 'PriceAndPolicyInformation':
      return <PriceAndPolicyInformation {...props} />;
    case 'PriceButton':
      return <PriceButton {...props} />;
    case 'PriceDisplay':
      return <PriceDisplay {...props} />;
    case 'ProductDetails':
      return <ProductDetails {...props} />;
    case 'ProgressBar':
      return <ProgressBar {...props} />;
    case 'PromotionCode':
      return <PromotionCode {...props} />;
    case 'Radio':
      return <Radio {...props} />;
    case 'RadioWithErrorMessage':
      return <RadioWithErrorMessage {...props} />;
    case 'RecurringLeadButton':
      return <RecurringLeadButton {...props} />;
    case 'Review':
      return <Review {...props} />;
    case 'ReviewBankDetailsAndAcceptDocuments':
      return <ReviewBankDetailsAndAcceptDocuments {...props} />;
    case 'ReviewCustomerDetails':
      return <ReviewCustomerDetails {...props} />;
    case 'ReviewCustomerDetailsInfo':
      return <ReviewCustomerDetailsInfo {...props} />;
    case 'ReviewDentalCustomerAndPolicyInfo':
      return <ReviewDentalCustomerAndPolicyInfo {...props} />;
    case 'ReviewHealthInformation':
      return <ReviewHealthInformation {...props} />;
    case 'ReviewInsuredPersonDetails':
      return <ReviewInsuredPersonDetails {...props} />;
    case 'ReviewMultiplePetsDetails':
      return <ReviewMultiplePetsDetails {...props} />;
    case 'ReviewPetCustomerAndPolicyInfo':
      return <ReviewPetCustomerAndPolicyInfo {...props} />;
    case 'ReviewPetDetails':
      return <ReviewPetDetails {...props} />;
    case 'ReviewPetDetailsInfo':
      return <ReviewPetDetailsInfo {...props} />;
    case 'ReviewPolicyDocuments':
      return <ReviewPolicyDocuments {...props} />;
    case 'Select':
      return <Select {...props} />;
    case 'SelectPetBreedWithAutocomplete':
      return <SelectPetBreedWithAutocomplete {...props} />;
    case 'SepaMandate':
      return <SepaMandate {...props} />;
    case 'SingleStepPayment':
      return <SingleStepPayment {...props} />;
    case 'SpecialPetBreedPolicyCategory':
      return <SpecialPetBreedPolicyCategory {...props} />;
    case 'StartingDate':
      return <StartingDate {...props} />;
    case 'Street':
      return <Street {...props} />;
    case 'Success':
      return <Success {...props} />;
    case 'SuccessReferral':
      return <SuccessReferral {...props} />;
    case 'TriggerWebHookButton':
      return <TriggerWebHookButton {...props} />;
    case 'TriggerWebHookModal':
      return <TriggerWebHookModal {...props} />;
    case 'VoucherCode':
      return <VoucherCode {...props} />;
    case 'VoucherCodeLink':
      return <VoucherCodeLink {...props} />;
    case 'WidgetsForAnotherInsuredPerson':
      return <WidgetsForAnotherInsuredPerson {...props} />;
    case 'WidgetHeader':
      return <WidgetHeader {...props} />;

    default:
      return null;
  }
}

export default Widget;
