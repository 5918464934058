import usePolicyCategory from '../policy/usePolicyCategory';
import {
  INSURANCE_CATEGORY_DENTAL,
  INSURANCE_CATEGORY_HEALTH,
  INSURANCE_CATEGORY_PET_HEALTH,
  INSURANCE_CATEGORY_PET_LIABILITY,
} from '../../constants/Constants';
import {
  POLICY_CATEGORIES_DENTAL,
  POLICY_CATEGORIES_HEALTH,
  POLICY_CATEGORIES_PET_HEALTH,
  POLICY_CATEGORIES_PET_LIABILITY,
} from '../../constants/policyCategory';

const useInsuranceCategory = () => {
  const policyCategory = usePolicyCategory();

  if (POLICY_CATEGORIES_DENTAL.includes(policyCategory)) {
    return INSURANCE_CATEGORY_DENTAL;
  }

  if (POLICY_CATEGORIES_PET_HEALTH.includes(policyCategory)) {
    return INSURANCE_CATEGORY_PET_HEALTH;
  }

  if (POLICY_CATEGORIES_PET_LIABILITY.includes(policyCategory)) {
    return INSURANCE_CATEGORY_PET_LIABILITY;
  }

  if (POLICY_CATEGORIES_HEALTH.includes(policyCategory)) {
    return INSURANCE_CATEGORY_HEALTH;
  }

  return null;
};

export default useInsuranceCategory;
