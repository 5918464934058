import { DENTOLO, PETOLO, VITOLO } from '../../insuranceType/InsuranceTypes';
import useInsuranceType from '../../insuranceType/useInsuranceType';
import useUpdateLeadPayloadForDentolo from './useUpdateLeadPayloadForDentolo';
import useUpdateLeadPayloadForPetolo from './useUpdateLeadPayloadForPetolo';

const useUpdateLeadDataPayload = () => {
  const insuranceType = useInsuranceType();

  const prepareUpdateLeadPayloadForDentolo = useUpdateLeadPayloadForDentolo();
  const prepareUpdateLeadPayloadForPetolo = useUpdateLeadPayloadForPetolo();

  switch (insuranceType) {
    case DENTOLO:
      return prepareUpdateLeadPayloadForDentolo;
    case PETOLO:
      return prepareUpdateLeadPayloadForPetolo;
    case VITOLO:
      return prepareUpdateLeadPayloadForDentolo;
    default:
      return prepareUpdateLeadPayloadForDentolo;
  }
};

export default useUpdateLeadDataPayload;
