import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { COOKIE_DA_DIREKT_DA_CAMP } from '../../../constants/Constants';
import useCookie from '../../../hooks/useCookie';
import { extractQueryParams } from '../../../utils/URLHelper';
import usePolicyCategory from '../../policy/usePolicyCategory';
import useInsuranceCategory from '../../insuranceCategory/useInsuranceCategory';
import useReferredId from '../../referral/useReferredId';
import useSignupSource from '../../schema/useSignupSource';
import useGduid from '../../tracking/useGduid';
import useParameters from '../../schema/useParameters';
import usePartnerData from '../../partnerData/usePartnerData';

const useCreateLeadPayloadForDentolo = () => {
  const location = useLocation();
  const {
    utm_source: utmSource = null,
    utm_medium: utmMedium = null,
    utm_campaign: utmCampaign = null,
    utm_term: utmTerm = null,
    utm_content: utmContent = null,
    ex_agency_id: exAgencyId = null,
    channel = null,
    partner = null,
    sales_agent_id: salesAgentId = null,
    sales_agent_source: salesAgentSource = null,
  } = extractQueryParams(location);

  const policyCategory = usePolicyCategory();
  const insuranceCategory = useInsuranceCategory();
  const referredId = useReferredId();
  const getGduid = useGduid();
  const { flow } = useParameters();

  const signupSource = useSignupSource();
  const [getCookie] = useCookie();
  const cookieDaCamp = getCookie(COOKIE_DA_DIREKT_DA_CAMP);
  const partnerData = usePartnerData();

  const daDirektSignUpSources = useSelector(
    (state) => state?.schema?.schemaConfig?.signUpSourcesDaDirekt
  );
  const validatedDaDirektSignUpSource = useMemo(
    () => (Array.isArray(daDirektSignUpSources) ? daDirektSignUpSources : []),
    [daDirektSignUpSources]
  );

  const prepareCreateLeadPayloadForDentolo = useCallback(() => {
    const payload = {};

    payload.payload = {};

    payload.insurance_category = insuranceCategory;

    payload.sales_channel = {
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
      utm_term: utmTerm,
      utm_content: utmContent,
      ex_agency_id: exAgencyId,
      channel,
      partner,
      flow,
      sales_agent: {
        id: salesAgentId,
        source: salesAgentSource,
      },
    };

    payload.contract = {
      policy_category: policyCategory,
    };

    if (referredId) {
      payload.sales_channel = { ...payload.sales_channel, referred_id: referredId };
    }

    // Add `mediacode` to `sales_channel` from cookie only for DA-Direkt
    if (validatedDaDirektSignUpSource.includes(signupSource)) {
      payload.sales_channel = { ...payload.sales_channel, mediacode: cookieDaCamp };
    }

    payload.gduid = getGduid();

    payload.partner_data = partnerData;

    return payload;
  }, [
    channel,
    cookieDaCamp,
    exAgencyId,
    flow,
    getGduid,
    insuranceCategory,
    partner,
    partnerData,
    policyCategory,
    referredId,
    salesAgentId,
    salesAgentSource,
    signupSource,
    utmCampaign,
    utmContent,
    utmMedium,
    utmSource,
    utmTerm,
    validatedDaDirektSignUpSource,
  ]);

  return prepareCreateLeadPayloadForDentolo;
};

export default useCreateLeadPayloadForDentolo;
