import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLeadDataFieldsGroup } from './leadSlice';
import { clearErrorsOnPage } from '../errorValidation/errorValidationSlice';

const useLeadDataSynchronizer = () => {
  const dispatch = useDispatch();
  const leadData = useSelector((state) => state?.lead?.leadData);
  const { customer, insured_person: insuredPerson } = leadData;
  const { postcode, street_name: streetName, house_number: houseNumber, city } = customer || {};
  const { same_customer_address: sameCustomerAddress } = insuredPerson || {};

  // This effect is to copy the customer address to the insured person address
  // if the user chooses that they have the same address.
  useEffect(() => {
    if (sameCustomerAddress) {
      const fieldsToChange = {
        street_name: streetName,
        house_number: houseNumber,
        postcode,
        city,
      };
      const payload = {
        fieldGroupName: 'insured_person',
        fieldsToChange,
      };
      dispatch(setLeadDataFieldsGroup(payload));

      // Clear the errors set by the insured_person address widgets
      dispatch(
        clearErrorsOnPage({
          fields: Object.keys(fieldsToChange).map((key) => `insured_person.${key}`),
        })
      );
    }
  }, [sameCustomerAddress, postcode, streetName, houseNumber, city, dispatch]);
};

export default useLeadDataSynchronizer;
