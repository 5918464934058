import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useWindow from '../../../hooks/useWindow';
import useFaircareBaseUrl from '../../schema/useFaircareBaseUrl';
import useWidgetsAPIData from '../useWidgetsAPIData';
import usePolicyCategory from '../../policy/usePolicyCategory';
import usePolicyAddonsAPI from '../usePolicyAddonsAPI';

const useDentoloPolicyPriceAPI = () => {
  const [, , , /* getFromWindow */ /* assignToWindow */ /* openNewWindow */ postMessage] =
    useWindow();

  const policyCategory = usePolicyCategory();
  const policyCategoryQueryParam = `policy_category=${policyCategory}`;

  const dateOfBirthCustomer = useSelector(
    (state) => state?.lead?.leadData?.customer?.date_of_birth
  );
  const dateOfBirthInsuredPerson = useSelector(
    (state) => state?.lead?.leadData?.insured_person?.date_of_birth
  );
  const dateOfBirth = dateOfBirthInsuredPerson || dateOfBirthCustomer;
  const dateOfBirthQueryParam = dateOfBirth ? `date_of_birth=${dateOfBirth}` : '';

  const startingAt = useSelector((state) => state?.lead?.leadData?.contract?.starting_at);
  const startingAtQueryParam = startingAt ? `starting_at=${startingAt}` : '';

  const [selectedAddonsDetails] = usePolicyAddonsAPI();
  const selectedAddonsQueryParam = selectedAddonsDetails
    .map((addon) => `policy_addons[]=${addon?.key}`)
    .join('&');

  const queryParams = [
    policyCategoryQueryParam,
    dateOfBirthQueryParam,
    startingAtQueryParam,
    selectedAddonsQueryParam,
  ]
    .filter((param) => !!param)
    .join('&');

  const [baseUrl, defaultHeaders] = useFaircareBaseUrl();
  const url = `${baseUrl}/api/v1/price?${queryParams}`;

  const { data: fetchedAPIData, loading: policyPriceDetailsLoading } = useWidgetsAPIData(
    url,
    defaultHeaders
  );

  const policyPriceDetails = fetchedAPIData || null;

  const policyPrice = policyPriceDetails?.policy_price || 0;

  useEffect(() => {
    postMessage({ event: 'policy_price', policyPrice });
  }, [postMessage, policyPrice]);

  return [policyPriceDetails, policyPriceDetailsLoading];
};

export default useDentoloPolicyPriceAPI;
