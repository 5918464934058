import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { extractQueryParams } from '../../utils/URLHelper';

const useMarketingTestTrackingParameters = () => {
  const location = useLocation();

  const trackingParametersFromSchemaConfig = useSelector(
    (state) => state?.schema?.schemaConfig?.marketingTestTrackingParameters
  );

  if (
    !Array.isArray(trackingParametersFromSchemaConfig) ||
    trackingParametersFromSchemaConfig.length < 1
  ) {
    return {};
  }

  const paramsFromUrl = extractQueryParams(location);

  const trackersWithValues = trackingParametersFromSchemaConfig.reduce(
    (acc, curr) => ({ ...acc, [curr]: paramsFromUrl[`${curr}`] || '' }),
    {}
  );

  return trackersWithValues;
};

export default useMarketingTestTrackingParameters;
