import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import usePolicyCategory from '../../policy/usePolicyCategory';
import useInsuranceCategory from '../../insuranceCategory/useInsuranceCategory';
import useSignupSource from '../../schema/useSignupSource';
import useSurveyId from '../../schema/useSurveyId';
import useGduid from '../../tracking/useGduid';
import useReferredId from '../../referral/useReferredId';
import useTrackingParameterValues from '../../tracking/useTrackingParameterValues';
import useMarketingTestTrackingParameters from '../../tracking/useMarketingTestTrackingParameters';
import usePolicyAddonsAPI from '../../widgetsAPIData/usePolicyAddonsAPI';
import { processWebhooksAndEmailTriggers } from '../leadActionUtils';
import { extractQueryParams, getPageIdFromUrl } from '../../../utils/URLHelper';
import { DEFAULT_INSURANCE_FOR } from '../../../constants/DefaultValues';
import useCookie from '../../../hooks/useCookie';
import { updateWebhookPayload } from '../../../utils/WebhookPayloadHelper';
import useParameters from '../../schema/useParameters';
import useCookieConsentStatus from '../../tracking/useCookieConsentStatus';
import usePartnerData from '../../partnerData/usePartnerData';
import {
  COOKIE_DA_DIREKT_DA_CAMP,
  INSURANCE_FOR_ANOTHER,
  INSURANCE_FOR_ME,
} from '../../../constants/Constants';

const useUpdateLeadPayloadForDentolo = () => {
  const policyCategory = usePolicyCategory();
  const insuranceCategory = useInsuranceCategory();
  const signupSource = useSignupSource();
  const surveyId = useSurveyId();
  const getGduid = useGduid();
  const referredId = useReferredId();
  const [getCookie] = useCookie();
  const cookieDaCamp = getCookie(COOKIE_DA_DIREKT_DA_CAMP);
  const partnerData = usePartnerData();

  const location = useLocation();
  const {
    utm_source: utmSource = null,
    utm_medium: utmMedium = null,
    utm_campaign: utmCampaign = null,
    utm_term: utmTerm = null,
    utm_content: utmContent = null,
    ex_agency_id: exAgencyId = null,
    channel = null,
    partner = null,
    sales_agent_id: salesAgentId = null,
    sales_agent_source: salesAgentSource = null,
  } = extractQueryParams(location);

  const { getCookieConsentList } = useCookieConsentStatus();
  const cookieConsentList = getCookieConsentList();

  const leadData = useSelector((state) => state?.lead?.leadData);
  const questions = useSelector((state) => state?.questions?.answers);

  const { flow, organisation } = useParameters();
  const trackingParameters = useTrackingParameterValues();
  const marketingTestTrackingParameters = useMarketingTestTrackingParameters();

  const [selectedAddonsDetails] = usePolicyAddonsAPI();
  const selectedPolicyAddons = selectedAddonsDetails.map((addon) => addon?.key);

  const daDirektSignUpSources = useSelector(
    (state) => state?.schema?.schemaConfig?.signUpSourcesDaDirekt
  );
  const validatedDaDirektSignUpSource = Array.isArray(daDirektSignUpSources)
    ? daDirektSignUpSources
    : [];

  const prepareUpdateLeadPayloadForDentolo = (webhooks, emailTriggers) => {
    const payload = {};

    const { leadActions, webhookIds, actionPayloads } = processWebhooksAndEmailTriggers(
      webhooks,
      emailTriggers
    );

    if (Array.isArray(leadActions)) {
      payload.actions = leadActions;
    } else {
      payload.actions = [];
    }

    const webhookPayload =
      Object.keys(actionPayloads)?.length > 0
        ? updateWebhookPayload(actionPayloads, organisation)
        : {};

    payload.additional = {
      survey_id: surveyId,
      webhook_ids: webhookIds || {},
      action_payloads: webhookPayload,
      ...trackingParameters,
      marketing_test_params: marketingTestTrackingParameters,
      cookie_consents: cookieConsentList,
    };

    payload.insurance_category = insuranceCategory;

    if (leadData?.bank_account) {
      payload.bank_account = leadData.bank_account;
    } else {
      payload.bank_account = {};
    }

    if (leadData?.communication_list) {
      payload.communication_list = leadData.communication_list;
    } else {
      payload.communication_list = {};
    }

    payload.contract = {
      ...leadData?.contract,
      signed: false,
      policy_addons: selectedPolicyAddons,
      policy_category: policyCategory,
    };

    if (!payload?.contract?.insurance_for) {
      payload.contract = { ...payload.contract, insurance_for: DEFAULT_INSURANCE_FOR };
    }

    if (payload?.contract?.insurance_for === INSURANCE_FOR_ANOTHER) {
      payload.contract = { ...payload.contract, insured_person: leadData?.insured_person };
    } else if (payload?.contract?.insurance_for === INSURANCE_FOR_ME) {
      const insuredPerson = {
        first_name: leadData?.customer?.first_name || leadData?.insured_person?.first_name,
        last_name: leadData?.customer?.last_name || leadData?.insured_person?.last_name,
        gender: leadData?.customer?.gender || leadData?.insured_person?.gender,
        date_of_birth: leadData?.customer?.date_of_birth || leadData?.insured_person?.date_of_birth,
        postcode: leadData?.customer?.postcode || leadData?.insured_person?.postcode,
        street_name: leadData?.customer?.street_name || leadData?.insured_person?.street_name,
        house_number: leadData?.customer?.house_number || leadData?.insured_person?.house_number,
        city: leadData?.customer?.city || leadData?.insured_person?.city,
      };

      payload.contract = { ...payload.contract, insured_person: insuredPerson };
    }
    if (!leadData?.customer) {
      payload.customer = {};
    } else {
      payload.customer = leadData.customer;
      if (payload?.customer?.email === '') {
        const { email, ...customerDetailsWithoutEmail } = payload.customer;
        payload.customer = customerDetailsWithoutEmail;
      }
      if (payload?.customer?.phone_number === '') {
        const { phone_number: phoneMumber, ...customerDetailsWithoutPhone } = payload.customer;
        payload.customer = customerDetailsWithoutPhone;
      }
    }

    if (!payload?.customer?.person_to_insured) {
      payload.customer = { ...payload.customer, person_to_insured: 'yes' };
    }

    payload.customer = { ...payload.customer, sign_up_source: signupSource };

    // Digital payments
    if (leadData?.digital_payment_method) {
      payload.digital_payment_method = { ...leadData.digital_payment_method };
    }

    payload.gduid = getGduid();

    if (!payload?.previous_insurance) {
      payload.previous_insurance = {};
    }
    payload.previous_insurance.has_insurance = !!(leadData?.contract?.previously_insured === 'yes');
    payload.previous_insurance.company = '';
    payload.previous_insurance.policy_name = '';

    if (leadData?.sales_channel) {
      payload.sales_channel = leadData.sales_channel;
    } else {
      payload.sales_channel = {};
    }

    payload.sales_channel = {
      ...payload.sales_channel,
      flow: payload?.flow || flow,
      utm_source:
        !!utmSource && utmSource !== payload.sales_channel?.utm_source
          ? utmSource
          : payload.sales_channel?.utm_source,
      utm_medium:
        !!utmMedium && utmMedium !== payload.sales_channel?.utm_medium
          ? utmMedium
          : payload.sales_channel?.utm_medium,
      utm_campaign:
        !!utmCampaign && utmCampaign !== payload.sales_channel?.utm_campaign
          ? utmCampaign
          : payload.sales_channel?.utm_campaign,
      utm_term:
        !!utmTerm && utmTerm !== payload.sales_channel?.utm_term
          ? utmTerm
          : payload.sales_channel?.utm_term,
      utm_content:
        !!utmContent && utmContent !== payload.sales_channel?.utm_content
          ? utmContent
          : payload.sales_channel?.utm_content,
      ex_agency_id:
        !!exAgencyId && exAgencyId !== payload.sales_channel?.ex_agency_id
          ? exAgencyId
          : payload.sales_channel?.ex_agency_id,
      channel:
        !!channel && channel !== payload.sales_channel?.channel
          ? channel
          : payload.sales_channel?.channel,
      partner:
        !!partner && partner !== payload.sales_channel?.partner
          ? partner
          : payload.sales_channel?.partner,
      sales_agent: {
        id: salesAgentId || leadData?.sales_channel?.sales_agent?.id,
        source: salesAgentSource || leadData?.sales_channel?.sales_agent?.source,
      },
    };

    if (!!referredId && leadData?.sales_channel?.referred_id !== referredId) {
      payload.sales_channel = { ...payload.sales_channel, referred_id: referredId };
    }

    // Add `mediacode` to `sales_channel` from cookie only for DA-Direkt
    if (validatedDaDirektSignUpSource.includes(signupSource)) {
      payload.sales_channel = { ...payload.sales_channel, mediacode: cookieDaCamp };
    }

    payload.questions = questions;
    payload.partner_data = leadData?.partner_data || partnerData;

    payload.voucher_codes = leadData?.voucher_codes;

    payload.uuid = leadData?.uuid;

    payload.page_id = getPageIdFromUrl();

    return payload;
  };

  return prepareUpdateLeadPayloadForDentolo;
};

export default useUpdateLeadPayloadForDentolo;
