import { useCallback } from 'react';
import useWindow from './useWindow';

export default function useLocalStorage() {
  const [getFromWindow] = useWindow();
  const storage = getFromWindow('localStorage');

  const getFromLocalStorage = useCallback(
    (key) => {
      const value = storage ? storage.getItem(key) : null;
      return value;
    },
    [storage]
  );

  const saveToLocalStorage = useCallback(
    (key, value) => {
      if (storage) {
        storage.setItem(key, JSON.stringify(value));
      }
      return null;
    },
    [storage]
  );

  const removeFromLocalStorage = useCallback(
    (key) => {
      if (storage) {
        storage.removeItem(key);
      }
      return null;
    },
    [storage]
  );

  return [getFromLocalStorage, saveToLocalStorage, removeFromLocalStorage];
}
