import { DENTOLO, PETOLO, VITOLO } from '../../insuranceType/InsuranceTypes';
import useInsuranceType from '../../insuranceType/useInsuranceType';
import useDentoloPoliciesDataAPI from './useDentoloPoliciesDataAPI';
import usePetoloPoliciesDataAPI from './usePetoloPoliciesDataAPI';
import useVitoloPoliciesDataAPI from './useVitoloPoliciesDataAPI';

const usePoliciesDataAPI = () => {
  const insuranceType = useInsuranceType();

  switch (insuranceType) {
    case DENTOLO:
      return useDentoloPoliciesDataAPI;
    case PETOLO:
      return usePetoloPoliciesDataAPI;
    case VITOLO:
      return useVitoloPoliciesDataAPI;
    default:
      return useDentoloPoliciesDataAPI;
  }
};

export default usePoliciesDataAPI;
