import { useSelector } from 'react-redux';
import useFaircareBaseUrl from '../../schema/useFaircareBaseUrl';
import usePolicyCategory from '../../policy/usePolicyCategory';
import useWidgetsAPIData from '../useWidgetsAPIData';
import useUuid from '../../customer/useUuid';

const useVitoloPoliciesDataAPI = () => {
  const uuid = useUuid();

  const combinedDocuments = useSelector(
    (state) => state?.schema?.activeSchema?.SCHEMA?.combinedDocuments
  );

  const [baseUrl, defaultHeaders] = useFaircareBaseUrl();
  const url = `${baseUrl}/api/v1/policies?combined=${!!combinedDocuments}&uuid=${uuid}`;

  const { data: fetchedAPIData, loading: policiesDataLoading } = useWidgetsAPIData(
    url,
    defaultHeaders
  );

  const policiesData = Array.isArray(fetchedAPIData) ? fetchedAPIData : [];

  const policyCategory = usePolicyCategory();
  const policyDataForCategory = policiesData.find((policy) => policy?.category === policyCategory);

  return [policyDataForCategory, policiesData, policiesDataLoading];
};

export default useVitoloPoliciesDataAPI;
