import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import useUuid from '../customer/useUuid';
import useFacebookIdentifier from './identifiers/useFacebookIdentifier';
import useGoogleAnalyticsIdentifier from './identifiers/useGoogleAnalyticsIdentifier';
import useOutbrainIdentifier from './identifiers/useOutbrainIdentifier';
import useTaboolaIdentifier from './identifiers/useTaboolaIdentifier';
import useTiktokIdentifier from './identifiers/useTiktokIdentifier';
import useGoogleBraidIdentifier from './identifiers/useGoogleBraidIdentifier';
import useGoogleWbraidIdentifier from './identifiers/useGoogleWbraidIdentifier';
import useRedditIdentifier from './identifiers/useRedditIdentifier';
import useZemantaIdentifier from './identifiers/useZemantaIdentifier';
import useMediagoIdentifier from './identifiers/useMediagoIdentifier';
import useMicrosoftBingIdentifier from './identifiers/useMicrosoftBingIdentifier';
import useAwinIdentifier from './identifiers/useAwinIdentifier';

const useAdServiceIdentifiers = () => {
  const [previousEmailValue, setPreviousEmailValue] = useState('');
  const customerEmail = useSelector((state) => state?.lead?.leadData?.customer?.email);

  const uuid = useUuid();

  const storeFacebookIdentifier = useFacebookIdentifier();
  const storeGoogleAnalyticsIdentifier = useGoogleAnalyticsIdentifier();
  const storeOutbrainIdentifier = useOutbrainIdentifier();
  const storeTaboolaIdentifier = useTaboolaIdentifier();
  const storeTiktokIdentifier = useTiktokIdentifier();
  const storeGoogleBraidIdentifier = useGoogleBraidIdentifier();
  const storeGoogleWbraidIdentifier = useGoogleWbraidIdentifier();
  const storeRedditIdentifier = useRedditIdentifier();
  const storeZemantaIdentifier = useZemantaIdentifier();
  const storeMediagoIdentifier = useMediagoIdentifier();
  const storeMicrosoftBingIdentifier = useMicrosoftBingIdentifier();
  const storeAwinIdentifier = useAwinIdentifier();

  const storeAllAdServiceIdentifiers = useCallback(
    (email) => {
      storeFacebookIdentifier(uuid, email);
      storeGoogleAnalyticsIdentifier(uuid, email);
      storeOutbrainIdentifier(uuid, email);
      storeTaboolaIdentifier(uuid, email);
      storeTiktokIdentifier(uuid, email);
      storeGoogleBraidIdentifier(uuid, email);
      storeGoogleWbraidIdentifier(uuid, email);
      storeRedditIdentifier(uuid, email);
      storeZemantaIdentifier(uuid, email);
      storeMediagoIdentifier(uuid, email);
      storeMicrosoftBingIdentifier(uuid, email);
      storeAwinIdentifier(uuid, email);
    },
    [
      uuid,
      storeFacebookIdentifier,
      storeGoogleAnalyticsIdentifier,
      storeOutbrainIdentifier,
      storeTaboolaIdentifier,
      storeTiktokIdentifier,
      storeGoogleBraidIdentifier,
      storeGoogleWbraidIdentifier,
      storeRedditIdentifier,
      storeZemantaIdentifier,
      storeMediagoIdentifier,
      storeMicrosoftBingIdentifier,
      storeAwinIdentifier,
    ]
  );

  useEffect(() => {
    if (previousEmailValue === '' && !!uuid) {
      // When the page first loads and lead data is available,
      // always store ad service identifier even if email value is present or not
      storeAllAdServiceIdentifiers(customerEmail);
      setPreviousEmailValue(customerEmail);
    } else if (!previousEmailValue && !!customerEmail) {
      // There was no email provided before, and the customer has just typed in the email,
      // Store ad service identifier again with email details
      storeAllAdServiceIdentifiers(customerEmail);
      setPreviousEmailValue(customerEmail);
    }
  }, [customerEmail, previousEmailValue, uuid, storeAllAdServiceIdentifiers]);

  return null;
};

export default useAdServiceIdentifiers;
