// List of available `locales`
export const LOCALES = {
  DE: 'de',
  FR: 'fr',
};

export const DEFAULT_LOCALE = LOCALES.DE;
export const HEADER_API_GETOLO_LOCALE = 'X-GETOLO-LOCALE';

// Get country from URL as locale or use default locale
export const getLocale = () => {
  const params = new URLSearchParams(window?.location?.search);

  // Get country from URL and convert it to lowercase
  const country = params.get('country')?.toLowerCase();

  // Check supplied country is valid, otherwise use default locale
  const localeToUse = Object.values(LOCALES).includes(country) ? country : DEFAULT_LOCALE;

  return localeToUse;
};
