import { useLocation } from 'react-router-dom';
import { extractQueryParams } from '../../utils/URLHelper';

const useTrackingParameterValues = () => {
  const location = useLocation();
  const {
    source = '',
    tbclid = '',
    gclid = '',
    obclid = '',
    awc = '',
    ScCid = '',
  } = extractQueryParams(location);
  return { source, tbclid, gclid, obclid, awc, ScCid };
};

export default useTrackingParameterValues;
