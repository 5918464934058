import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import useS2STrackingAPI from '../../../api/useS2STrackingAPI';
import { extractQueryParams } from '../../../utils/URLHelper';

const useGoogleWbraidIdentifier = () => {
  const location = useLocation();
  const [storeAdServiceIdentifierAPI] = useS2STrackingAPI();

  const storeGoogleWbraidIdentifier = useCallback(
    (uuid, email) => {
      const { wbraid = null } = extractQueryParams(location);
      if (wbraid) {
        const data = { wbraid };
        storeAdServiceIdentifierAPI(uuid, email, data);
      }
    },
    [location, storeAdServiceIdentifierAPI]
  );

  return storeGoogleWbraidIdentifier;
};

export default useGoogleWbraidIdentifier;
