import { createSlice } from '@reduxjs/toolkit';

import {
  getObjectProperty,
  isEmptyArray,
  isEmptyObject,
  setObjectProperty,
} from '../../utils/GeneralHelper/GeneralHelper';

const insuredPerson = {
  postcode: null,
  first_name: null,
  last_name: null,
  date_of_birth: null,
  street_name: null,
  house_number: null,
  city: null,
  gender: null,
};

const insuredPet = {
  pet_type: null,
  gender: null,
  name: null,
  date_of_birth: null,
  breed_id: null,
  transponder_code: null,
};

export const contractData = {
  policy_category: '',
  starting_at: null,
  documents_accepted_at: null,
  created_at: '',
  risk_carrier: '',
  signed: null,
  billing_day: '',
  insurance_for: '',
  insured_legally: '',
  insured_person: insuredPerson,
  insured_pet: insuredPet,
};

const leadData = {
  bank_account: {
    iban: null,
  },
  communication_list: {
    e_mail: null,
    telephone: null,
  },
  contract: contractData,
  contracts: [contractData],
  created_at: '',
  customer: {
    gender: null,
    first_name: null,
    last_name: null,
    date_of_birth: null,
    email: null,
    street_name: null,
    house_number: null,
    postcode: null,
    city: null,
    country: null,
    phone_number: null,
    consultation_protocol_path: null,
    sign_up_source: null,
  },
  digital_payment_method: {
    customer_reference: null,
    external_payment_id: null,
    iframe_submit_success: false,
    iframe_url: null,
    instruction_reference: null,
  },
  gduid: null,
  id: null,
  insured_person: {
    postcode: null,
    first_name: null,
    last_name: null,
    date_of_birth: null,
    street_name: null,
    house_number: null,
    city: null,
    gender: null,
  },
  key: '',
  page_id: null,
  pet: insuredPet,
  previous_insurance: null,
  sales_channel: {},
  updated_at: '',
  uuid: '',
  voucher_codes: [],
};

const convertSingleToMultipleContracts = (leadDataToConvert) => {
  const { contract, contracts } = leadDataToConvert || {};

  if (!leadDataToConvert || (!!contracts && !isEmptyArray(contracts))) return leadDataToConvert;

  let convertedLeadData = { ...leadDataToConvert };

  if (!!contract && !isEmptyObject(contract)) {
    convertedLeadData = { ...leadDataToConvert, contracts: [contract], contract: null };
  } else {
    convertedLeadData = { ...leadDataToConvert, contracts: [], contract: null };
  }

  return convertedLeadData;
};

const convertMultipleToSingleContract = (leadDataToConvert) => {
  const { contract, contracts } = leadDataToConvert || {};

  if (!leadDataToConvert || (!!contract && !isEmptyObject(contract))) return leadDataToConvert;

  let convertedLeadData = { ...leadDataToConvert };

  convertedLeadData = {
    ...leadDataToConvert,
    contract: contracts?.[0] || {},
    pet: contracts?.[0]?.insured_pet || {},
    contracts: null,
  };

  return convertedLeadData;
};

const leadDetails = createSlice({
  name: 'lead',
  initialState: {
    leadData,
    downloadedLeadData: null, // Refer useLeadDataField.js to know the significance of this
  },
  reducers: {
    leadDataReceived(state, action) {
      const { leadData: receivedLeadData, multipleContracts } = action.payload;

      /* --- To avoid race conditions --- */
      const receivedLeadDataWithAdditionalInfo = receivedLeadData;
      if (!receivedLeadDataWithAdditionalInfo.insured_person) {
        receivedLeadDataWithAdditionalInfo.insured_person = state.leadData.insured_person;
      }
      if (!receivedLeadDataWithAdditionalInfo.pet) {
        receivedLeadDataWithAdditionalInfo.pet = state.leadData.pet;
      }
      /* -------------------------------- */

      let convertedLeadDataWithAdditionalInfo = { ...receivedLeadDataWithAdditionalInfo };

      // checking if `multipleContracts` is `true` or `false because if its undefined or null dont do conversion
      if (multipleContracts === true || multipleContracts === false) {
        convertedLeadDataWithAdditionalInfo = multipleContracts
          ? convertSingleToMultipleContracts(receivedLeadDataWithAdditionalInfo)
          : convertMultipleToSingleContract(receivedLeadDataWithAdditionalInfo);
      }

      state.leadData = convertedLeadDataWithAdditionalInfo;
      state.downloadedLeadData = receivedLeadData;
    },
    setLeadDataField(state, action) {
      const { fieldGroupName, fieldName, value } = action.payload;

      if (fieldGroupName) {
        if (fieldName) {
          setObjectProperty(state.leadData, `${fieldGroupName}.${fieldName}`, value);
        } else {
          setObjectProperty(state.leadData, `${fieldGroupName}`, value);
        }
      }
    },
    setMultipleLeadDataFieldValues(state, action) {
      const { fieldGroupName, fieldName, value } = action.payload;

      const fieldGroupValue = getObjectProperty(state.leadData, fieldGroupName);

      const updatedFieldGroupValue = (fieldGroupValue || []).map((groupValue) => {
        return { ...groupValue, [fieldName]: value };
      });

      setObjectProperty(state.leadData, `${fieldGroupName}`, updatedFieldGroupValue);
    },
    setLeadDataFieldsGroup(state, action) {
      const { fieldGroupName, fieldsToChange } = action.payload;

      if (fieldGroupName) {
        if (fieldsToChange) {
          const fieldsGroupValue = { ...state.leadData[`${fieldGroupName}`], ...fieldsToChange };
          state.leadData[`${fieldGroupName}`] = fieldsGroupValue;
        }
      }
    },
    removeLeadDataField(state, action) {
      const { fieldGroupName, fieldName } = action.payload;
      if (fieldGroupName) {
        if (fieldName) {
          const { [fieldName]: value, ...rest } = state.leadData[`${fieldGroupName}`] || {};
          state.leadData[`${fieldGroupName}`] = rest;
        } else {
          const { [fieldGroupName]: value, ...rest } = state.leadData || {};
          state.leadData = rest;
        }
      }
    },
    addContract(state, action) {
      const payload = action.payload || {};

      const newContract = {
        key: Date.now(),
        ...payload,
      };

      state.leadData.contracts.push(newContract);
    },
    removeContract(state, action) {
      const { index } = action.payload;
      const { contracts } = state.leadData;

      const updatedContracts = contracts.filter((_, idx) => idx !== index);

      state.leadData.contracts = updatedContracts;
    },
    updateDigitalPaymentInLeadData(state, action) {
      const newDigiPayment = action.payload;

      state.leadData.digital_payment_method = {
        ...state.leadData.digital_payment_method,
        ...newDigiPayment,
      };
    },
  },
});

export const {
  leadDataReceived,
  setLeadDataField,
  setMultipleLeadDataFieldValues,
  setLeadDataFieldsGroup,
  removeLeadDataField,
  addContract,
  removeContract,
  updateDigitalPaymentInLeadData,
} = leadDetails.actions;

export default leadDetails.reducer;

export const saveLeadDataToStore = (leadDataToBeSaved, multipleContracts) => (dispatch) => {
  if (leadDataToBeSaved) {
    // add `key` to each contract object to avoid `key` conflict in `PetDetailsGroup` widget
    const { contracts } = leadDataToBeSaved || {};
    let updatedLeadDataToBeSaved = leadDataToBeSaved || {};

    if (Array.isArray(contracts) && contracts?.length > 0) {
      const updatedContracts = contracts.map((contract, index) => ({
        ...contract,
        key: Date.now() + index,
      }));
      updatedLeadDataToBeSaved = { ...leadDataToBeSaved, contracts: updatedContracts };
    }

    dispatch(leadDataReceived({ leadData: updatedLeadDataToBeSaved, multipleContracts }));
    return leadDataToBeSaved;
  }
  return null;
};
