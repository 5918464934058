import React from 'react';
import ReactMarkdown from 'react-markdown';
import useDynamicText from '../widgets/PageHeader/useDynamicText';
import {
  StyledPageHeaderContainer,
  StyledImageContainer,
  StyledImage,
  StyledTitleAndDescriptionContainer,
  StyledTitle,
  StyledDescription,
} from '../widgets/PageHeader/PageHeader.styled';

function DEPRECATEDTitleAndDescription({ page, thumbImage }) {
  const { description, dynamicDescription, descriptionHeight } = page || {};
  const populatedDescription = useDynamicText(description);
  const populatedDynamicDescription = useDynamicText(dynamicDescription);
  const descriptionToShow = dynamicDescription ? populatedDynamicDescription : populatedDescription;

  const { description_subtext: descriptionSubtext } = page || {};
  const populatedDescriptionSubtext = useDynamicText(descriptionSubtext);

  const validatedDescriptionHeight = Number.isInteger(descriptionHeight) ? descriptionHeight : 0;

  const hasContent = !!thumbImage || !!descriptionToShow || !!populatedDescriptionSubtext;

  return (
    <StyledPageHeaderContainer hasContent={hasContent}>
      {!!thumbImage && (
        <StyledImageContainer>
          <StyledImage src={thumbImage} alt="Thumbnail" />
        </StyledImageContainer>
      )}

      <StyledTitleAndDescriptionContainer descriptionHeight={validatedDescriptionHeight}>
        {!!descriptionToShow && (
          <StyledTitle data-hj-suppress="true" key={descriptionToShow}>
            <ReactMarkdown>{descriptionToShow}</ReactMarkdown>
          </StyledTitle>
        )}

        {!!populatedDescriptionSubtext && (
          <StyledDescription data-hj-suppress="true" key={populatedDescriptionSubtext}>
            <ReactMarkdown>{populatedDescriptionSubtext}</ReactMarkdown>
          </StyledDescription>
        )}
      </StyledTitleAndDescriptionContainer>
    </StyledPageHeaderContainer>
  );
}

export default DEPRECATEDTitleAndDescription;
