import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { NO_POLICY_ADDON_SELECTED } from '../../constants/Constants';
import { extractQueryParams } from '../../utils/URLHelper';
import useSurveyId from '../schema/useSurveyId';
import useMultipleContracts from '../schema/useMultipleContracts';

/* IMPORTANT - This should not be used to get addon details. Addons have to be
  validated first by making sure that it's compatible with the policy category.
  Use usePolicyAddonsAPI hook to do this.
*/
const usePolicyAddons = () => {
  const location = useLocation();
  const isMultipleContracts = useMultipleContracts();

  const { policy_addons: suppliedAddons = null } = extractQueryParams(location);
  const suppliedAddonsArray = suppliedAddons ? suppliedAddons.split(',') : [];

  // #TODO: getting `policy_addons` from the first contract as it is same for all the contracts for now.
  const appliedAddons = useSelector((state) =>
    isMultipleContracts
      ? state?.lead?.leadData?.contracts?.[0]?.policy_addons
      : state?.lead?.leadData?.contract?.policy_addons
  );
  const appliedAddonsArray = Array.isArray(appliedAddons) ? appliedAddons : [];

  let policyAddons = Array.from(new Set(appliedAddonsArray));
  if (Array.isArray(suppliedAddonsArray)) {
    if (suppliedAddonsArray.some((arr) => arr === NO_POLICY_ADDON_SELECTED)) {
      policyAddons = [];
    } else if (suppliedAddonsArray.length > 0) {
      policyAddons = Array.from(new Set(suppliedAddonsArray));
    }
  }

  const surveyId = useSurveyId();
  const addonsPossibleForThisSurvey = useSelector(
    (state) => state?.schema?.schemaConfig?.addons?.[`${surveyId}`]
  );

  if (addonsPossibleForThisSurvey) {
    return policyAddons;
  }
  return [];
};

export default usePolicyAddons;
