export const { NODE_ENV, PUBLIC_URL } = process.env;
export const APP_ENV = process.env.REACT_APP_ENV;

// Datadog variables
export const DATADOG_ENV = process.env.REACT_APP_DATADOG_ENV;
export const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
export const DATADOG_SITE = 'datadoghq.eu';
export const DATADOG_SERVICE = 'tyrion';

export const SCHEMA_BASE_URL = process.env.REACT_APP_SCHEMA_BASE_URL;

export const LEADS_BASE_URL = process.env.REACT_APP_LEADS_BASE_URL;
export const LEADS_FAIRCARE_BASE_URL = process.env.REACT_APP_LEADS_FAIRCARE_BASE_URL;
export const LEADS_CLIENT_API_KEY = process.env.REACT_APP_LEADS_CLIENT_API_KEY;

export const PETOLO_BASE_URL = process.env.REACT_APP_PETOLO_BASE_URL;
export const PETOLO_FAIRCARE_BASE_URL = process.env.REACT_APP_PETOLO_FAIRCARE_BASE_URL;
export const PETOLO_CLIENT_API_KEY = process.env.REACT_APP_PETOLO_CLIENT_API_KEY;

export const STRYKER_BASE_URL = process.env.REACT_APP_STRYKER_BASE_URL;
export const STRYKER_USERNAME = process.env.REACT_APP_STRYKER_USERNAME;
export const STRYKER_PASSWORD = process.env.REACT_APP_STRYKER_PASSWORD;

export const SURVEY_BASE_URL = process.env.REACT_APP_SURVEY_BASE_URL;

export const TRACKER_ENV = process.env.REACT_APP_TRACKER_ENV;

export const USE_SHADOW_DOM = process.env.REACT_APP_USE_SHADOW_DOM;
