import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';

import useWindow from '../../../hooks/useWindow';
import useSurveyId from '../../schema/useSurveyId';
import usePolicyCategory from '../../policy/usePolicyCategory';
import useGduid from '../useGduid';
import usePolicyAddonsAPI from '../../widgetsAPIData/usePolicyAddonsAPI';
import usePolicyPriceAPI from '../../widgetsAPIData/usePolicyPriceAPI';
import useMultipleContracts from '../../schema/useMultipleContracts';
import useMultiplePolicyPriceAPI from '../../widgetsAPIData/useMultiplePolicyPriceAPI';
import useSignupSource from '../../schema/useSignupSource';
import usePetBreedsAPI from '../../widgetsAPIData/usePetBreedsAPI';
import { extractQueryParams } from '../../../utils/URLHelper';
import { SIGN_UP_SOURCES_GOOGLE_TRACKING_PETOLO } from '../../../constants/Constants';
import {
  getPetContractDataForMultipleContracts,
  getPetContractDataForSingleContract,
} from './petoloGoogleTagManager.helper';

/*
  - Because of multiple contracts we've planned to restructure the GTM event payload.
  - We moved contract data such as policy category, addons, price and pet details inside `contractData` property. It will be an array of objects, even for single contract.
  - To make it backward compatible, no change in the current payload structure has been made. For this reason for single contract, there will be redundant `values` in event root for now.
*/

const usePetoloGoogleTagManager = (page) => {
  // The event will be sent when this is set to true
  const [triggerSendEvent, setTriggerSendEvent] = useState(false);

  const pageId = page?.pageId;
  const surveyId = useSurveyId();
  const getGduid = useGduid();
  const signupSource = useSignupSource();
  const isMultipleContracts = useMultipleContracts();

  const policyCategory = usePolicyCategory();

  const [selectedAddonsDetails] = usePolicyAddonsAPI();
  const policyAddons = selectedAddonsDetails.map((addon) => addon?.key).join(',');

  const petTypeLookup = isMultipleContracts ? 'contracts[0].insured_pet' : '';
  const [petBreedsList, petBreedsLoading] = usePetBreedsAPI(petTypeLookup);

  const getPolicyPriceDetails = usePolicyPriceAPI();
  const getMultiplePriceDetails = useMultiplePolicyPriceAPI();

  const getPolicyPriceDetailsFn = isMultipleContracts
    ? getMultiplePriceDetails
    : getPolicyPriceDetails;

  const [policyPriceDetails, policyPriceDetailsLoading] = getPolicyPriceDetailsFn();

  const pages = useSelector((state) => state?.schema?.activeSchema?.SCHEMA?.pages);
  const currentPageNumber = Array.isArray(pages) ? pages.findIndex((p) => p.pageId === pageId) : 0;
  const totalPages = Array.isArray(pages) ? pages.length : 0;

  const [getFromWindow, assignToWindow] = useWindow();
  const windowLocation = getFromWindow('location');
  const pageUrl = `${windowLocation?.origin}${windowLocation?.pathname}`;
  const navigator = getFromWindow('navigator');
  const userAgent = navigator?.userAgent;

  const leadData = useSelector((state) => state?.lead?.leadData || {});

  const dateOfBirth = useSelector((state) => state?.lead?.leadData?.customer?.date_of_birth);
  const birthYear = dateOfBirth ? DateTime.fromISO(dateOfBirth).get('year') : null;

  const postcode = useSelector((state) => state?.lead?.leadData?.customer?.postcode) || null;

  const gender = useSelector((state) => state?.lead?.leadData?.customer?.gender) || null;

  // list of questions and answers
  const questions = useSelector((state) => state?.questions?.answers);
  // current question and answer
  const questionAnswer = useMemo(() => questions[`${pageId}`] || {}, [pageId, questions]);

  const gtmContractData = useMemo(
    () =>
      isMultipleContracts
        ? getPetContractDataForMultipleContracts(
            leadData,
            policyAddons,
            policyPriceDetails,
            petBreedsList
          )
        : getPetContractDataForSingleContract(
            leadData,
            policyCategory,
            policyAddons,
            policyPriceDetails,
            petBreedsList
          ),
    [isMultipleContracts, leadData, petBreedsList, policyAddons, policyCategory, policyPriceDetails]
  );

  const location = useLocation();
  const {
    source,
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
    utm_term: utmTerm,
    utm_content: utmContent,
  } = extractQueryParams(location);

  // TODO: remove this from `event` because this data also exists in `contractData`.
  // single pet data for backward compatibility
  const singlePetData = useMemo(
    () => (!isMultipleContracts ? gtmContractData[0] : {}),
    [gtmContractData, isMultipleContracts]
  );

  const backwardCompatibleData = useMemo(
    () => ({
      contract_start_date: singlePetData?.contract_start_date || null,
      policy_category: singlePetData?.policy_category || policyCategory,
      policy_addons: singlePetData?.policy_addons || '',
      gender_pet: singlePetData?.gender_pet || null,
      birthYear_pet: singlePetData?.birthYear_pet || null,
      pet_type: singlePetData?.pet_type || null,
      pet_breed_id: singlePetData?.pet_breed_id || null,
      pet_breed: singlePetData?.pet_breed || null,
      pet_breed_category: singlePetData?.pet_breed_category || null,
      priceStandard: singlePetData?.priceStandard || 0,
      priceStandardYearly: singlePetData?.priceStandardYearly || 0,
      price: singlePetData?.price || 0,
      priceYearly: singlePetData?.priceYearly || 0,
      priceExtra: singlePetData?.priceExtra || 0,
    }),
    [policyCategory, singlePetData]
  );

  const event = useMemo(
    () => ({
      event: 'step_update',
      event_name: pageId,
      created_at: DateTime.local().toISO(),
      survey_id: surveyId,
      total_pages: totalPages,
      current_page: currentPageNumber + 1,
      page_url: pageUrl,
      source: source || null,
      utm_source: utmSource || null,
      utm_medium: utmMedium || null,
      utm_campaign: utmCampaign || null,
      utm_term: utmTerm || null,
      utm_content: utmContent || null,
      gduid: getGduid(),
      userAgent,
      birthyear: birthYear,
      postcode,
      gender,
      contractData: gtmContractData,
      ...backwardCompatibleData,
      question_answer_list: questions,
      question_answer: questionAnswer,
    }),
    [
      pageId,
      surveyId,
      totalPages,
      currentPageNumber,
      pageUrl,
      source,
      utmSource,
      utmMedium,
      utmCampaign,
      utmTerm,
      utmContent,
      getGduid,
      userAgent,
      birthYear,
      postcode,
      gender,
      gtmContractData,
      backwardCompatibleData,
      questions,
      questionAnswer,
    ]
  );

  useEffect(() => {
    if (triggerSendEvent && !petBreedsLoading && !policyPriceDetailsLoading) {
      const getDataLayer = () => {
        if (!Array.isArray(getFromWindow('dataLayer'))) {
          assignToWindow('dataLayer', []);
        }
        return getFromWindow('dataLayer');
      };

      if (SIGN_UP_SOURCES_GOOGLE_TRACKING_PETOLO.includes(signupSource)) {
        getDataLayer().push(event);
      }
      setTriggerSendEvent(false);
    }
  }, [
    triggerSendEvent,
    getFromWindow,
    assignToWindow,
    signupSource,
    event,
    petBreedsLoading,
    policyPriceDetailsLoading,
  ]);

  const trackGTMEvents = () => {
    setTriggerSendEvent(true);
    return Promise.resolve(null);
  };

  return trackGTMEvents;
};

export default usePetoloGoogleTagManager;
