import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import DEPRECATEDTitleAndDescription from './DEPRECATEDTitleAndDescription';
import WidgetsManager from '../widgetsManager/WidgetsManager';
import usePageNavigation from './usePageNavigation';
import { promiseWithRetry } from '../../utils/GeneralHelper';
import { MODAL_FORM_PORTAL } from '../../constants/Constants';
import { StyledPageInnerContainer } from './Page.styled';

const DEPRECATEDBackButton = React.lazy(() =>
  promiseWithRetry(() => import('../widgets/BackButton'))
);
const DEPRECATEDProgressBar = React.lazy(() =>
  promiseWithRetry(() => import('./DEPRECATEDProgressBar'))
);

function Page({ pages, currentPage, thumbImage, setCurrentPage }) {
  const pageFormRef = useRef(null);
  const modalFormRef = useRef(null);

  // Enhance page navigation with error validation, tracking, lead and customer creation
  const [
    startShowingErrorsOnPage,
    goToNextPage,
    goToExternalURL,
    goBackToPreviousPage,
    goBackToExternalURL,
  ] = usePageNavigation(currentPage, setCurrentPage);

  const progressBar = useSelector((state) => state?.schema?.activeSchema?.SCHEMA?.progress_bar);
  const progressBarBelowContent = useSelector(
    (state) => state?.schema?.activeSchema?.SCHEMA?.progress_bar_below_content
  );
  const progressBarHasNavigation = useSelector(
    (state) => state?.schema?.activeSchema?.SCHEMA?.progress_bar_has_navigation
  );

  const { backButton, backButtonStep, backButtonUrl, backButtonVariant, pageId } =
    currentPage || {};

  const backButtonWidgetData = {
    title: backButton,
    backButtonStep,
    backButtonUrl,
    backButtonVariant,
  };

  return (
    <>
      <StyledPageInnerContainer>
        {!!progressBar && !progressBarBelowContent && (
          <DEPRECATEDProgressBar
            variant={progressBar}
            pages={pages}
            currentPage={currentPage}
            hasNavigation={progressBarHasNavigation}
            goBackToPreviousPage={goBackToPreviousPage}
          />
        )}

        {!!backButton && (
          <DEPRECATEDBackButton
            widgetData={backButtonWidgetData}
            goBackToPreviousPage={goBackToPreviousPage}
            goBackToExternalURL={goBackToExternalURL}
          />
        )}

        <form ref={pageFormRef} data-page-id={pageId} onSubmit={(e) => e.preventDefault()}>
          <DEPRECATEDTitleAndDescription page={currentPage} thumbImage={thumbImage} />

          <WidgetsManager
            uiSchema={currentPage?.uiSchema}
            dataSchema={currentPage?.dataSchema}
            pageId={currentPage?.pageId}
            pageDescription={currentPage?.description}
            setCurrentPage={goToNextPage}
            goToExternalURL={goToExternalURL}
            goBackToExternalURL={goBackToExternalURL}
            goBackToPreviousPage={goBackToPreviousPage}
            startShowingErrorsOnPage={startShowingErrorsOnPage}
            pageFormRef={pageFormRef}
            modalFormRef={modalFormRef}
          />
        </form>

        {!!progressBar && !!progressBarBelowContent && (
          <DEPRECATEDProgressBar
            variant={progressBar}
            pages={pages}
            currentPage={currentPage}
            hasNavigation={progressBarHasNavigation}
            goBackToPreviousPage={goBackToPreviousPage}
          />
        )}
      </StyledPageInnerContainer>

      <form ref={modalFormRef} onSubmit={(e) => e.preventDefault()}>
        <div id={MODAL_FORM_PORTAL}>
          {/* This is a portal to render modals present in the review page */}
          {/* There cannot be a form within a form, so the modal form has to be outside the main form */}
        </div>
      </form>
    </>
  );
}

export default Page;
