import styled from 'styled-components';
import { applyTheme, MIN_WIDTH_LG } from '../theme/Theme';

import { THEME } from '../../constants/Constants';

const padding = applyTheme({
  [THEME.DENTOLO_THEME]: '0',
  [THEME.PETOLO_THEME]: '0',
  [THEME.VITOLO_THEME]: '0',
  [THEME.DA_DIREKT_THEME]: '0 1em 0',
  [THEME.DENTOLO_LEFT_ALIGN_THEME]: '0 1rem 1rem',
});

const paddingDesktop = applyTheme({
  [THEME.DENTOLO_THEME]: '0',
  [THEME.PETOLO_THEME]: '0',
  [THEME.VITOLO_THEME]: '0',
  [THEME.DA_DIREKT_THEME]: '0',
  [THEME.DENTOLO_LEFT_ALIGN_THEME]: '0 4rem 0',
});

/* eslint-disable import/prefer-default-export */
export const StyledPageInnerContainer = styled.div`
  flex-grow: 1;
  padding: ${padding};

  @media only screen and (min-width: ${MIN_WIDTH_LG}) {
    padding: ${paddingDesktop};
  }
`;
/* eslint-enable import/prefer-default-export */
