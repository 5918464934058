import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';
import useWindow from '../../../hooks/useWindow';
import useSurveyId from '../../schema/useSurveyId';
import usePolicyCategory from '../../policy/usePolicyCategory';
import usePolicyAddonsAPI from '../../widgetsAPIData/usePolicyAddonsAPI';
import usePolicyPriceAPI from '../../widgetsAPIData/usePolicyPriceAPI';
import useGduid from '../useGduid';
import { extractQueryParams } from '../../../utils/URLHelper';
import useSignupSource from '../../schema/useSignupSource';
import { SIGN_UP_SOURCES_WEB } from '../../../constants/Constants';

const useDentoloGoogleTagManager = (page) => {
  // The event will be sent when this is set to true
  const [triggerSendEvent, setTriggerSendEvent] = useState(false);

  const pageId = page?.pageId;
  const surveyId = useSurveyId();
  const getGduid = useGduid();
  const signupSource = useSignupSource();

  const pages = useSelector((state) => state?.schema?.activeSchema?.SCHEMA?.pages);
  const currentPageNumber = Array.isArray(pages) ? pages.findIndex((p) => p.pageId === pageId) : 0;
  const totalPages = Array.isArray(pages) ? pages.length : 0;

  const [getFromWindow, assignToWindow] = useWindow();
  const windowLocation = getFromWindow('location');
  const pageUrl = `${windowLocation?.origin}${windowLocation?.pathname}`;
  const navigator = getFromWindow('navigator');
  const userAgent = navigator?.userAgent;

  const dateOfBirth = useSelector((state) => state?.lead?.leadData?.customer?.date_of_birth);
  const birthYear = dateOfBirth ? DateTime.fromISO(dateOfBirth).get('year') : null;

  const postcode = useSelector((state) => state?.lead?.leadData?.customer?.postcode) || null;

  const gender = useSelector((state) => state?.lead?.leadData?.customer?.gender) || null;

  const policyCategory = usePolicyCategory();

  const [selectedAddonsDetails] = usePolicyAddonsAPI();
  const policyAddons = selectedAddonsDetails.map((addon) => addon?.key).join(',');

  const startingAt = useSelector((state) => state?.lead?.leadData?.contract?.starting_at) || null;

  const getPolicyPriceDetails = usePolicyPriceAPI();
  const [policyPriceDetails, policyPriceDetailsLoading] = getPolicyPriceDetails();
  const policyPrice = policyPriceDetails?.policy_price || 0;
  const policyPriceYearly = policyPrice ? (policyPrice * 1200) / 100 : 0; // Round off to two decimal places
  const policyAddonsPrice = policyPriceDetails?.addon_price || 0;
  const policyPriceWithAddons = policyPrice + policyAddonsPrice || 0;
  const policyPriceWithAddonsYearly = policyPriceWithAddons
    ? (policyPriceWithAddons * 1200) / 100
    : 0; // Round off to two decimal places

  // list of questions and answers
  const questions = useSelector((state) => state?.questions?.answers);
  // current question and answer
  const questionAnswer = useMemo(() => questions[`${pageId}`] || {}, [pageId, questions]);

  const location = useLocation();
  const {
    source,
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
    utm_term: utmTerm,
    utm_content: utmContent,
  } = extractQueryParams(location);

  const event = useMemo(
    () => ({
      event: 'step_update',
      event_name: pageId,
      created_at: DateTime.local().toISO(),
      survey_id: surveyId,
      total_pages: totalPages,
      current_page: currentPageNumber + 1,
      page_url: pageUrl,
      source: source || null,
      utm_source: utmSource || null,
      utm_medium: utmMedium || null,
      utm_campaign: utmCampaign || null,
      utm_term: utmTerm || null,
      utm_content: utmContent || null,
      gduid: getGduid(),
      userAgent,
      birthyear: birthYear,
      postcode,
      gender,
      policy_category: policyCategory,
      policy_addons: policyAddons,
      contract_start_date: startingAt,
      priceStandard: policyPrice,
      priceStandardYearly: policyPriceYearly,
      price: policyPriceWithAddons,
      priceYearly: policyPriceWithAddonsYearly,
      priceExtra: policyAddonsPrice,
      question_answer_list: questions,
      question_answer: questionAnswer,
    }),
    [
      pageId,
      surveyId,
      totalPages,
      currentPageNumber,
      pageUrl,
      source,
      utmSource,
      utmMedium,
      utmCampaign,
      utmTerm,
      utmContent,
      getGduid,
      userAgent,
      birthYear,
      postcode,
      gender,
      policyCategory,
      policyAddons,
      startingAt,
      policyPrice,
      policyPriceYearly,
      policyPriceWithAddons,
      policyPriceWithAddonsYearly,
      policyAddonsPrice,
      questions,
      questionAnswer,
    ]
  );

  useEffect(() => {
    if (triggerSendEvent && !policyPriceDetailsLoading) {
      const getDataLayer = () => {
        if (!Array.isArray(getFromWindow('dataLayer'))) {
          assignToWindow('dataLayer', []);
        }
        return getFromWindow('dataLayer');
      };

      if (SIGN_UP_SOURCES_WEB.includes(signupSource)) {
        getDataLayer().push(event);
      }
      setTriggerSendEvent(false);
    }
  }, [
    triggerSendEvent,
    getFromWindow,
    assignToWindow,
    signupSource,
    event,
    policyPriceDetailsLoading,
  ]);

  const trackGTMEvents = () => {
    setTriggerSendEvent(true);
    return Promise.resolve(null);
  };

  return trackGTMEvents;
};

export default useDentoloGoogleTagManager;
