import { useCallback, useEffect } from 'react';
import { datadogLogs } from '@datadog/browser-logs';

import useUuid from '../features/customer/useUuid';
import useSurveyId from '../features/schema/useSurveyId';
import { filterOptionsDetails } from '../utils/LoggerHelper/LoggerHelper';

export const useDataDog = () => {
  const notifyErrorToDataDog = useCallback((errorName, errorDetails) => {
    const additionalInfoInName = errorDetails?.url || errorDetails?.message || '';
    const nameToUse = `${errorName}${additionalInfoInName ? ` - ${additionalInfoInName}` : ''}`;

    const filteredOptions = filterOptionsDetails(errorDetails?.options);
    const updatedErrorDetails = {
      ...errorDetails,
      options: filteredOptions,
    };

    // log error to datadog
    datadogLogs.logger.error(nameToUse, { errorDetails: updatedErrorDetails });
  }, []);

  const setDataDogContext = useCallback((surveyId, pageId, uuid) => {
    datadogLogs.logger.setContext({
      survey_id: surveyId,
      page_id: pageId,
      uuid,
    });
  }, []);

  const resetDataDogContext = useCallback(() => {
    datadogLogs.logger.clearContext();
  }, []);

  return { notifyErrorToDataDog, setDataDogContext, resetDataDogContext };
};

export const useDataDogContext = (pageId) => {
  const { setDataDogContext } = useDataDog();

  const surveyId = useSurveyId();
  const uuid = useUuid();

  useEffect(() => {
    setDataDogContext(surveyId, pageId, uuid);
  }, [pageId, setDataDogContext, surveyId, uuid]);
};
