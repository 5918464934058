import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useWindow from '../../../hooks/useWindow';
import useUuid from '../../customer/useUuid';
import useFaircareBaseUrl from '../../schema/useFaircareBaseUrl';
import useWidgetsAPIData from '../useWidgetsAPIData';
import useInsuranceCategory from '../../insuranceCategory/useInsuranceCategory';

const usePetoloMultiplePolicyPriceAPI = () => {
  const [, , , /* getFromWindow */ /* assignToWindow */ /* openNewWindow */ postMessage] =
    useWindow();

  const contracts = useSelector((state) => state?.lead?.leadData?.contracts) || [];

  const paramsForApi = contracts.map((contract) => {
    const { insured_pet: insuredPet } = contract;
    return {
      name: insuredPet?.name,
      policy_category: contract?.policy_category,
      date_of_birth: insuredPet?.date_of_birth,
      starting_at: contract?.starting_at,
      breed_id: insuredPet?.breed_id,
    };
  });

  const stringifiedParamsForApi = paramsForApi?.length > 0 ? JSON.stringify(paramsForApi) : '';
  const encodedParamsForApi = encodeURIComponent(stringifiedParamsForApi);

  const uuid = useUuid();

  const insuranceCategory = useInsuranceCategory();

  const payload = {
    uuid,
    insurance_category: insuranceCategory,
    contracts: encodedParamsForApi || '',
  };
  const searchParams = new URLSearchParams(payload);

  const [baseUrl, defaultHeaders] = useFaircareBaseUrl();
  const url = `${baseUrl}/api/petolo/v1/price/multiple_contract_prices?${searchParams}`;

  const { data: fetchedAPIData, loading: policyPriceDetailsLoading } = useWidgetsAPIData(
    url,
    defaultHeaders
  );

  const policyPriceDetails = fetchedAPIData || null;
  const { policy_prices: policyPrices } = policyPriceDetails || {};

  if (policyPriceDetails) {
    // # Calculate the total price of all contracts
    policyPriceDetails.totalPrice = policyPriceDetails?.policy_prices?.reduce((acc, curr, arr) => {
      const { date_of_birth: dateOfBirth, breed_id: breedId } = policyPrices[`${arr}`] || {};

      // if data required to calculate the correct price is not available, don't add it to the total price
      return !!dateOfBirth && !!breedId ? acc + (curr?.policy_price || 0) : acc;
    }, 0);
  }

  useEffect(() => {
    postMessage({ event: 'multiple_policy_price', policyPriceDetails });
  }, [postMessage, policyPriceDetails]);

  return [policyPriceDetails, policyPriceDetailsLoading];
};

export default usePetoloMultiplePolicyPriceAPI;
