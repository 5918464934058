import { DENTOLO, PETOLO } from '../../insuranceType/InsuranceTypes';
import useInsuranceType from '../../insuranceType/useInsuranceType';
import useDentoloGoogleTagManager from './useDentoloGoogleTagManager';
import usePetoloGoogleTagManager from './usePetoloGoogleTagManager';

const useGoogleTagManager = (page) => {
  const insuranceType = useInsuranceType();

  const dentoloTagManager = useDentoloGoogleTagManager(page);
  const petoloTagManager = usePetoloGoogleTagManager(page);

  switch (insuranceType) {
    case DENTOLO:
      return dentoloTagManager;
    case PETOLO:
      return petoloTagManager;
    default:
      return dentoloTagManager;
  }
};

export default useGoogleTagManager;
