import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import useS2STrackingAPI from '../../../api/useS2STrackingAPI';
import { extractQueryParams } from '../../../utils/URLHelper';

const useMediagoIdentifier = () => {
  const location = useLocation();
  const [storeAdServiceIdentifierAPI] = useS2STrackingAPI();

  const storeMediagoIdentifier = useCallback(
    (uuid, email) => {
      const { mgoid = null } = extractQueryParams(location);
      if (mgoid) {
        const data = { mgoid };
        storeAdServiceIdentifierAPI(uuid, email, data);
      }
    },
    [location, storeAdServiceIdentifierAPI]
  );

  return storeMediagoIdentifier;
};

export default useMediagoIdentifier;
