import { SIGN_UP_SOURCE_TYRION, INSURANCE_FOR_ME } from './Constants';

export const DEFAULT_POLICY_CATEGORY = 6;
export const DEFAULT_SIGN_UP_SOURCE = SIGN_UP_SOURCE_TYRION;
export const DEFAULT_INSURANCE_FOR = INSURANCE_FOR_ME;
export const DEFAULT_NUMBER_OF_MONTHS_FOR_STARTING_DATE = 3;

export const SCHEMA_LOADING_TEXT = 'text.schema_loading';

export const DEFAULT_REFERRAL_EMAIL_SUBJECT = 'referral_email.subject';

export const DEFAULT_REVIEW_MODAL_TITLE = 'review_modal.title';

// Default Error messages
export const DEFAULT_VALIDATION_ERROR_MESSAGE = 'error.default.validation.generic';
export const DEFAULT_VALIDATION_ERROR_MESSAGE_DATE = 'error.default.validation.date';
export const DEFAULT_VALIDATION_ERROR_MESSAGE_INPUT = 'error.default.validation.input';
export const DEFAULT_VALIDATION_ERROR_MESSAGE_DUPLICATE_PET = 'error.duplicate_pet';

// Multiple Pets
export const DEFAULT_MULTIPLE_PETS_SECTION_TITLE = 'multiple_pets.default.section.details.title';
export const DEFAULT_BUTTON_LABEL_ADD_PET = 'multiple_pets.default.label.button.add_pet';
export const DEFAULT_BUTTON_LABEL_REMOVE_PET = 'multiple_pets.default.label.button.remove_pet';
export const DEFAULT_LABEL_INDIVIDUAL_PRICE = 'multiple_pets.default.label.individual_price';
export const DEFAULT_REVIEW_MULTIPLE_PETS_DETAIILS_SECTION_TITLE =
  'multiple_pets.default.section.review.title';

export const DEFAULT_DELETE_PET_CONFIRMATION_MESSAGE =
  'multiple_pets.default.delete_confirmation.message';
export const DEFAULT_DELETE_PET_CONFIRMATION_BUTTON_YES =
  'multiple_pets.default.label.button.delete_pet.yes';
export const DEFAULT_DELETE_PET_CONFIRMATION_BUTTON_NO =
  'multiple_pets.default.label.button.delete_pet.no';

export const DEFAULT_DIGITAL_PAYMENT_FETCHING_LINK_MESSAGE =
  'digital_payment.default.fetching_link_message';
