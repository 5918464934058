import styled from 'styled-components';

import { applyTheme, MIN_WIDTH_LG, palette } from '../../theme/Theme';
import { THEME } from '../../../constants/Constants';
import { fontH1, fontP } from '../../theme/typography';

const marginTopBottomMobile = ({ hasContent, marginTopBottomMobile: margin }) => {
  if (hasContent) {
    if (Number.isFinite(margin)) {
      return `${margin}em`;
    }
    return '1em';
  }
  return '0';
};

const marginTopBottomDesktop = ({ hasContent, marginTopBottomDesktop: margin }) => {
  if (hasContent) {
    if (Number.isFinite(margin)) {
      return `${margin}em`;
    }
    return '3em';
  }
  return '0';
};

export const StyledPageHeaderContainer = styled.div`
  margin: ${marginTopBottomMobile} 0;

  @media only screen and (min-width: ${MIN_WIDTH_LG}) {
    margin: ${marginTopBottomDesktop} 0;
  }
`;

export const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
  margin-top: 0.5em;
`;

export const StyledImage = styled.img`
  width: 5em;
  height: 5em;
  border-radius: 50%;
  box-shadow: ${palette.box_shadow_1};
`;

export const StyledTitleAndDescriptionContainer = styled.div`
  height: ${(props) => (props?.contentHeight > 0 ? `${props.contentHeight}px` : 'auto')};
`;

const alignment = applyTheme({
  [THEME.DENTOLO_THEME]: 'center',
  [THEME.PETOLO_THEME]: 'center',
  [THEME.DA_DIREKT_THEME]: 'left',
  [THEME.DENTOLO_LEFT_ALIGN_THEME]: 'left',
  [THEME.VITOLO_THEME]: 'center',
});

const titleFontColor = applyTheme({
  [THEME.DENTOLO_THEME]: palette.professional_blue,
  [THEME.PETOLO_THEME]: palette.professional_blue,
  [THEME.DA_DIREKT_THEME]: palette.professional_blue,
  [THEME.DENTOLO_LEFT_ALIGN_THEME]: palette.professional_blue,
  [THEME.FRESSNAPF_THEME]: palette.color_forest_green,
  [THEME.VITOLO_THEME]: palette.professional_blue,
});

const titleBackgroundColor = applyTheme({
  [THEME.DENTOLO_THEME]: palette.vitalized_green,
  [THEME.PETOLO_THEME]: palette.vitalized_orange,
  [THEME.VITOLO_THEME]: palette.vibrant_light_violet,
  [THEME.DA_DIREKT_THEME]: 'transparent',
  [THEME.DENTOLO_LEFT_ALIGN_THEME]: 'transparent',
  [THEME.FRESSNAPF_THEME]: 'transparent',
});

export const StyledTitle = styled.div`
  margin: 0 0 1em;
  word-break: break-word;
  ${fontH1}
  color: ${titleFontColor};
  text-align: ${alignment};

  em {
    text-decoration: none;
    font-style: normal;
    background-color: ${titleBackgroundColor};
  }
`;

const descriptionColor = applyTheme({
  [THEME.DENTOLO_THEME]: palette.professional_light_blue,
  [THEME.PETOLO_THEME]: palette.professional_light_blue,
  [THEME.VITOLO_THEME]: palette.professional_light_blue,
  [THEME.DA_DIREKT_THEME]: palette.professional_blue,
  [THEME.FRESSNAPF_THEME]: palette.color_greyish_black,
});

export const StyledDescription = styled.div`
  padding: 0;
  margin: 0;
  ${fontP};
  color: ${descriptionColor};
  text-align: ${alignment};
  word-break: break-word;
`;
