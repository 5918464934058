// adding datadog initialization to the top
import './configs/datadog.config';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { StyleSheetManager } from 'styled-components';
import { datadogLogs } from '@datadog/browser-logs';

import store from './app/store';
import { NODE_ENV, USE_SHADOW_DOM } from './app/environment';
import { ROOT_ELEMENT_ID } from './constants/Constants';
import { getLocale } from './locales/locale.helper';
import { initializeShadowDOM, polyfillReactIntlWithErrorReporting } from './app/utils';

import App from './app/App';
import ErrorBoundary from './components/ErrorBoundary';
import GetoloInternationalisation from './components/GetoloInternationalisation';

if (NODE_ENV === 'development') {
  window.store = store;
}

const locale = getLocale();

async function initializeApp() {
  // Polyfill Intl for older browsers
  await polyfillReactIntlWithErrorReporting(locale, datadogLogs);

  const rootElement = document.getElementById(ROOT_ELEMENT_ID);

  if (!rootElement) {
    datadogLogs.logger.error(`Root element with ID ${ROOT_ELEMENT_ID} not found`);
    return;
  }

  const appShadowRoot = USE_SHADOW_DOM === 'true' ? initializeShadowDOM(rootElement) : null;
  const appContainer = createRoot(appShadowRoot || rootElement);

  appContainer.render(
    <ErrorBoundary datadog={datadogLogs}>
      <GetoloInternationalisation locale={locale}>
        <Provider store={store}>
          <StyleSheetManager target={appShadowRoot}>
            <App />
          </StyleSheetManager>
        </Provider>
      </GetoloInternationalisation>
    </ErrorBoundary>
  );
}

initializeApp();
