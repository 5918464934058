import { createQueryParamsString, extractQueryParams } from '../URLHelper/URLHelper';

// # Webhooks/Actions
const KEY_SETUP_DIGITAL_PAYMENT_ACTION = 'setup_digital_payment_method';

const keysToUpdate = [KEY_SETUP_DIGITAL_PAYMENT_ACTION];
const necessaryParamKeys = [
  'flow',
  'product',
  'organisation',
  'country',
  'theme',
  'policy_category',
  'policy_addons',
  'uuid',
  'refid',
  'skip_customer',
  'gduid',
  'ex_agency_id',
  'sign_up_source',
  'voucher_code',
];

// # remove unnecessary URL params
const cleanLocationWithoutStep = (locationObj) => {
  if (!locationObj) return '';

  const queryParams = extractQueryParams(locationObj);

  const cleanedQueryParams = Object.keys(queryParams)
    .filter((key) => necessaryParamKeys.includes(key))
    .reduce(
      (acc, key) => ({
        ...acc,
        [`${key}`]: queryParams[`${key}`],
      }),
      {}
    );

  return createQueryParamsString(cleanedQueryParams);
};

export const updatePayloadForSetUpdigitalDigitalPaymentAction = (payload) => {
  if (!payload || Object.keys(payload) < 1) return null;

  const { successStep, successExternalUrl, failureStep, failureExternalUrl } = payload || {};

  const location = window?.location;
  const cleanedQueryString = cleanLocationWithoutStep(location);
  const cleanedLocationWithoutStep = `${location?.origin}${location?.pathname}${cleanedQueryString}`;

  const newPayload = {};

  if (successStep || successExternalUrl) {
    newPayload.successUrl = successStep
      ? `${cleanedLocationWithoutStep}#${successStep}`
      : successExternalUrl;
  }

  if (failureStep || failureExternalUrl) {
    newPayload.failureUrl = failureStep
      ? `${cleanedLocationWithoutStep}#${failureStep}`
      : failureExternalUrl;
  }

  return Object.keys(newPayload).length > 0 ? newPayload : null;
};

export const updateWebhookPayload = (payload, organisation) => {
  if (!payload || Object.keys(payload) < 1) return null;

  // # filter items that does not need to be updated
  const noUpdatePayload = Object.keys(payload)
    .filter((key) => !keysToUpdate.includes(key))
    .reduce(
      (obj, key) => ({
        ...obj,
        [`${key}`]: payload[`${key}`],
      }),
      {}
    );

  // # update payload for setup digital payments action
  const newPayloadForSetupDigitalPaymentAction = updatePayloadForSetUpdigitalDigitalPaymentAction(
    payload?.[`${KEY_SETUP_DIGITAL_PAYMENT_ACTION}`]
  );

  return {
    ...noUpdatePayload,
    ...(newPayloadForSetupDigitalPaymentAction && {
      [KEY_SETUP_DIGITAL_PAYMENT_ACTION]: {
        ...newPayloadForSetupDigitalPaymentAction,
        organisation,
      },
    }),
  };
};
