import { DENTOLO, PETOLO } from '../../insuranceType/InsuranceTypes';
import useInsuranceType from '../../insuranceType/useInsuranceType';
import useDentoloFaircareBaseUrl from './useDentoloFaircareBaseUrl';
import usePetoloFaircareBaseUrl from './usePetoloFaircareBaseUrl';

const useFaircareBaseUrl = () => {
  const insuranceType = useInsuranceType();

  const dentoloFaircareBaseUrl = useDentoloFaircareBaseUrl();
  const petoloFaircareBaseUrl = usePetoloFaircareBaseUrl();

  switch (insuranceType) {
    case DENTOLO:
      return dentoloFaircareBaseUrl;
    case PETOLO:
      return petoloFaircareBaseUrl;
    default:
      return dentoloFaircareBaseUrl;
  }
};

export default useFaircareBaseUrl;
