import React from 'react';
import { IntlProvider } from 'react-intl';

import { DEFAULT_LOCALE } from '../../locales/locale.helper';
import messages from '../../locales/messages';

function GetoloInternationalisation(props) {
  const { children, locale } = props;

  // locale will have value from the URL or the default locale
  const localeMessages = messages[`${locale}`] || {};

  return (
    <IntlProvider defaultLocale={DEFAULT_LOCALE} locale={locale} messages={localeMessages}>
      {children}
    </IntlProvider>
  );
}

export default GetoloInternationalisation;
