export const processWebhooks = (webhooks, aggregatedResult) => {
  if (Array.isArray(webhooks) && webhooks.length > 0) {
    const processedWebhooks = webhooks.reduce((agg, next) => {
      const leadActions = Array.isArray(agg?.leadActions) ? agg.leadActions : [];
      const webhookIds = agg?.webhookIds ? agg.webhookIds : {};
      const actionPayloads = agg?.payloads ? agg.payloads : {};

      const { name: webhookName, webhook_id: webhookId, payload } = next || {};

      if (webhookName) {
        const isDuplicate = leadActions.includes(webhookName);

        const newLeadActions = isDuplicate ? leadActions : [...leadActions, webhookName];
        const newWebhookIds = { ...webhookIds, [webhookName]: webhookId };
        const newActionPayloads = { ...actionPayloads, [webhookName]: payload };

        return {
          ...agg,
          leadActions: newLeadActions,
          webhookIds: newWebhookIds,
          actionPayloads: newActionPayloads,
        };
      }

      return agg;
    }, aggregatedResult);

    return processedWebhooks;
  }
  return aggregatedResult;
};

export const processEmailTriggers = (emailTriggers, aggregatedResult) => {
  if (Array.isArray(emailTriggers)) {
    const leadActions = Array.isArray(aggregatedResult?.leadActions)
      ? aggregatedResult.leadActions
      : [];
    const newLeadActions = Array.from(new Set([...leadActions, ...emailTriggers]));
    return { ...aggregatedResult, leadActions: newLeadActions };
  }
  return aggregatedResult;
};

export const processWebhooksAndEmailTriggers = (webhooks, emailTriggers) => {
  const initialValue = { leadActions: [], webhookIds: {}, actionPayloads: {} };

  const processedWebhooks = processWebhooks(webhooks, initialValue);

  const processedWebhooksAndEmailTriggers = processEmailTriggers(emailTriggers, processedWebhooks);

  return processedWebhooksAndEmailTriggers;
};
