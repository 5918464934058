import useCustomEventsTracking from './useCustomEventsTracking';
import useFacebookTracking from './useFacebookTracking';
import useGoogleAnalyticsTracker from './useGoogleAnalyticsTracker';
import useGoogleTagManager from './useGoogleTagManager';
import useSubmitEvents from './useSubmitEvents';
import useSchemaTracking from './useSchemaTracking';
import useDaDirektTracking from './useDaDirektTracking';

const usePageTracking = (page) => {
  const daDirektEventName = page?.daDirektTrackingEventName;

  const trackCustomEvents = useCustomEventsTracking(page);
  const triggerSubmitEvents = useSubmitEvents(page);
  const trackFacebookEvents = useFacebookTracking(page);
  const trackGTMEvents = useGoogleTagManager(page);
  const trackGAEvents = useGoogleAnalyticsTracker(page);
  const trackPageForSchema = useSchemaTracking(page);
  const trackDaDirektEvents = useDaDirektTracking(page);

  const sendTrackingData = () => {
    // Ensure that each of these Promises never gets rejected
    return Promise.all([
      trackCustomEvents(),
      triggerSubmitEvents(),
      trackFacebookEvents(),
      trackGTMEvents(),
      trackGAEvents(),
      trackPageForSchema(),
      trackDaDirektEvents(daDirektEventName),
    ]);
  };

  return sendTrackingData;
};

export default usePageTracking;
