import useFaircareBaseUrl from '../schema/useFaircareBaseUrl';
import useWidgetsAPIData from './useWidgetsAPIData';
import usePolicyCategory from '../policy/usePolicyCategory';
import usePolicyAddons from '../policy/usePolicyAddons';
import useParameters from '../schema/useParameters';
import { getInsuranceCategoryFromProductName } from '../../utils/ProductHelper';

const usePolicyAddonsAPI = (run) => {
  const { product } = useParameters();
  const insuranceCategory = getInsuranceCategoryFromProductName(product);

  const policyCategory = usePolicyCategory();
  const selectedAddons = usePolicyAddons();

  const shouldCallAPI = !!run || selectedAddons.length > 0;

  const [baseUrl, defaultHeaders] = useFaircareBaseUrl();
  const url = shouldCallAPI
    ? `${baseUrl}/api/v1/addons?policy_category=${policyCategory}&insurance_category=${insuranceCategory}`
    : '';

  const { data: fetchedAPIData, loading: policyAddonsLoading } = useWidgetsAPIData(
    url,
    defaultHeaders
  );

  const availableAddons = Array.isArray(fetchedAPIData?.available_addons)
    ? fetchedAPIData.available_addons
    : [];
  const selectedAddonsDetails = availableAddons.filter((addon) =>
    selectedAddons.includes(addon?.key)
  );

  return [selectedAddonsDetails, availableAddons, policyAddonsLoading];
};

export default usePolicyAddonsAPI;
