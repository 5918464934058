import { THEME } from '../constants/Constants';

const de = {
  [`app_outage.message.${THEME.DENTOLO_THEME}`]:
    'Unsere Seite ist aktuell nicht verfügbar, bitte probieren Sie es in Kürze nochmal.',
  [`app_outage.message.${THEME.PETOLO_THEME}`]:
    'Unsere Seite ist aktuell nicht verfügbar, bitte probieren Sie es in Kürze nochmal.',
  [`app_outage.message.${THEME.VITOLO_THEME}`]:
    'Unsere Seite ist aktuell nicht verfügbar, bitte probieren Sie es in Kürze nochmal.',
  [`app_outage.message.${THEME.DA_DIREKT_THEME}`]:
    'Unsere Seite ist aktuell nicht verfügbar, bitte probieren Sie es in Kürze nochmal.',
  [`app_outage.message.${THEME.FRESSNAPF_THEME}`]:
    'Unsere Seite ist aktuell nicht verfügbar, bitte probieren Sie es in Kürze nochmal.',

  'billing_day.title': 'Abbuchung des Beitrags',
  'billing_day.first_of_month': 'Zum 1. des Monats',
  'billing_day.fifteenth_of_month': 'Zum 15. des Monats',

  'digital_payment.default.fetching_link_message': 'Bitte warten',

  'error.default.validation.generic': 'Es liegt ein Fehler vor',
  'error.default.validation.date': 'Bitte ein gültiges Datum angeben',
  'error.default.validation.input': 'Bitte einen gültigen Wert angeben',
  'error.duplicate_pet': 'Details zum Haustier duplizieren',
  'error.iban.invalid': 'Ungültiges IBAN-Format. Bitte überprüfen',
  'error.iban.non_sepa':
    'Diese IBAN ist nicht gültig. Bitte geben Sie eine IBAN der offiziellen SEPA Länder an, um fortzufahren.',

  'month.january': 'Januar',
  'month.february': 'Februar',
  'month.march': 'März',
  'month.april': 'April',
  'month.may': 'Mai',
  'month.june': 'Juni',
  'month.july': 'Juli',
  'month.august': 'August',
  'month.september': 'September',
  'month.october': 'Oktober',
  'month.november': 'November',
  'month.december': 'Dezember',

  'multiple_pets.default.delete_confirmation.message':
    'Wollen Sie dieses Haustier wirklich entfernen?',
  'multiple_pets.default.label.button.add_pet': 'Weiteren Haustier versichern',
  'multiple_pets.default.label.button.remove_pet': 'Diesen Haustier entfernen',
  'multiple_pets.default.label.button.delete_pet.yes': 'Ja',
  'multiple_pets.default.label.button.delete_pet.no': 'Nein',
  'multiple_pets.default.label.individual_price': 'Preis für {{contracts.insured_pet.name}}',
  'multiple_pets.default.section.details.title': 'Ihr Haustier',
  'multiple_pets.default.section.review.title': '{{contracts.insured_pet.name}}s Angaben',

  'payment.link.use_iban': 'Ich möchte lieber meine IBAN verwenden.',
  'payment.link.use_bank_account': 'Ich möchte lieber meine Kontonummer und BLZ verwenden.',

  'pet.gender.male': 'Männlich',
  'pet.gender.female': 'Weiblich',
  'pet.type.dog': 'Hund',
  'pet.type.cat': 'Katze',

  'referral_email.subject': 'Dentolo Referral',

  'review_modal.title': 'Ihre Angaben ändern',

  'salutation.male': 'Herr',
  'salutation.female': 'Frau',

  'sepa_mandate.title': 'SEPA-Mandat',
  'sepa_mandate.text':
    'Ich erteile das *SEPA-Lastschriftmandat* zum Einzug der fälligen Beiträge von meinem Konto und bestätige, verfügungsberechtigter Kontoinhaber zu sein.',
  'sepa_mandate.info_01':
    'Ich ermächtige die DA Direkt Versicherung mit der Gläubiger-ID: DE81ZZZ00000023246, Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der DA Direkt Versicherung auf mein Konto gezogenen Lastschriften einzulösen.',
  'sepa_mandate.info_02':
    'Bitte beachten Sie: Die Frist für die Vorabinformation der SEPA-Lastschrift wird auf einen Tag verkürzt.',
  'sepa_mandate.info_03':
    'Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.',
  'text.schema_loading': 'Bitte warten Sie einen Moment',
};

export default de;
