import useWindow from '../../hooks/useWindow';
import { SUBMIT_EVENTS_TABOOLA } from '../../constants/Constants';

const useSubmitEventToTaboola = () => {
  const [getFromWindow, assignToWindow] = useWindow();

  const getTFA = () => {
    if (!Array.isArray(getFromWindow('_tfa'))) {
      assignToWindow('_tfa', []);
    }
    return getFromWindow('_tfa');
  };

  const submitEventToTaboola = (eventName) => {
    getTFA().push({ notify: 'action', name: eventName });
  };

  return submitEventToTaboola;
};

const useSubmitEvents = (page) => {
  const submitEventToTaboola = useSubmitEventToTaboola();

  const triggerSubmitEvents = () => {
    const events = page?.submitEvents;

    if (Array.isArray(events)) {
      events.forEach((event) => {
        const { trigger, event_name: eventName } = event;
        if (trigger === SUBMIT_EVENTS_TABOOLA) {
          submitEventToTaboola(eventName);
        }
      });
    }
    return Promise.resolve(null);
  };

  return triggerSubmitEvents;
};

export default useSubmitEvents;
