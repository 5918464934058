import { useCallback } from 'react';

const Console = console;

export default function useConsole() {
  const consoleError = useCallback((logObject) => {
    Console.error(logObject);
  }, []);

  const consoleLog = useCallback((logObject) => {
    Console.log(logObject);
  }, []);

  return [consoleError, consoleLog];
}
