export const POLICY_CATEGORIES_DENTOLO_DENTAL = [6, 7, 8, 13];
export const POLICY_CATEGORIES_VITOLO_HEALTH = [30, 31, 32, 36, 37, 38];
export const POLICY_CATEGORIES_PETOLO_PET_HEALTH = [14, 15, 16, 23, 24, 25];
export const POLICY_CATEGORIES_PETOLO_PET_LIABILITY = [26, 27];
export const POLICY_CATEGORIES_PATOLO_FR_PET_HEALTH = [42, 43, 44, 45, 46, 47];

// # Fressnapf
export const POLICY_CATEGORIES_FRESSNAPF_PET_HEALTH_EMPLOYEE = [28, 29];

// # DA-Direkt
export const POLICY_CATEGORIES_DA_DIREKT_DENTAL = [9, 10, 11, 12];
export const POLICY_CATEGORIES_DA_DIREKT_PET_HEALTH = [17, 18, 19, 20, 21, 22];
export const POLICY_CATEGORIES_DA_DIREKT_HEALTH = [33, 34, 35, 39, 40, 41];

// # Combined
export const POLICY_CATEGORIES_DENTAL = [
  ...POLICY_CATEGORIES_DENTOLO_DENTAL,
  ...POLICY_CATEGORIES_DA_DIREKT_DENTAL,
];

export const POLICY_CATEGORIES_HEALTH = [
  ...POLICY_CATEGORIES_VITOLO_HEALTH,
  ...POLICY_CATEGORIES_DA_DIREKT_HEALTH,
];

export const POLICY_CATEGORIES_PET_HEALTH = [
  ...POLICY_CATEGORIES_PETOLO_PET_HEALTH,
  ...POLICY_CATEGORIES_PATOLO_FR_PET_HEALTH,
  ...POLICY_CATEGORIES_FRESSNAPF_PET_HEALTH_EMPLOYEE,
  ...POLICY_CATEGORIES_DA_DIREKT_PET_HEALTH,
];

export const POLICY_CATEGORIES_PET_LIABILITY = [...POLICY_CATEGORIES_PETOLO_PET_LIABILITY];
