import useParameters from '../schema/useParameters';
import { INSURANCE_TYPES, DEFAULT_INSURANCE_TYPE } from './InsuranceTypes';

const useInsuranceType = () => {
  const { product } = useParameters();
  if (!!product && INSURANCE_TYPES.includes(product)) {
    return product;
  }
  return DEFAULT_INSURANCE_TYPE;
};

export default useInsuranceType;
