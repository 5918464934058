import { useCallback } from 'react';
import { useFetch } from '../../hooks/useFetch';
import useFaircareBaseUrl from '../../features/schema/useFaircareBaseUrl';

const augmentInsuredPersonInfoToLeadData = (leadData) => {
  if (leadData?.contract?.insured_person) {
    const newLeadData = { ...leadData, insured_person: leadData?.contract?.insured_person };
    return newLeadData;
  }
  return leadData;
};

const useDentoloLeadAPI = () => {
  const [executeFetchRequest, executeFetchRequestWithRetry] = useFetch();

  const [baseUrl, defaultHeaders] = useFaircareBaseUrl();

  const createLeadAPI = useCallback(
    (payload) => {
      const createLeadUrl = `${baseUrl}/api/v1/leads`;
      const body = JSON.stringify(payload);
      const options = {
        method: 'POST',
        headers: defaultHeaders,
        body,
      };
      return executeFetchRequestWithRetry(createLeadUrl, options).then((response) =>
        response?.lead ? augmentInsuredPersonInfoToLeadData(response.lead) : null
      );
    },
    [baseUrl, defaultHeaders, executeFetchRequestWithRetry]
  );

  const getLeadAPI = useCallback(
    (uuid) => {
      const getLeadUrl = `${baseUrl}/api/v1/leads/${uuid}`;
      const options = {
        headers: defaultHeaders,
      };
      return executeFetchRequest(getLeadUrl, options).then((response) =>
        response?.lead ? augmentInsuredPersonInfoToLeadData(response.lead) : null
      );
    },
    [baseUrl, defaultHeaders, executeFetchRequest]
  );

  const updateLeadAPI = useCallback(
    (leadData) => {
      const uuid = leadData?.uuid;

      if (uuid) {
        const updateLeadUrl = `${baseUrl}/api/v1/leads/${uuid}`;
        const body = JSON.stringify(leadData);

        const options = {
          method: 'PUT',
          headers: defaultHeaders,
          body,
        };
        return executeFetchRequest(updateLeadUrl, options).then((data) => {
          if (data?.status) {
            const newLeadData = augmentInsuredPersonInfoToLeadData(data?.lead);
            const newData = { ...data, lead: newLeadData };
            return newData;
          }
          return null;
        });
      }
      return Promise.resolve(null);
    },
    [baseUrl, defaultHeaders, executeFetchRequest]
  );

  return [createLeadAPI, getLeadAPI, updateLeadAPI];
};

export default useDentoloLeadAPI;
