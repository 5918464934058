import { useSelector } from 'react-redux';

import { resolvePathValue } from '../../../utils/GeneralHelper';
import { SALUTATION_GENDER_LIST, SALUTATIONS } from '../../../constants/Constants';

/**
 * Resolves additional dynamic value based on predefined rules.
 * @param {string|null} value - The value to resolve.
 * @returns {string|null} - Resolved value or the original value.
 */
const resolveOtherDynamicValues = (value) => {
  if (SALUTATION_GENDER_LIST.includes(value)) {
    return SALUTATIONS[`${value}`];
  }

  return value;
};

/**
 * Hook to process dynamic placeholders in text and replace them with values from leadData.
 * If no text is provided, it returns the original input (could be null).
 * @param {string|null} text - The input text containing placeholders (e.g., "{{key}}").
 * @returns {string|null} - The text with placeholders replaced by dynamic values, or null if input is null.
 */
const useDynamicText = (text) => {
  const leadData = useSelector((state) => state?.lead?.leadData) || {};

  if (!text) return text;

  let populatedText = text;

  if (text.match(/\{{.*?\}}/)) {
    while (populatedText.match(/\{{.*?\}}/) !== null) {
      const keysAndDefaultValueString = populatedText
        .match(/\{{.*?\}}/)[0]
        .replace('{{', '')
        .replace('}}', '');

      const keysAndDefaultValue = keysAndDefaultValueString
        ? keysAndDefaultValueString.split('/')
        : [];

      const keyString = keysAndDefaultValue.length > 0 ? keysAndDefaultValue[0] : '';
      const defaultValue = keysAndDefaultValue.length > 1 ? keysAndDefaultValue[1] : '';

      const keys = keyString ? keyString.split('.') : [];
      const sanitizedKeys = keys.length === 1 ? ['customer', ...keys] : keys;
      const sanitizedKeyString = sanitizedKeys.join('.');

      const value = resolvePathValue(sanitizedKeyString, leadData);
      const valueToUse = resolveOtherDynamicValues(value);

      populatedText = populatedText.replace(
        /\{{.*?\}}/,
        valueToUse ? ` ${valueToUse}` : ` ${defaultValue}`
      );
    }
  }

  return populatedText;
};

export default useDynamicText;
