import React from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';

import useWindow from '../hooks/useWindow';
import useSurveyId from '../features/schema/useSurveyId';
import useSignupSource from '../features/schema/useSignupSource';
import { SIGN_UP_SOURCES_WEB } from '../constants/Constants';

const APP_LOAD_EVENT_NAME = 'funnel_app_loaded';

function useAppLoad() {
  const surveyId = useSurveyId();
  const signupSource = useSignupSource();

  const daDirektSignUpSources = useSelector(
    (state) => state?.schema?.schemaConfig?.signUpSourcesDaDirekt
  );

  const [getFromWindow, assignToWindow] = useWindow();

  const windowLocation = getFromWindow('location');
  const pageUrl = `${windowLocation?.origin}${windowLocation?.pathname}`;

  const navigator = getFromWindow('navigator');
  const userAgent = navigator?.userAgent;

  const event = React.useMemo(
    () =>
      surveyId && pageUrl
        ? {
            event: APP_LOAD_EVENT_NAME,
            created_at: DateTime.local().toISO(),
            survey_id: surveyId,
            page_url: pageUrl,
            userAgent,
          }
        : null,
    [pageUrl, surveyId, userAgent]
  );

  React.useEffect(() => {
    // Callback function to call when page load triggers
    const onAppLoad = () => {
      if (!event) return;

      const allowedSignupSources = Array.isArray(daDirektSignUpSources)
        ? [...SIGN_UP_SOURCES_WEB, ...daDirektSignUpSources]
        : SIGN_UP_SOURCES_WEB;

      const getDataLayer = () => {
        if (!Array.isArray(getFromWindow('dataLayer'))) {
          assignToWindow('dataLayer', []);
        }

        return getFromWindow('dataLayer');
      };

      if (allowedSignupSources.includes(signupSource)) {
        getDataLayer().push(event);
      }
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onAppLoad();
    } else {
      window.addEventListener('load', onAppLoad, false);
    }

    // Remove the event listener when component unmounts
    return () => window.removeEventListener('load', onAppLoad);
  }, [assignToWindow, daDirektSignUpSources, event, getFromWindow, signupSource]);
}

export default useAppLoad;
