import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import sha256 from 'crypto-js/sha256';
import useSessionStorage from '../../hooks/useSessionStorage';
import useSchemaAPI from '../../api/useSchemaAPI';
import { receivedSchema, receivedSchemaConfig, setSchemaDownloadFailed } from './schemaSlice';

const generateHash = (text) => {
  if (text) {
    try {
      return sha256(text).toString();
    } catch (error) {
      return null;
    }
  } else {
    return null;
  }
};

const useFetchSchema = (flow, product, organisation) => {
  const dispatch = useDispatch();
  const [getFromSessionStorage /* saveToSessionStorage */, , removeFromSessionStorage] =
    useSessionStorage();

  const [fetchSchemaAPI, fetchSchemaConfigAPI] = useSchemaAPI();

  const alreadyFetchedSchemaConfig = useSelector((state) => state?.schema?.schemaConfig);

  // Fetch schema config separately, and only once
  useEffect(() => {
    if (!alreadyFetchedSchemaConfig) {
      fetchSchemaConfigAPI(product, organisation).then((schemaConfig) =>
        dispatch(receivedSchemaConfig({ schemaConfig }))
      );
    }
  }, [dispatch, product, organisation, alreadyFetchedSchemaConfig, fetchSchemaConfigAPI]);

  // Fetch the schema if the flow changes
  useEffect(() => {
    if (flow) {
      fetchSchemaAPI(flow, product, organisation)
        .then((activeSchema) => {
          if (activeSchema) {
            const schemaHash = generateHash(JSON.stringify(activeSchema));
            dispatch(receivedSchema({ activeSchema, schemaHash }));
          } else {
            dispatch(setSchemaDownloadFailed({ schemaDownloadFailed: true }));
          }
        })
        .catch(() => {
          dispatch(setSchemaDownloadFailed({ schemaDownloadFailed: true }));
        });
    } else {
      // This will ensure that the default schema is loaded by the useDefaultSchema hook
      dispatch(setSchemaDownloadFailed({ schemaDownloadFailed: true }));
    }
  }, [
    dispatch,
    flow,
    product,
    organisation,
    getFromSessionStorage,
    removeFromSessionStorage,
    fetchSchemaAPI,
  ]);

  return null;
};

export default useFetchSchema;
