import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import useS2STrackingAPI from '../../../api/useS2STrackingAPI';
import { extractQueryParams } from '../../../utils/URLHelper';

const useOutbrainIdentifier = () => {
  const location = useLocation();
  const [storeAdServiceIdentifierAPI] = useS2STrackingAPI();

  const storeOutbrainIdentifier = useCallback(
    (uuid, email) => {
      const { obclid = null } = extractQueryParams(location);
      if (obclid) {
        const data = { obclid };
        storeAdServiceIdentifierAPI(uuid, email, data);
      }
    },
    [location, storeAdServiceIdentifierAPI]
  );

  return storeOutbrainIdentifier;
};

export default useOutbrainIdentifier;
