import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  OPTIN_EMAIL_GROUP_ZURICH,
  OPTIN_EMAIL_ZURICH,
  OPTIN_TELEPHONE_GROUP_ZURICH,
  OPTIN_TELEPHONE_ZURICH,
  PAYMENT_METHOD_SEPA,
  PAYMENT_PERIOD_MONTHLY,
  INSURANCE_FOR_ME,
  INSURANCE_FOR_ANOTHER,
  INSURANCE_FOR_ME_LABEL,
  INSURANCE_FOR_ANOTHER_LABEL,
} from '../../constants/Constants';
import useWindow from '../../hooks/useWindow';
import useSignupSource from '../schema/useSignupSource';
import usePetBreedsAPI from '../widgetsAPIData/usePetBreedsAPI';
import usePoliciesDataAPI from '../widgetsAPIData/usePoliciesDataAPI';
import usePolicyAddonsAPI from '../widgetsAPIData/usePolicyAddonsAPI';
import usePolicyPriceAPI from '../widgetsAPIData/usePolicyPriceAPI';
import { getAgeInMonthsAndYears } from '../../utils/DateHelper';

const getContactOptInValue = (communicationList, schema) => {
  if (
    !!communicationList?.[`${OPTIN_EMAIL_ZURICH}`] &&
    !!communicationList?.[`${OPTIN_TELEPHONE_ZURICH}`]
  ) {
    return schema?.contact_optin_email_and_phone;
  }
  if (
    !!communicationList?.[`${OPTIN_EMAIL_ZURICH}`] &&
    !communicationList?.[`${OPTIN_TELEPHONE_ZURICH}`]
  ) {
    return schema?.contact_optin_email;
  }
  if (
    !communicationList?.[`${OPTIN_EMAIL_ZURICH}`] &&
    !!communicationList?.[`${OPTIN_TELEPHONE_ZURICH}`]
  ) {
    return schema?.contact_optin_phone;
  }
  return '';
};

const getGroupOptInValue = (communicationList, schema) => {
  if (
    !!communicationList?.[`${OPTIN_EMAIL_GROUP_ZURICH}`] &&
    !!communicationList?.[`${OPTIN_TELEPHONE_GROUP_ZURICH}`]
  ) {
    return schema?.group_optin_email_and_phone;
  }
  if (
    !!communicationList?.[`${OPTIN_EMAIL_GROUP_ZURICH}`] &&
    !communicationList?.[`${OPTIN_TELEPHONE_GROUP_ZURICH}`]
  ) {
    return schema?.group_optin_email;
  }
  if (
    !communicationList?.[`${OPTIN_EMAIL_GROUP_ZURICH}`] &&
    !!communicationList?.[`${OPTIN_TELEPHONE_GROUP_ZURICH}`]
  ) {
    return schema?.group_optin_phone;
  }
  return '';
};

const useDaDirektTracking = (page) => {
  // The event will be sent when this is set to true
  const [eventName, setEventName] = useState('');

  const schema = useSelector((state) => state?.schema?.activeSchema?.SCHEMA);

  const pages = schema?.pages;
  const currentPageId = page?.pageId;
  const currentPageNumber = Array.isArray(pages)
    ? pages.findIndex((p) => !!currentPageId && p?.pageId === currentPageId)
    : -1;

  const [getFromWindow, assignToWindow] = useWindow();
  const windowLocation = getFromWindow('location');
  const pageUrl = `${windowLocation?.origin}${windowLocation?.pathname}`;

  const productName = schema?.product_name;
  const productId = schema?.product_id;
  const productCategory = schema?.product_category;

  const getPolicyData = usePoliciesDataAPI();
  const [policyDataForCategory /* policiesData */, , policiesDataLoading] = getPolicyData();
  let policyName = policyDataForCategory?.name || '';

  const [selectedAddons] = usePolicyAddonsAPI();

  if (policyName && Array.isArray(selectedAddons) && selectedAddons.length > 0) {
    policyName = `${policyName} und ${selectedAddons[0]?.name}`;
  }

  const billingDay = useSelector((state) => state?.lead?.leadData?.contract?.billing_day) || null;

  const getPolicyPriceDetails = usePolicyPriceAPI();
  const [policyPriceDetails, policyPriceDetailsLoading] = getPolicyPriceDetails();
  const policyPrice = policyPriceDetails?.policy_price || 0;
  const policyPriceYearly = policyPrice ? (policyPrice * 1200) / 100 : 0; // Round off to two decimal places
  const policyAddonsPrice = policyPriceDetails?.addon_price || 0;
  const policyPriceWithAddons = policyPrice + policyAddonsPrice || 0;
  const policyPriceWithAddonsYearly = policyPriceWithAddons
    ? (policyPriceWithAddons * 1200) / 100
    : 0; // Round off to two decimal places

  const startingAt = useSelector((state) => state?.lead?.leadData?.contract?.starting_at) || null;

  const dateOfBirth = useSelector((state) => state?.lead?.leadData?.customer?.date_of_birth);
  const { years: ageOfPerson } = getAgeInMonthsAndYears(dateOfBirth) || {};

  const dateOfBirthPet = useSelector((state) => state?.lead?.leadData?.pet?.date_of_birth);
  const { years: ageOfPet } = getAgeInMonthsAndYears(dateOfBirthPet) || {};

  const [petBreedsList, petBreedsLoading] = usePetBreedsAPI();
  const petBreedId = useSelector((state) => state?.lead?.leadData?.pet?.breed_id) || null;
  const breedDetails = Array.isArray(petBreedsList)
    ? petBreedsList.find((breed) => breed?.id === petBreedId)
    : null;
  const petBreedName = breedDetails?.name || null;

  const communicationList = useSelector((state) => state?.lead?.leadData?.communication_list);
  const contactOptInValue = getContactOptInValue(communicationList, schema);
  const groupOptInValue = getGroupOptInValue(communicationList, schema);

  const insuranceFor = useSelector((state) => state?.lead?.leadData?.contract?.insurance_for);

  let situation = '';
  if (insuranceFor === INSURANCE_FOR_ME) {
    situation = INSURANCE_FOR_ME_LABEL;
  } else if (insuranceFor === INSURANCE_FOR_ANOTHER) {
    situation = INSURANCE_FOR_ANOTHER_LABEL;
  }

  const dateOfBirthOfInsured = useSelector(
    (state) => state?.lead?.leadData?.insured_person?.date_of_birth
  );
  let ageOfOtherPersonInsured = '';
  if (insuranceFor === INSURANCE_FOR_ANOTHER && !!dateOfBirthOfInsured) {
    const { years: age } = getAgeInMonthsAndYears(dateOfBirthOfInsured);
    ageOfOtherPersonInsured = age;
  }

  let ageAddInsurant = '';
  if (ageOfPet) {
    ageAddInsurant = ageOfPet;
  } else if (ageOfOtherPersonInsured) {
    ageAddInsurant = ageOfOtherPersonInsured;
  }

  const eventPayload = useMemo(
    () => ({
      event: eventName,
      ecommerce: {
        checkout: {
          actionField: {
            step: currentPageNumber + 2, // DA Direkt likes to track the event when a page loads, so we just increment the page number
            stepName: currentPageId || '',
            urlReferrer: pageUrl,
          },
          products: [
            {
              name: productName || '',
              id: productId || '',
              category: productCategory || '',
              variant: policyName || '',
              paymentPeriod: PAYMENT_PERIOD_MONTHLY,
              paymentMethod: PAYMENT_METHOD_SEPA,
              debit: billingDay || '',
              quantity: 1,
              priceStandard: `${policyPrice}`,
              priceStandardYearly: `${policyPriceYearly}`,
              price: `${policyPriceWithAddons}`,
              priceYearly: `${policyPriceWithAddonsYearly}`,
              priceExtra: `${policyAddonsPrice}`,
              inceptionDate: startingAt || '',
              situation,
              ageInsurant: ageOfPerson ? `${ageOfPerson}` : '',
              ageAddInsurant: `${ageAddInsurant}`,
              breedName: petBreedName || '',
              breedId: petBreedId ? `${petBreedId}` : '',
              contact: contactOptInValue,
              optin: groupOptInValue,
            },
          ],
        },
      },
    }),
    [
      eventName,
      currentPageId,
      currentPageNumber,
      pageUrl,
      productName,
      productId,
      productCategory,
      policyName,
      billingDay,
      startingAt,
      policyPrice,
      policyPriceYearly,
      policyPriceWithAddons,
      policyPriceWithAddonsYearly,
      policyAddonsPrice,
      situation,
      ageOfPerson,
      ageAddInsurant,
      petBreedId,
      petBreedName,
      contactOptInValue,
      groupOptInValue,
    ]
  );

  const allowedSignUpSources = useSelector(
    (state) => state?.schema?.schemaConfig?.signUpSourcesDaDirekt
  );

  const signupSource = useSignupSource();

  useEffect(() => {
    if (!!eventName && !petBreedsLoading && !policiesDataLoading && !policyPriceDetailsLoading) {
      const getDataLayer = () => {
        if (!Array.isArray(getFromWindow('dataLayer'))) {
          assignToWindow('dataLayer', []);
        }
        return getFromWindow('dataLayer');
      };

      if (Array.isArray(allowedSignUpSources) && allowedSignUpSources.includes(signupSource)) {
        getDataLayer().push(eventPayload);
      }
      setEventName('');
    }
  }, [
    eventName,
    getFromWindow,
    assignToWindow,
    signupSource,
    eventPayload,
    petBreedsLoading,
    policiesDataLoading,
    policyPriceDetailsLoading,
    allowedSignUpSources,
  ]);

  const trackDaDirektEvents = (event) => {
    setEventName(event);
    return Promise.resolve(null);
  };

  return trackDaDirektEvents;
};

export default useDaDirektTracking;
