import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { createQueryParamsString, extractQueryParams } from '../../utils/URLHelper/URLHelper';
import { setSchemaDownloadFailed } from './schemaSlice';

const useDefaultSchema = (flow) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const schemaFailedToDownload = useSelector((state) => state?.schema?.schemaDownloadFailed);

  const schemaConfig = useSelector((state) => state?.schema?.schemaConfig);
  const schemaConfigDownloaded = !!schemaConfig;

  const defaultSchema = schemaConfig?.defaultSchema;

  useEffect(() => {
    if (schemaFailedToDownload && schemaConfigDownloaded && !!defaultSchema) {
      dispatch(setSchemaDownloadFailed({ schemaDownloadFailed: false }));
      const queryParams = extractQueryParams(location);
      const newQueryParams = { ...queryParams, flow: defaultSchema };
      const search = createQueryParamsString(newQueryParams);
      navigate(`${location.pathname}${search}`, { replace: true });
    }
  }, [
    dispatch,
    schemaFailedToDownload,
    schemaConfigDownloaded,
    defaultSchema,
    flow,
    navigate,
    location,
  ]);
};

export default useDefaultSchema;
