import { useLocation } from 'react-router-dom';
import useUuid from './useUuid';
import useCustomerAPI from '../../api/useCustomerAPI';
import { extractQueryParams } from '../../utils/URLHelper';
import { BLACKLISTED_EMAILS } from '../../constants/Constants';

const useCreateCustomer = (shouldCreateCustomer) => {
  const location = useLocation();
  const { skip_customer: skipCustomer = false } = extractQueryParams(location);

  const uuid = useUuid();

  const [createCustomerAPI] = useCustomerAPI();

  const createCustomer = (updatedLeadData) => {
    const customerEmail = updatedLeadData?.customer?.email;
    const isBlacklistedEmail = customerEmail ? BLACKLISTED_EMAILS.includes(customerEmail) : false;

    if (!!shouldCreateCustomer && !!updatedLeadData && !skipCustomer && !isBlacklistedEmail) {
      return createCustomerAPI(uuid);
    }
    return Promise.resolve(null);
  };

  return createCustomer;
};

export default useCreateCustomer;
