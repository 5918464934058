import useUuid from '../customer/useUuid';
import useSignupSource from '../schema/useSignupSource';
import useS2STrackingAPI from '../../api/useS2STrackingAPI';
import { SIGN_UP_SOURCES_WEB } from '../../constants/Constants';
import useWindow from '../../hooks/useWindow';

const useCustomEventsTracking = (page) => {
  const uuid = useUuid();
  const signupSource = useSignupSource();
  const [getFromWindow] = useWindow();
  const [, /* storeAdServiceIdentifierAPI */ trackEventsAPI] = useS2STrackingAPI();

  const trackCustomEvents = () => {
    if (SIGN_UP_SOURCES_WEB.includes(signupSource)) {
      const customEvents = Array.isArray(page?.customEvents) ? page.customEvents : [];
      const pageSubmitEvent = `${page?.pageId}`;
      const events = [pageSubmitEvent, ...customEvents];

      const location = getFromWindow('location');
      const additionalData = { url: location?.href };

      return trackEventsAPI(uuid, events, signupSource, additionalData);
    }
    return Promise.resolve(null);
  };

  return trackCustomEvents;
};

export default useCustomEventsTracking;
