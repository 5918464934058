import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useFaircareBaseUrl from '../schema/useFaircareBaseUrl';
import useWidgetsAPIData from './useWidgetsAPIData';
import { getObjectProperty } from '../../utils/GeneralHelper/GeneralHelper';

const usePetBreedsAPI = (petTypelookup) => {
  const pathForPetType = `${petTypelookup || 'pet'}.pet_type`;
  const leadData = useSelector((state) => state?.lead?.leadData);
  const petType = getObjectProperty(leadData, pathForPetType);

  const [baseUrl, defaultHeaders] = useFaircareBaseUrl();
  const url = petType ? `${baseUrl}/api/petolo/v1/breeds?pet_type=${petType}` : '';

  const { data: fetchedAPIData, loading: petBreedsLoading } = useWidgetsAPIData(
    url,
    defaultHeaders
  );

  const petBreedsList = useMemo(
    () => (Array.isArray(fetchedAPIData) ? fetchedAPIData : []),
    [fetchedAPIData]
  );

  return [petBreedsList, petBreedsLoading];
};

export default usePetBreedsAPI;
