import { useCallback } from 'react';

const getWindow = () => {
  return window;
};

export default function useWindow() {
  const windowObject = getWindow();

  const getFromWindow = useCallback(
    (key) => {
      try {
        return !!windowObject && windowObject[`${key}`] ? windowObject[`${key}`] : null;
      } catch (error) {
        return null;
      }
    },
    [windowObject]
  );

  const assignToWindow = useCallback(
    (key, value) => {
      if (windowObject) {
        windowObject[`${key}`] = value;
      }
    },
    [windowObject]
  );

  const openNewWindow = useCallback(
    (url, target, features) => {
      if (windowObject) {
        windowObject.open(url, target, features);
      }
    },
    [windowObject]
  );

  const postMessage = useCallback(
    (message) => {
      if (windowObject && message) {
        windowObject.postMessage(message, windowObject?.location?.origin);
      }
    },
    [windowObject]
  );

  return [getFromWindow, assignToWindow, openNewWindow, postMessage];
}
