import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSessionStorage from '../../hooks/useSessionStorage';
import { QUESTION_ANSWERS_SESSION_STORAGE_KEY } from '../../constants/Constants';
import { restoreQuestionAnswers } from './questionsSlice';

const useOfflineQuestionAnswers = () => {
  const dispatch = useDispatch();
  const [getFromSessionStorage, saveToSessionStorage] = useSessionStorage();

  const answers = useSelector((state) => state?.questions?.answers);

  useEffect(() => {
    const offlineQuestionAnswers = getFromSessionStorage(QUESTION_ANSWERS_SESSION_STORAGE_KEY);
    dispatch(restoreQuestionAnswers({ answers: offlineQuestionAnswers }));
  }, [dispatch, getFromSessionStorage]);

  useEffect(() => {
    saveToSessionStorage(QUESTION_ANSWERS_SESSION_STORAGE_KEY, answers);
  }, [answers, saveToSessionStorage]);
};

export default useOfflineQuestionAnswers;
