import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useSessionStorage from '../../hooks/useSessionStorage';
import { clearAllErrorsOnPage } from '../errorValidation/errorValidationSlice';
import { extractQueryParams, createQueryParamsString } from '../../utils/URLHelper';
import {
  LEAD_DATA_SESSION_STORAGE_KEY,
  QUESTION_ANSWERS_SESSION_STORAGE_KEY,
} from '../../constants/Constants';

const useCustomerGenerated = (setCurrentPageNumber) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const customerGenerated = useSelector((state) => state?.lead?.leadData?.customer_generated);
  const [, , /* getFromSessionStorage */ /* saveToSessionStorage */ removeFromSessionStorage] =
    useSessionStorage();

  useEffect(() => {
    if (customerGenerated === true) {
      // Clear exisitng lead data
      removeFromSessionStorage(LEAD_DATA_SESSION_STORAGE_KEY);
      removeFromSessionStorage(QUESTION_ANSWERS_SESSION_STORAGE_KEY);

      // Remove uuid from the URL so that new lead can be generated
      const queryParams = extractQueryParams(location);
      const { uuid, ...otherQueryParams } = queryParams;
      const search = createQueryParamsString(otherQueryParams);
      navigate(`${location.pathname}${search}`, { replace: true });

      // Remove validation errors from current page
      dispatch(clearAllErrorsOnPage());

      // Navigate to the first page of the schema
      setCurrentPageNumber(0);
    }
  }, [
    dispatch,
    navigate,
    location,
    customerGenerated,
    removeFromSessionStorage,
    setCurrentPageNumber,
  ]);
};

export default useCustomerGenerated;
