// get value from nested object using path as string
export const getObjectProperty = (obj, path) => {
  if (!path) return undefined;

  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  const result = pathArray.reduce((prevObj, key) => prevObj && prevObj[`${key}`], obj);

  return result === undefined ? null : result;
};

// set value to nested object using path as string
export const setObjectProperty = (obj, path, value) => {
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);

  pathArray.reduce((acc, key, i, arrKey) => {
    const indexOfKey = arrKey.findIndex((item) => item === key);
    const arrKeyLength = arrKey?.length;

    const newAcc = acc;

    if (newAcc[`${key}`] === undefined || newAcc[`${key}`] === null) {
      // if the current key has child key (it is an object), then create an empty `object` or else use `null`
      newAcc[`${key}`] = indexOfKey < arrKeyLength - 1 ? {} : null;
    }

    if (i === pathArray.length - 1) {
      newAcc[`${key}`] = value;
    }

    return newAcc[`${key}`];
  }, obj);
};

export const safeParseJSON = (json) => {
  try {
    return JSON.parse(json);
  } catch (error) {
    return '';
  }
};

export const safeParseInt = (integerValue, defaultValue) => {
  const parsedValue = parseInt(integerValue, 10);
  if (Number.isNaN(parsedValue)) {
    return defaultValue;
  }
  return parsedValue;
};

export const checkFormValidity = (formRef) => {
  const reportValidityAvailable =
    !!formRef?.current && typeof formRef.current.reportValidity === 'function';
  const checkValidityAvailable =
    !!formRef?.current && typeof formRef.current.checkValidity === 'function';

  if (reportValidityAvailable) {
    const isFormValid = formRef.current.reportValidity();
    return isFormValid;
  }
  if (checkValidityAvailable) {
    const isFormValid = formRef.current.checkValidity();
    return isFormValid;
  }
  // Unable to check if the form is valid or not. So let the user proceed
  return true;
};

export const padStart = (stringValue, length, value) => {
  const validatedStringValue = stringValue || '';
  const padStartAvailable =
    !!validatedStringValue && typeof validatedStringValue.padStart === 'function';

  if (padStartAvailable) {
    return validatedStringValue.padStart(length, value);
  }
  return `${new Array(length).fill(`${value}`).join('')}${stringValue}`.slice(-length);
};

export const promiseWithRetry = (fn, retriesLeft = 5, interval = 500) => {
  if (typeof fn === 'function') {
    return new Promise((resolve, reject) => {
      fn()
        .then(resolve)
        .catch((error) => {
          setTimeout(() => {
            if (retriesLeft === 1) {
              reject(error);
              return;
            }
            promiseWithRetry(fn, retriesLeft - 1, interval).then(resolve, reject);
          }, interval);
        });
    });
  }
  return Promise.resolve(null);
};

export const resolvePathValue = (pathValue, dataObject) => {
  // #TODO: remove this after `multiple_pets` testing is complete
  // const pathValueArray = pathValue ? pathValue.split('.') : [];
  // const value = pathValueArray.reduce((agg, next) => (agg ? agg[`${next}`] : null), dataObject);

  const value = getObjectProperty(dataObject, pathValue);

  return value;
};

// Adds index to path of nested object
// `checker`: (optional, string) if index needs to be added to a specific word
export const addIndexToPath = (path, index, checker = '') => {
  if (!path) return '';

  const checkerLen = checker?.length || null;

  const splitPath = path.split('.');
  const lastWord = checker.slice(-checkerLen);

  if (!!checker && lastWord !== checker) return path;

  const tempPath = splitPath.slice(1, splitPath?.length);
  tempPath.unshift(`${splitPath[0]}[${index}]`);

  return tempPath.join('.');
};

export const isBoolean = (value) =>
  typeof value === 'boolean' || (typeof value === 'object' && value instanceof Boolean);

export const isEmptyObject = (obj) => {
  return !!obj && typeof obj === 'object' && Object.keys(obj).length < 1;
};

export const isEmptyArray = (arr) => {
  return Array.isArray(arr) && arr.length < 1;
};

// adds index to `submitTo` or `fieldGroupName`
export const getDataSchemaForMultipleContracts = (dataSchema, contractIndex) => {
  const dataSchemaKeys = Object.keys(dataSchema) || [];

  return dataSchemaKeys.reduce((attrs, key) => {
    const currDataSchema = dataSchema?.[`${key}`] || {};
    const { submitTo, fieldGroupName } = currDataSchema;

    if (submitTo) {
      const submitToWithIndex = addIndexToPath(submitTo, contractIndex, 'contracts');

      return { ...attrs, [`${key}`]: { ...currDataSchema, submitTo: submitToWithIndex } };
    }

    if (fieldGroupName) {
      const fieldGroupNameWithIndex = addIndexToPath(fieldGroupName, contractIndex, 'contracts');

      return {
        ...attrs,
        [`${key}`]: { ...currDataSchema, fieldGroupName: fieldGroupNameWithIndex },
      };
    }

    return { ...attrs, [`${key}`]: currDataSchema };
  }, {});
};

export const scrollToFirstErrorElement = (form) => {
  // find the first invalid element
  const firstInvalidElement = form?.querySelector(':invalid');

  if (!form || !firstInvalidElement) return;

  // Calculate position with extra spacing, e.g., 100px above the element
  const scrollPosition = firstInvalidElement.getBoundingClientRect().top + window.scrollY - 150;

  // Scroll to the first invalid element with extra spacing
  window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
};

// flatten nested object
// eg. a: { b: { c: 1 } } => { 'a.b.c': 1 }
export const getFlattenedObject = (nestedObject, parentKey = '') => {
  return Object.entries(nestedObject).reduce((acc, [key, value]) => {
    const newKey = parentKey ? `${parentKey}.${key}` : key;

    return typeof value === 'object' && value !== null
      ? { ...acc, ...getFlattenedObject(value, newKey) }
      : { ...acc, [newKey]: value };
  }, {});
};
