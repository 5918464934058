import { useLocation } from 'react-router-dom';

import useWindow from '../../hooks/useWindow';
import { extractQueryParams } from '../../utils/URLHelper';
import { getProductName } from '../../utils/ProductHelper';
import {
  ORGANISATIONS,
  TYRION_GLOBAL_PARAMETERS_COUNTRY_KEY,
  TYRION_GLOBAL_PARAMETERS_FLOW_KEY,
  TYRION_GLOBAL_PARAMETERS_KEY,
  TYRION_GLOBAL_PARAMETERS_ORGANISATION_KEY,
  TYRION_GLOBAL_PARAMETERS_PRODUCT_KEY,
  TYRION_GLOBAL_PARAMETERS_THEME_KEY,
} from '../../constants/Constants';

const useParameters = () => {
  const location = useLocation();

  const {
    organisation: organisationFromUrl,
    product: productFromUrl,
    flow: flowFromUrl,
    theme: themeFromUrl,
    country: countryFromUrl,
  } = extractQueryParams(location);

  const [getFromWindow] = useWindow();
  const tyrionParamsFromPage = getFromWindow(TYRION_GLOBAL_PARAMETERS_KEY);

  const organisationFromPage =
    tyrionParamsFromPage?.[`${TYRION_GLOBAL_PARAMETERS_ORGANISATION_KEY}`];
  const providedOrganisation = organisationFromUrl || organisationFromPage;
  const organisation =
    !!providedOrganisation && ORGANISATIONS.includes(providedOrganisation)
      ? providedOrganisation
      : null;

  const productFromPage = tyrionParamsFromPage?.[`${TYRION_GLOBAL_PARAMETERS_PRODUCT_KEY}`];
  const productLabel = productFromUrl || productFromPage;
  const product = getProductName(productLabel);

  const flowFromPage = tyrionParamsFromPage?.[`${TYRION_GLOBAL_PARAMETERS_FLOW_KEY}`];
  const flow = flowFromUrl || flowFromPage;

  const themeFromPage = tyrionParamsFromPage?.[`${TYRION_GLOBAL_PARAMETERS_THEME_KEY}`];
  const theme = themeFromUrl || themeFromPage;

  const countryFromPage = tyrionParamsFromPage?.[`${TYRION_GLOBAL_PARAMETERS_COUNTRY_KEY}`];
  const country = countryFromUrl?.toLowerCase() || countryFromPage?.toLowerCase();

  return {
    organisation,
    product,
    flow,
    theme,
    country,
  };
};

export default useParameters;
