import { useEffect, useState } from 'react';
import { APP_ENV, LEADS_FAIRCARE_BASE_URL } from '../app/environment';

const useBackendBranchName = () => {
  const [backendBranchName, setBackendBranchName] = useState('');

  useEffect(() => {
    if (APP_ENV === 'beta') {
      fetch(`${LEADS_FAIRCARE_BASE_URL}/current_branch`)
        .then((response) => response.json())
        .then((json) => {
          const deployedBranchName = json?.name;
          if (deployedBranchName) {
            setBackendBranchName(deployedBranchName);
          }
        })
        .catch(() => null);
    }
  }, []);

  return backendBranchName;
};

export default useBackendBranchName;
