import React from 'react';

import useWindow from '../../hooks/useWindow';
import { DOMAINS_DENTOLO, DOMAINS_PETOLO, DOMAINS_VITOLO } from '../../constants/domains';

const allowedDomains = [...DOMAINS_DENTOLO, ...DOMAINS_PETOLO, ...DOMAINS_VITOLO];

const generateCustomKeyWithPrefix = (key) => {
  const customKey = key.toLowerCase().replace(/ /g, '_');

  return customKey ? `consent_${customKey}` : null;
};

function useCookieConsentStatus() {
  const [getFromWindow] = useWindow();

  const getCookieConsentList = React.useCallback(() => {
    const { hostname } = getFromWindow('location') || {};
    const strippedHostname = hostname.startsWith('www.') ? hostname.slice(4) : hostname;

    if (!allowedDomains.includes(strippedHostname)) return null;

    const { getServicesBaseInfo } = getFromWindow('UC_UI') || {};

    if (!getServicesBaseInfo || !Array.isArray(getServicesBaseInfo())) return null;

    const mapped = getServicesBaseInfo().reduce((service, { name, consent }) => {
      const customKey = generateCustomKeyWithPrefix(name);

      if (!customKey) return service;

      return { ...service, [customKey]: consent?.status };
    }, {});

    return mapped;
  }, [getFromWindow]);

  return { getCookieConsentList };
}

export default useCookieConsentStatus;
