export const extractQueryParamsFromString = (queryParamString) => {
  if (typeof queryParamString === 'string') {
    const queryParamArray = queryParamString.split('&');
    const queryParams = queryParamArray.reduce((agg, next) => {
      const [key, value] = next.split('=');
      if (!!key && !!value) {
        return { ...agg, [key]: value };
      }
      return agg;
    }, {});
    return queryParams;
  }
  return {};
};

export const getQueryParamsString = (searchString) => {
  const search = searchString || '';
  const queryParamString = search.length && search[0] === '?' ? search.substring(1) : '';
  return queryParamString;
};

export const extractQueryParams = (location) => {
  const queryParamString = getQueryParamsString(location?.search);
  return extractQueryParamsFromString(queryParamString);
};

export const createQueryParamsString = (queryParams) => {
  if (queryParams) {
    const queryParamsArray = Object.keys(queryParams).map(
      (item) => `${item}=${queryParams[`${item}`]}`
    );
    const queryParamsString = queryParamsArray.join('&');
    return queryParamsString ? `?${queryParamsString}` : '';
  }
  return '';
};

export const generateQueryParamValues = (currentParamValues, newValue, oldValue) => {
  if (typeof currentParamValues !== 'string' && !Array.isArray(currentParamValues)) {
    return newValue;
  }

  let newParamValuesArr = [];

  if (Array.isArray(currentParamValues)) {
    newParamValuesArr = [...currentParamValues];
  } else if (currentParamValues && typeof currentParamValues === 'string') {
    newParamValuesArr = currentParamValues.split(',');
  }

  if (oldValue && newParamValuesArr.includes(oldValue)) {
    newParamValuesArr = newParamValuesArr.filter((item) => item !== oldValue);
  }

  if (newValue && !newParamValuesArr.includes(newValue)) {
    newParamValuesArr.push(newValue);
  }

  return newParamValuesArr.join(',');
};

export const getPageIdFromUrl = () => {
  const hash = window?.location?.hash || '';
  const pageId = hash ? hash.replace('#', '') : null;

  return pageId;
};
