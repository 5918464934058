import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  LEADS_FAIRCARE_BASE_URL,
  LEADS_BASE_URL,
  LEADS_CLIENT_API_KEY,
} from '../../../app/environment';

const useDentoloFaircareBaseUrl = () => {
  const enableFaircareApi = useSelector((state) => state?.schema?.schemaConfig?.enableFaircareApi);
  const baseUrl = enableFaircareApi ? LEADS_FAIRCARE_BASE_URL : LEADS_BASE_URL;

  const defaultHeaders = useMemo(
    () => ({
      'Content-type': 'application/json',
      'X-API-KEY': LEADS_CLIENT_API_KEY,
      'KeyId': LEADS_CLIENT_API_KEY,
    }),
    []
  );

  return [baseUrl, defaultHeaders];
};

export default useDentoloFaircareBaseUrl;
