import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import useUuid from '../customer/useUuid';
import useSurveyId from '../schema/useSurveyId';
import useGduid from './useGduid';
import useWindow from '../../hooks/useWindow';
import { extractQueryParams } from '../../utils/URLHelper';
import { TRACKER_ENV } from '../../app/environment';

const useDtlTracker = (page) => {
  const location = useLocation();
  const { uuid: uuidFromUrl = null } = extractQueryParams(location);

  const uuid = useUuid();
  const surveryId = useSurveyId();
  const getGduid = useGduid();
  const pageId = page?.pageId;

  const [getFromWindow] = useWindow();

  const sendDataToDtlTracker = useCallback(
    (initialParameters, method, methodArguments) => {
      const scriptChecker = setInterval(() => {
        const DtlTracker = getFromWindow('dtlTracker');
        if (typeof DtlTracker === 'function') {
          const dentoloTracker = new DtlTracker(initialParameters);
          if (!!dentoloTracker && typeof dentoloTracker?.[`${method}`] === 'function') {
            dentoloTracker[`${method}`].call(methodArguments);
          }
          clearInterval(scriptChecker);
        }
      }, 1000);
    },
    [getFromWindow]
  );

  const trackDtlPageLoad = useCallback(() => {
    const parameters = {
      originatingService: 'dentolo-landing-webflow',
      dtlSurveyId: surveryId,
      dtlUtoken: uuidFromUrl,
      environment: TRACKER_ENV,
    };
    sendDataToDtlTracker(parameters, 'collectAndSendData', null);

    return Promise.resolve(null);
  }, [sendDataToDtlTracker, surveryId, uuidFromUrl]);

  const trackDtlEvents = useCallback(() => {
    const gduid = getGduid();
    const parameters = {
      eventType: 'click',
      dtlSurveyId: surveryId,
      dtlUtoken: uuid,
      dtlPageId: pageId,
      dtlGduid: gduid,
      environment: TRACKER_ENV,
    };
    sendDataToDtlTracker(parameters, 'collectAndSendSurveyData', null);

    return Promise.resolve(null);
  }, [sendDataToDtlTracker, surveryId, uuid, pageId, getGduid]);

  const trackDtlUserData = useCallback(
    (userData) => {
      const DtlTracker = getFromWindow('dtlTracker');
      if (typeof DtlTracker === 'function') {
        const parameters = {
          environment: TRACKER_ENV,
        };
        const dentoloTracker = new DtlTracker(parameters);
        if (!!dentoloTracker && typeof dentoloTracker?.sendUserData === 'function') {
          const payload = JSON.stringify({ userData });
          dentoloTracker.sendUserData(payload);
        }
      }
      return Promise.resolve(null);
    },
    [getFromWindow]
  );

  return [trackDtlEvents, trackDtlPageLoad, trackDtlUserData];
};

export default useDtlTracker;
