import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import useLocalStorage from '../../hooks/useLocalStorage';
import { extractQueryParams } from '../../utils/URLHelper';

const useGduid = () => {
  const location = useLocation();
  const { gduid: gduidFromUrl = null } = extractQueryParams(location);

  const [getFromLocalStorage] = useLocalStorage();

  const getGduid = useCallback(() => {
    const gduidFromCookie = getFromLocalStorage('gduid');
    return gduidFromUrl || gduidFromCookie;
  }, [getFromLocalStorage, gduidFromUrl]);

  return getGduid;
};

export default useGduid;
