import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  PETOLO_FAIRCARE_BASE_URL,
  PETOLO_BASE_URL,
  PETOLO_CLIENT_API_KEY,
} from '../../../app/environment';

const usePetoloFaircareBaseUrl = () => {
  const enableFaircareApi = useSelector((state) => state?.schema?.schemaConfig?.enableFaircareApi);
  const baseUrl = enableFaircareApi ? PETOLO_FAIRCARE_BASE_URL : PETOLO_BASE_URL;

  const defaultHeaders = useMemo(
    () => ({
      'Content-type': 'application/json',
      'X-API-KEY': PETOLO_CLIENT_API_KEY,
      'KeyId': PETOLO_CLIENT_API_KEY,
    }),
    []
  );

  return [baseUrl, defaultHeaders];
};

export default usePetoloFaircareBaseUrl;
