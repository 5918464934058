import { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    const { datadog } = this.props;

    const renderingError = `ReactRenderingError${error?.message ? ` - ${error.message}` : ''}`;
    const errorDetails = {
      message: error?.message,
      stack: error?.stack,
      componentStack: info?.componentStack,
    };

    // log error to datadog
    datadog.logger.error(renderingError, { errorDetails });
  }

  render() {
    const { children, fallback } = this.props;
    const { hasError } = this.state;

    return hasError ? fallback : children;
  }
}

export default ErrorBoundary;

ErrorBoundary.defaultProps = {
  fallback: null,
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
  datadog: PropTypes.shape({
    logger: PropTypes.shape({
      error: PropTypes.func.isRequired,
    }),
  }).isRequired,
};
