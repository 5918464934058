import { useLocation } from 'react-router-dom';
import useWindow from '../../hooks/useWindow';
import useUuid from '../customer/useUuid';
import useGduid from '../tracking/useGduid';
import {
  extractQueryParams,
  extractQueryParamsFromString,
  createQueryParamsString,
} from '../../utils/URLHelper';

const useExternalPage = () => {
  const [, /* getFromWindow */ assignToWindow] = useWindow();
  const location = useLocation();
  const {
    policy_category: policyCategory,
    policy_addons: policyAddons,
    flow,
    product,
    organisation,
    theme,
    ...queryParams
  } = extractQueryParams(location);

  const uuid = useUuid();
  const getGduid = useGduid();

  const redirectToExternalPage = (externalUrl, attachUuid, attachAllParams) => {
    const [externalUrlWithoutHash, externalUrlHash] = externalUrl.split('#');
    const [externalUrlWithoutSearch, externalUrlSearch] = externalUrlWithoutHash.split('?');
    const externalUrlQueryParams = extractQueryParamsFromString(externalUrlSearch);
    const gduid = getGduid();

    let newExternalUrlQueryParams = { ...externalUrlQueryParams, gduid };

    if (attachUuid) {
      newExternalUrlQueryParams = { ...newExternalUrlQueryParams, uuid };
    }
    if (attachAllParams) {
      newExternalUrlQueryParams = { ...newExternalUrlQueryParams, ...queryParams };
    }

    const newExternalUrlSearch = createQueryParamsString(newExternalUrlQueryParams);
    const newExternalUrl = `${externalUrlWithoutSearch}${newExternalUrlSearch}${
      externalUrlHash ? `#${externalUrlHash}` : ''
    }`;
    assignToWindow('location', newExternalUrl);
  };

  return redirectToExternalPage;
};

export default useExternalPage;
