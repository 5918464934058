import { useLocation } from 'react-router-dom';
import { extractQueryParams } from '../../utils/URLHelper';

const useReferredId = () => {
  const location = useLocation();
  const { refid: referredId = null } = extractQueryParams(location);
  return referredId;
};

export default useReferredId;
