import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import useS2STrackingAPI from '../../../api/useS2STrackingAPI';
import useCookie from '../../../hooks/useCookie';
import { extractQueryParams } from '../../../utils/URLHelper';

const useFacebookIdentifier = () => {
  const location = useLocation();
  const [getCookie] = useCookie();
  const [storeAdServiceIdentifierAPI] = useS2STrackingAPI();

  const storeFacebookIdentifier = useCallback(
    (uuid, email) => {
      const { fbclid: fbclidFromURL = null } = extractQueryParams(location);
      const fbclidFromCookie = getCookie('_fbc');
      const fbclid = fbclidFromURL || fbclidFromCookie;
      if (fbclid) {
        const data = { fbclid };
        storeAdServiceIdentifierAPI(uuid, email, data);
      }
    },
    [location, getCookie, storeAdServiceIdentifierAPI]
  );

  return storeFacebookIdentifier;
};

export default useFacebookIdentifier;
