import { combineReducers } from '@reduxjs/toolkit';
import schemaReducer from '../features/schema/schemaSlice';
import leadReducer from '../features/customer/leadSlice';
import questionsReducer from '../features/questions/questionsSlice';
import errorValidationReducer from '../features/errorValidation/errorValidationSlice';

const rootReducer = combineReducers({
  schema: schemaReducer,
  lead: leadReducer,
  questions: questionsReducer,
  errorValidation: errorValidationReducer,
});

export default rootReducer;
