import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { extractQueryParamsFromString, getQueryParamsString } from '../../utils/URLHelper';

const PARTNER_DATA_PREFIX = 'pd_';

export default function usePartnerData() {
  const location = useLocation();
  const { search } = location;

  const params = useMemo(
    () => extractQueryParamsFromString(getQueryParamsString(search)),
    [search]
  );

  const partnerDataInUrl = useMemo(() => {
    const result = Object.entries(params).reduce((acc, [key, value]) => {
      if (!key.startsWith(PARTNER_DATA_PREFIX)) return acc;

      const keyWithoutPrefix = key.slice(PARTNER_DATA_PREFIX.length);
      return { ...acc, [keyWithoutPrefix]: value };
    }, {});

    return Object.keys(result).length > 0 ? result : null;
  }, [params]);

  return partnerDataInUrl;
}
