import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { palette } from '../features/theme/Theme';

const shimmer = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const overlay = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(256, 256, 256, 0) 0,
    rgba(256, 256, 256, 0.2) 20%,
    rgba(256, 256, 256, 0.5) 60%,
    rgba(256, 256, 256, 0)
  );
  animation: ${shimmer} 2s infinite;
  content: '';
`;

const StyledRedactedDiv = styled.div`
  position: relative;
  background: ${palette.light_gray};
  border-radius: 0.125em;
  margin: auto;

  &::after {
    ${overlay};
  }
`;

const StyledRedactedThumbImage = styled(StyledRedactedDiv)`
  width: 5em;
  height: 5em;
  margin-bottom: 1em;
  margin-top: 0.5em;
  border-radius: 50%;
`;

const StyledRedactedTitle = styled(StyledRedactedDiv)`
  width: ${(props) => props?.width || '65'}%;
  height: 1.75em;
  margin-bottom: 1em;
`;

const StyledRedactedSubTitle = styled(StyledRedactedDiv)`
  width: ${(props) => props?.width || '75'}%;
  height: 1.25em;
  margin-bottom: 2em;
`;

export const StyledRedactedWidget = styled(StyledRedactedDiv)`
  width: 100%;
  height: 4em;

  & + & {
    margin-top: 1rem;
  }
`;

const titleWidth = Math.floor(Math.random() * 51) + 30;
const subTitleWidth = Math.floor(Math.random() * 31) + 60;

function SkeletonPage({ thumbImage }) {
  return (
    <>
      {!!thumbImage && <StyledRedactedThumbImage />}
      <StyledRedactedTitle width={titleWidth} />
      <StyledRedactedSubTitle width={subTitleWidth} />
      <StyledRedactedWidget />
    </>
  );
}

export default SkeletonPage;
