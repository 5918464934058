import useUuid from '../customer/useUuid';
import useSurveyAPI from '../../api/useSurveyAPI';

const allSurveyIdsInDynamicFlow = (dynamicFlows) => {
  if (Array.isArray(dynamicFlows)) {
    const surveyIds = dynamicFlows.map((dynamicFlow) => dynamicFlow?.surveyId);
    return Array.from(new Set(surveyIds));
  }
  return [];
};

const getUserSurveyDataForAllSurveyIds = async (uuid, surveyIds, getSurveyDataAPI) => {
  if (Array.isArray(surveyIds)) {
    const userSurveyDataPromise = surveyIds.map((surveyId) => {
      return getSurveyDataAPI(uuid, surveyId).then((surveyData) => ({
        [surveyId]: surveyData?.answersCollection,
      }));
    });
    const userSurveyDataArray = await Promise.all(userSurveyDataPromise);
    const userSurveyData = userSurveyDataArray.reduce((agg, next) => ({ ...agg, ...next }), {});
    return userSurveyData;
  }
  return {};
};

const evaluateAnswers = (expectedAnswers, actualAnswers) => {
  if (expectedAnswers) {
    const matchesAllExpectations = Object.keys(expectedAnswers).reduce((agg, next) => {
      const matchesExpectaion = expectedAnswers[`${next}`] === actualAnswers?.[`${next}`];
      return agg && matchesExpectaion;
    }, true);
    return matchesAllExpectations;
  }
  return false;
};

const useDynamicFlows = (page, setCurrentPage, redirectToExternalPage) => {
  const uuid = useUuid();

  const [, /* sendSurveyDataAPI */ getSurveyDataAPI] = useSurveyAPI();

  const evaluateDynamicFlows = async () => {
    if (Array.isArray(page?.dynamicFlows)) {
      const surveyIds = allSurveyIdsInDynamicFlow(page.dynamicFlows);
      const userSurveyData = await getUserSurveyDataForAllSurveyIds(
        uuid,
        surveyIds,
        getSurveyDataAPI
      );

      const matchingDynamicFlow = page.dynamicFlows.find((dynamicFlow) => {
        const surveyId = dynamicFlow?.surveyId;
        const expectedAnswers = dynamicFlow?.answers;
        const actualAnswers =
          !!surveyId && !!userSurveyData?.[`${surveyId}`] ? userSurveyData[`${surveyId}`] : {};
        const answersMatch = evaluateAnswers(expectedAnswers, actualAnswers);
        return answersMatch;
      });

      const endpoint = matchingDynamicFlow?.endpoint;
      const { nextStep, externalUrl, attachUuid, attachAllParams } = endpoint || {};

      if (nextStep) {
        return [true, () => setCurrentPage(nextStep)];
      }
      if (externalUrl) {
        return [true, () => redirectToExternalPage(externalUrl, !!attachUuid, !!attachAllParams)];
      }
      return [false, () => null];
    }
    return [false, () => null];
  };

  return evaluateDynamicFlows;
};

export default useDynamicFlows;
