import { useEffect } from 'react';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';

import useWindow from '../../../hooks/useWindow';
import useUuid from '../../customer/useUuid';
import useFaircareBaseUrl from '../../schema/useFaircareBaseUrl';
import useWidgetsAPIData from '../useWidgetsAPIData';
import usePolicyCategory from '../../policy/usePolicyCategory';
import usePolicyAddonsAPI from '../usePolicyAddonsAPI';

import { POLICY_CATEGORIES_PET_LIABILITY } from '../../../constants/policyCategory';

const usePetoloPolicyPriceAPI = (apiParams) => {
  const [, , , /* getFromWindow */ /* assignToWindow */ /* openNewWindow */ postMessage] =
    useWindow();

  const policyCategory = usePolicyCategory();
  const policyCategoryQueryParam = `policy_category=${apiParams?.policyCategory || policyCategory}`;

  const dateOfBirthFromLead = useSelector((state) => state?.lead?.leadData?.pet?.date_of_birth);
  const dateOfBirth = apiParams?.dateOfBirth || dateOfBirthFromLead;
  const dateOfBirthQueryParam = dateOfBirth
    ? `date_of_birth=${dateOfBirth}`
    : `date_of_birth=${DateTime.local().toISODate()}`;

  const startingAtFromLead = useSelector((state) => state?.lead?.leadData?.contract?.starting_at);
  const startingAt = apiParams?.startingAt || startingAtFromLead;
  const startingAtQueryParam = startingAt ? `starting_at=${startingAt}` : '';

  const breedIdFromLead = useSelector((state) => state?.lead?.leadData?.pet?.breed_id);
  const breedId = apiParams?.breedId || breedIdFromLead;
  const breedIdQueryParam = breedId ? `breed_id=${breedId}` : '';

  const [selectedAddonsDetails] = usePolicyAddonsAPI();
  const selectedAddonsQueryParam = selectedAddonsDetails
    .map((addon) => `policy_addons[]=${addon?.key}`)
    .join('&');

  const uuid = useUuid();
  const uuidQueryParam = uuid ? `uuid=${uuid}` : '';

  const queryParams = [
    policyCategoryQueryParam,
    dateOfBirthQueryParam,
    startingAtQueryParam,
    breedIdQueryParam,
    uuidQueryParam,
    selectedAddonsQueryParam,
  ]
    .filter((param) => !!param)
    .join('&');

  const [baseUrl, defaultHeaders] = useFaircareBaseUrl();
  const url = POLICY_CATEGORIES_PET_LIABILITY.includes(policyCategory)
    ? `${baseUrl}/api/petolo/v1/pet_liability/price?${queryParams}`
    : `${baseUrl}/api/petolo/v1/price?${queryParams}`;

  const { data: fetchedAPIData, loading: policyPriceDetailsLoading } = useWidgetsAPIData(
    url,
    defaultHeaders
  );

  const policyPriceDetails = fetchedAPIData || null;

  const policyPrice = policyPriceDetails?.policy_price || 0;

  useEffect(() => {
    postMessage({ event: 'policy_price', policyPrice });
  }, [postMessage, policyPrice]);

  return [policyPriceDetails, policyPriceDetailsLoading];
};

export default usePetoloPolicyPriceAPI;
