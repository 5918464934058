import { css } from 'styled-components';

import { THEME } from '../../constants/Constants';
import { applyTheme } from './Theme';

export const fontFamily = {
  circularTt: '"Circular TT", Arial',
  centraNo2: 'CentraNo2-Medium, fallback-font, sans-serif',
  skolarSans: 'SkolarSans, sans-serif',
};

export const themeFontFamily = applyTheme({
  [THEME.DENTOLO_THEME]: fontFamily.circularTt,
  [THEME.PETOLO_THEME]: fontFamily.circularTt,
  [THEME.VITOLO_THEME]: fontFamily.circularTt,
  [THEME.DA_DIREKT_THEME]: fontFamily.centraNo2,
  [THEME.FRESSNAPF_THEME]: fontFamily.skolarSans,
  [THEME.DENTOLO_LEFT_ALIGN_THEME]: fontFamily.circularTt,
});

export const fontH0 = css`
  font-family: ${themeFontFamily};
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.375rem;
`;

export const fontH1 = css`
  font-family: ${themeFontFamily};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
`;

export const fontH2 = css`
  font-family: ${themeFontFamily};
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
`;

export const fontH3 = css`
  font-family: ${themeFontFamily};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
`;

export const fontH4 = css`
  font-family: ${themeFontFamily};
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
`;

export const fontH5 = css`
  font-family: ${themeFontFamily};
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
`;

export const fontP = css`
  font-family: ${themeFontFamily};
  font-size: 1rem;
  font-weight: 450;
  line-height: 1.25rem;
`;

export const fontSubText = css`
  font-family: ${themeFontFamily};
  font-size: 0.875rem;
  font-weight: 450;
  line-height: 1.125rem;
`;

export const fontLegalText = css`
  font-family: ${themeFontFamily};
  font-size: 0.75rem;
  font-weight: 450;
  line-height: 1rem;
`;
