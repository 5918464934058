import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import useS2STrackingAPI from '../../../api/useS2STrackingAPI';
import { extractQueryParams } from '../../../utils/URLHelper';

const useGoogleBraidIdentifier = () => {
  const location = useLocation();
  const [storeAdServiceIdentifierAPI] = useS2STrackingAPI();

  const storeGoogleBraidIdentifier = useCallback(
    (uuid, email) => {
      const { gbraid = null } = extractQueryParams(location);
      if (gbraid) {
        const data = { gbraid };
        storeAdServiceIdentifierAPI(uuid, email, data);
      }
    },
    [location, storeAdServiceIdentifierAPI]
  );

  return storeGoogleBraidIdentifier;
};

export default useGoogleBraidIdentifier;
