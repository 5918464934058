import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import useSessionStorage from '../../hooks/useSessionStorage';
import useLeadAPI from '../../api/useLeadAPI';
import { leadDataReceived, saveLeadDataToStore } from './leadSlice';
import {
  extractQueryParams,
  createQueryParamsString,
  getQueryParamsString,
  extractQueryParamsFromString,
} from '../../utils/URLHelper';
import {
  LEAD_DATA_SESSION_STORAGE_KEY,
  SIGN_UP_SOURCE_DA_DIREKT_CRM,
} from '../../constants/Constants';
import useCreateLeadDataPayload from './useCreateLeadDataPayload';
import useSignupSource from '../schema/useSignupSource';
import { getDateDiffFromToday } from '../../utils/DateHelper/DateHelper';
import useMultipleContracts from '../schema/useMultipleContracts';

const validityCheckSignupSource = [SIGN_UP_SOURCE_DA_DIREKT_CRM];

const checkLeadDataValidity = (signupSource, leadData) => {
  if (!leadData?.created_at || !validityCheckSignupSource.includes(signupSource)) return true;

  const expirationTimeinMinutes = 40320; // this is `4 weeks`
  const leadDataCreatedDateIso = DateTime.fromISO(leadData?.created_at);

  const difference = getDateDiffFromToday(leadDataCreatedDateIso, ['minutes']);
  const validLeadData = difference?.minutes <= expirationTimeinMinutes;

  return validLeadData;
};

const createNewLead = (dispatch, createLeadAPI, prepareCreateLeadDataPayload) => () => {
  const payload = prepareCreateLeadDataPayload();
  createLeadAPI(payload).then((leadData) => dispatch(saveLeadDataToStore(leadData)));
};

const removeUnwantedParamsFromUrl = (navigate, pathname, search) => {
  const queryParamString = getQueryParamsString(search);
  const queryParams = extractQueryParamsFromString(queryParamString);

  const { uuid, ex_agency_id: executingAgencyId, ...otherQueryParams } = queryParams;
  const newSearch = createQueryParamsString(otherQueryParams);
  navigate(`${pathname}${newSearch}`, { replace: true });
};

const getLeadDataOrCreateNewLead =
  (dispatch, navigate, pathname, search, getLeadAPI, createANewLead, signupSource) =>
  (uuid, multipleContracts) => {
    getLeadAPI(uuid).then((leadData) => {
      const isValidLeadData = checkLeadDataValidity(signupSource, leadData);

      if (!!leadData && isValidLeadData) {
        dispatch(saveLeadDataToStore(leadData, multipleContracts));
      } else {
        removeUnwantedParamsFromUrl(navigate, pathname, search);
        createANewLead();
      }
    });
  };

const useLeadData = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;

  const multipleContracts = useMultipleContracts();

  const { uuid: uuidFromUrl = null } = extractQueryParams(location);
  const [createLeadAPI, getLeadAPI] = useLeadAPI();

  const dispatch = useDispatch();
  const [getFromSessionStorage] = useSessionStorage();

  const prepareCreateLeadDataPayload = useCreateLeadDataPayload();

  const currentSignupSource = useSignupSource();

  useEffect(() => {
    const createANewLead = createNewLead(dispatch, createLeadAPI, prepareCreateLeadDataPayload);
    const getLeadDataWithTheUuidOrCreateANewLeadIfItFails = getLeadDataOrCreateNewLead(
      dispatch,
      navigate,
      pathname,
      search,
      getLeadAPI,
      createANewLead,
      currentSignupSource
    );

    const offlineLeadData = getFromSessionStorage(LEAD_DATA_SESSION_STORAGE_KEY);
    const uuidSavedOffline = offlineLeadData?.uuid;

    if (uuidSavedOffline) {
      const isValidLeadData = checkLeadDataValidity(currentSignupSource, offlineLeadData);

      if (!uuidFromUrl) {
        // We have uuid saved in session storage, and no uuid supplied in URL, don't refresh data from backend
        dispatch(leadDataReceived({ leadData: offlineLeadData, multipleContracts }));
      } else if (!!uuidFromUrl && uuidFromUrl === uuidSavedOffline && !isValidLeadData) {
        // We have uuid saved in session storage, and uuid supplied in URL is the same as the saved uuid but data uuid has expired, create a new lead
        createANewLead();
      } else if (!!uuidFromUrl && uuidFromUrl === uuidSavedOffline) {
        // We have uuid saved in session storage, and uuid supplied in URL is the same as the saved uuid, don't refresh data from backend
        dispatch(leadDataReceived({ leadData: offlineLeadData, multipleContracts }));
      } else if (!!uuidFromUrl && uuidFromUrl !== uuidSavedOffline) {
        // We have uuid saved in session storage, but uuid supplied in URL is different, refresh data from backend
        getLeadDataWithTheUuidOrCreateANewLeadIfItFails(uuidFromUrl, multipleContracts);
      }
    } else if (uuidFromUrl) {
      // No saved uuid, but it is supplied in the URL
      getLeadDataWithTheUuidOrCreateANewLeadIfItFails(uuidFromUrl, multipleContracts);
    } else {
      // No saved uuid, and it's not supplied in the URL also
      createANewLead();
      // Navigate to the first page since we are creating a new lead
      navigate(`${pathname}${search}`, { replace: true });
    }
  }, [
    dispatch,
    navigate,
    pathname,
    search,
    uuidFromUrl,
    getFromSessionStorage,
    createLeadAPI,
    getLeadAPI,
    prepareCreateLeadDataPayload,
    currentSignupSource,
    multipleContracts,
  ]);

  return null;
};

export default useLeadData;
