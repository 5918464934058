import { PETOLO } from '../../insuranceType/InsuranceTypes';
import useInsuranceType from '../../insuranceType/useInsuranceType';

import usePetoloMultiplePolicyPriceAPI from './usePetoloMultiplePolicyPriceAPI';

const defaultHookFn = () => [];

const useMultiplePolicyPriceAPI = () => {
  const insuranceType = useInsuranceType();

  switch (insuranceType) {
    case PETOLO:
      return usePetoloMultiplePolicyPriceAPI;
    default:
      return defaultHookFn;
  }
};

export default useMultiplePolicyPriceAPI;
