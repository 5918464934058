import { useCallback } from 'react';

const getCookies = () => {
  try {
    return document.cookie;
  } catch (error) {
    return '';
  }
};

export default function useCookie() {
  const getCookie = useCallback((cookieName) => {
    const cookie = getCookies();
    const cookieKeyValues = cookie.split('; ');

    const cookieValue = cookieKeyValues.reduce((result, keyValue) => {
      const [key, value] = keyValue.split('=');
      return key === cookieName ? value : result;
    }, '');

    return cookieValue;
  }, []);

  return [getCookie];
}
