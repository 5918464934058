import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import useS2STrackingAPI from '../../../api/useS2STrackingAPI';
import { extractQueryParams } from '../../../utils/URLHelper';

const useZemantaIdentifier = () => {
  const location = useLocation();
  const [storeAdServiceIdentifierAPI] = useS2STrackingAPI();

  const storeZemantaIdentifier = useCallback(
    (uuid, email) => {
      const { ztaid = null } = extractQueryParams(location);
      if (ztaid) {
        const data = { ztaid };
        storeAdServiceIdentifierAPI(uuid, email, data);
      }
    },
    [location, storeAdServiceIdentifierAPI]
  );

  return storeZemantaIdentifier;
};

export default useZemantaIdentifier;
