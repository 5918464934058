import styled from 'styled-components';
import { applyTheme, palette, MIN_WIDTH_MD, MIN_WIDTH_LG } from '../theme/Theme';
import { THEME } from '../../constants/Constants';

const display = applyTheme({
  [THEME.DENTOLO_THEME]: 'block',
  [THEME.PETOLO_THEME]: 'block',
  [THEME.VITOLO_THEME]: 'block',
  [THEME.DA_DIREKT_THEME]: 'block',
  [THEME.DENTOLO_LEFT_ALIGN_THEME]: 'flex',
});

const backgroundColor = applyTheme({
  [THEME.DENTOLO_THEME]: 'transparent',
  [THEME.PETOLO_THEME]: 'transparent',
  [THEME.VITOLO_THEME]: 'transparent',
  [THEME.DA_DIREKT_THEME]: 'transparent',
  [THEME.DENTOLO_LEFT_ALIGN_THEME]: palette.white,
});

const boxShadow = applyTheme({
  [THEME.DENTOLO_THEME]: 'none',
  [THEME.PETOLO_THEME]: 'none',
  [THEME.VITOLO_THEME]: 'none',
  [THEME.DA_DIREKT_THEME]: 'none',
  [THEME.DENTOLO_LEFT_ALIGN_THEME]: palette.box_shadow_3,
});

const borderRadius = applyTheme({
  [THEME.DENTOLO_THEME]: '0',
  [THEME.PETOLO_THEME]: '0',
  [THEME.VITOLO_THEME]: '0',
  [THEME.DA_DIREKT_THEME]: '0',
  [THEME.DENTOLO_LEFT_ALIGN_THEME]: '8px',
});

const tabletWidth = applyTheme({
  [THEME.DENTOLO_THEME]: 'auto',
  [THEME.PETOLO_THEME]: 'auto',
  [THEME.VITOLO_THEME]: 'auto',
  [THEME.DA_DIREKT_THEME]: '100%',
  [THEME.DENTOLO_LEFT_ALIGN_THEME]: '38em',
});

const desktopWidth = applyTheme({
  [THEME.DENTOLO_THEME]: '100%',
  [THEME.PETOLO_THEME]: '100%',
  [THEME.VITOLO_THEME]: '100%',
  [THEME.DA_DIREKT_THEME]: '100%',
  [THEME.DENTOLO_LEFT_ALIGN_THEME]: '54em',
});

/* eslint-disable import/prefer-default-export */
export const StyledPageOuterContainer = styled.div`
  display: ${display};
  width: 100%;
  margin: 0 auto;
  background: ${backgroundColor};
  box-shadow: ${boxShadow};
  border-radius: ${borderRadius};

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    width: ${tabletWidth};
    margin: 0;
  }

  @media only screen and (min-width: ${MIN_WIDTH_LG}) {
    width: ${desktopWidth};
  }
`;
/* eslint-enable import/prefer-default-export */
