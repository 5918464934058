import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeSchema: null,
  schemaDownloadFailed: false,
  schemaConfig: null,
  schemaConfigDownloaded: false,
  schemaHash: null,
};

const schemaDetails = createSlice({
  name: 'schema',
  initialState,
  reducers: {
    receivedSchema(state, action) {
      const { activeSchema, schemaHash } = action.payload;
      state.activeSchema = activeSchema;
      state.schemaDownloadFailed = false;
      state.schemaHash = schemaHash;
    },
    setSchemaDownloadFailed(state, action) {
      const { schemaDownloadFailed } = action.payload;
      state.schemaDownloadFailed = schemaDownloadFailed;
    },
    receivedSchemaConfig(state, action) {
      const { schemaConfig } = action.payload;
      state.schemaConfig = schemaConfig;
    },
  },
});

export const { receivedSchema, receivedSchemaConfig, setSchemaDownloadFailed } =
  schemaDetails.actions;

export default schemaDetails.reducer;
