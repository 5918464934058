import { useCallback } from 'react';
import { useFetch } from '../hooks/useFetch';
import { STRYKER_BASE_URL, STRYKER_USERNAME, STRYKER_PASSWORD } from '../app/environment';
import useInsuranceCategory from '../features/insuranceCategory/useInsuranceCategory';

const defaultHeaders = {
  'Content-type': 'application/json',
  'Authorization': `Basic ${btoa(`${STRYKER_USERNAME}:${STRYKER_PASSWORD}`)}`,
};

const useS2STrackingAPI = () => {
  const [executeFetchRequest] = useFetch();
  const insuranceCategory = useInsuranceCategory();

  const storeAdServiceIdentifierAPI = useCallback(
    (uuid, email, data) => {
      if (uuid) {
        const storeAdServiceIdentifierUrl = `${STRYKER_BASE_URL}/api/identifiers`;
        const body = JSON.stringify({ uuid, email, insurance_category: insuranceCategory, data });
        const options = {
          method: 'POST',
          headers: defaultHeaders,
          body,
        };
        return executeFetchRequest(storeAdServiceIdentifierUrl, options);
      }
      return Promise.resolve(null);
    },
    [insuranceCategory, executeFetchRequest]
  );

  const trackEventsAPI = useCallback(
    (uuid, events, source, additionalData) => {
      if (uuid) {
        const trackEventsUrl = `${STRYKER_BASE_URL}/api/events/batch`;
        const body = JSON.stringify({
          uuid,
          events,
          source,
          additional_data: additionalData,
        });
        const options = {
          method: 'POST',
          headers: defaultHeaders,
          body,
        };
        return executeFetchRequest(trackEventsUrl, options);
      }
      return Promise.resolve(null);
    },
    [executeFetchRequest]
  );

  return [storeAdServiceIdentifierAPI, trackEventsAPI];
};

export default useS2STrackingAPI;
