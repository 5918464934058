import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { createTheme, ThemeProvider as MaterialUIThemeProvider } from '@mui/material/styles';

import useAppLoad from '../../app/useAppLoad';
import useParameters from './useParameters';
import useFetchSchema from './useFetchSchema';
import useDefaultSchema from './useDefaultSchema';
import useTrackPageLoad from './useTrackPageLoad';
import useFrontendBranchName from '../../hooks/useFrontendBranchName';
import useBackendBranchName from '../../hooks/useBackendBranchName';
import { THEME } from '../../constants/Constants';
import { APP_ENV } from '../../app/environment';

import PagesManager from '../pagesManager/PagesManager';
import LoadingAnimation from '../../components/LoadingAnimation';
import {
  StyledSchemaContainer,
  StyledLoadingAnimationContainer,
  StyledBranchName,
} from './Schema.styled';

function Schema() {
  const { organisation, product, flow, theme: themeFromUrl } = useParameters();

  // Track app load event and add it to dataLayer
  useAppLoad();

  // Fetch schema and schema config
  useFetchSchema(flow, product, organisation);

  // If schema is not found or schema name is not specified, fetch the default schema
  useDefaultSchema(flow);

  const activeSchema = useSelector((state) => state?.schema?.activeSchema);
  const schemaDownloaded = !!activeSchema;

  // Send tracking event once schema is downloaded
  useTrackPageLoad(schemaDownloaded);

  const schemaConfig = useSelector((state) => state?.schema?.schemaConfig);
  const schemaConfigDownloaded = !!schemaConfig;

  const themeFromSchema = useSelector((state) => state?.schema?.activeSchema?.SCHEMA?.theme);
  const theme = themeFromUrl || themeFromSchema || THEME.DENTOLO_THEME;

  const frontendBranchName = useFrontendBranchName();
  const backendBranchName = useBackendBranchName();

  return (
    <MaterialUIThemeProvider theme={createTheme({ tyrionTheme: theme })}>
      <StyledComponentsThemeProvider theme={{ theme }}>
        <StyledSchemaContainer>
          {(!schemaDownloaded || !schemaConfigDownloaded) && (
            <StyledLoadingAnimationContainer>
              <LoadingAnimation />
            </StyledLoadingAnimationContainer>
          )}

          {schemaDownloaded && schemaConfigDownloaded && <PagesManager />}
        </StyledSchemaContainer>

        {APP_ENV === 'beta' && (
          <StyledBranchName>
            {frontendBranchName ? <>Frontend Branch: {frontendBranchName}</> : null}
            <br />
            {backendBranchName ? <>Backend Branch: {backendBranchName}</> : null}
          </StyledBranchName>
        )}
      </StyledComponentsThemeProvider>
    </MaterialUIThemeProvider>
  );
}

export default Schema;
