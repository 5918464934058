import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import useSessionStorage from '../../hooks/useSessionStorage';
import {
  SUCCESS_PAGE_IDS,
  LEAD_DATA_SESSION_STORAGE_KEY,
  QUESTION_ANSWERS_SESSION_STORAGE_KEY,
} from '../../constants/Constants';
import { clearAllErrorsOnPage } from '../errorValidation/errorValidationSlice';
import { extractQueryParams, createQueryParamsString } from '../../utils/URLHelper';

const mergeQueryParams = (location, newQueryParamsObject) => {
  if (newQueryParamsObject) {
    const exisitngQueryParams = extractQueryParams(location);
    const newQueryParams = { ...exisitngQueryParams, ...newQueryParamsObject };
    const newQueryParamsString = createQueryParamsString(newQueryParams);
    return newQueryParamsString;
  }
  return location?.search || '';
};

const useUpdateRoute = (pages) => {
  const dispatch = useDispatch();

  const [getFromSessionStorage, saveToSessionStorage, removeFromSessionStorage] =
    useSessionStorage();

  const validatedPages = Array.isArray(pages) ? pages : [];

  const navigate = useNavigate();

  const location = useLocation();
  const { hash } = location;
  const pageId = hash ? hash.substring(1) : null;
  const pageIndex = validatedPages.findIndex((page) => page?.pageId === pageId);
  const isInvalidPageId = pageIndex === -1;
  const pageNumber = isInvalidPageId ? 0 : pageIndex;

  const isSuccessPage = SUCCESS_PAGE_IDS.includes(pageId);
  const showFirstPage = pageId ? isSuccessPage : true;

  const initialPageNumber = showFirstPage ? 0 : pageNumber;
  const validatedInitialPageNumber = initialPageNumber === -1 ? 0 : initialPageNumber;

  const firstPage = validatedPages[0];
  const firstPageId = firstPage?.pageId;

  const [currentPageNumber, setCurrentPageNumber] = useState(validatedInitialPageNumber);

  useEffect(() => {
    if (!pageId || isInvalidPageId) {
      // When the app initially loads without any hash in the url
      // Or when an invalid page id is specified
      navigate(`${location.pathname}${location.search}#${firstPageId}`, { replace: true });
    } else {
      // Whenever the hash changes, set the corresponding page number to state
      dispatch(clearAllErrorsOnPage());
      setCurrentPageNumber(pageNumber);
    }
  }, [
    dispatch,
    navigate,
    location.pathname,
    location.search,
    pageId,
    isInvalidPageId,
    firstPageId,
    pageNumber,
    getFromSessionStorage,
    saveToSessionStorage,
  ]);

  // If user refreshes on success page, clear stored lead data
  useEffect(() => {
    if (isSuccessPage && currentPageNumber === 0) {
      removeFromSessionStorage(LEAD_DATA_SESSION_STORAGE_KEY);
      removeFromSessionStorage(QUESTION_ANSWERS_SESSION_STORAGE_KEY);
    }
  }, [currentPageNumber, isSuccessPage, removeFromSessionStorage]);

  const currentPage =
    currentPageNumber < validatedPages.length ? validatedPages[`${currentPageNumber}`] : null;

  const setCurrentPage = (newPageId, queryParams) => {
    navigate(`${location.pathname}${mergeQueryParams(location, queryParams)}#${newPageId}`);
  };

  return [currentPage, setCurrentPage, setCurrentPageNumber];
};

export default useUpdateRoute;
