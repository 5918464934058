import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import useWindow from '../../../hooks/useWindow';
import useS2STrackingAPI from '../../../api/useS2STrackingAPI';
import { extractQueryParams } from '../../../utils/URLHelper';

const extractFromCookieAndTrack = (uuid, email, storeAdServiceIdentifierAPI) => (tracker) => {
  const gclid = tracker.get('_gclid');
  if (gclid) {
    const data = { gclid };
    storeAdServiceIdentifierAPI(uuid, email, data);
  }
};

const useGoogleAnalyticsIdentifier = () => {
  const location = useLocation();
  const [getFromWindow] = useWindow();
  const [storeAdServiceIdentifierAPI] = useS2STrackingAPI();

  const storeGoogleAnalyticsIdentifier = useCallback(
    (uuid, email) => {
      const { gclid = null } = extractQueryParams(location);
      if (gclid) {
        const data = { gclid };
        storeAdServiceIdentifierAPI(uuid, email, data);
      } else {
        const ga = getFromWindow('ga');
        if (typeof ga === 'function') {
          // Function "ga" will wait until Google Analytics script is loaded, then execute readyCallback
          ga(extractFromCookieAndTrack(uuid, email, storeAdServiceIdentifierAPI));
        }
      }
    },
    [location, getFromWindow, storeAdServiceIdentifierAPI]
  );

  return storeGoogleAnalyticsIdentifier;
};

export default useGoogleAnalyticsIdentifier;
