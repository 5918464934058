import { DateTime } from 'luxon';

import { padStart } from '../GeneralHelper';
import { getLocale } from '../../locales/locale.helper';

export const getISODateString = (day, month, year) => {
  if (!!day && !!month && !!year) {
    return `${padStart(year, 4, '0')}-${padStart(month, 2, '0')}-${padStart(day, 2, '0')}`;
  }
  return '';
};

export const getMonthNameInLocale = (monthIndex) => {
  const locale = getLocale();

  return Intl.DateTimeFormat(locale, { month: 'long' }).format(new Date(2000, monthIndex));
};

export const toReadableDateString = (dateTime) => {
  const monthIndex = dateTime.get('month') - 1;
  const translatedMonthName = getMonthNameInLocale(monthIndex);

  return `${dateTime.get('day')}. ${translatedMonthName} ${dateTime.get('year')}`;
};

export const getAgeInYears = (dateOfBirthString) => {
  if (dateOfBirthString) {
    const dateOfBirth = DateTime.fromISO(dateOfBirthString);
    const currentTime = DateTime.local();
    const difference = currentTime.diff(dateOfBirth, 'years').toObject();
    const age = difference.years;
    return age;
  }
  return 0;
};

export const getAgeInMonthsAndYears = (dateOfBirthString) => {
  if (dateOfBirthString) {
    const dateOfBirth = DateTime.fromISO(dateOfBirthString);
    const currentDate = DateTime.local().startOf('day');
    const difference = currentDate.diff(dateOfBirth, ['years', 'months', 'days']).toObject();
    const { years, months } = difference;
    return { years, months };
  }
  return null;
};

export const getAgeInDays = (dateOfBirthString) => {
  if (dateOfBirthString) {
    const dateOfBirth = DateTime.fromISO(dateOfBirthString);
    const currentDate = DateTime.local().startOf('day');
    const difference = currentDate.diff(dateOfBirth, 'days').toObject();

    return difference?.days;
  }

  return null;
};

export const LESS_THAN_MINIMUM_AGE = 'LESS_THAN_MINIMUM_AGE';
export const GREATER_THAN_MAXIMUM_AGE = 'GREATER_THAN_MAXIMUM_AGE';
export const ACCEPTABLE_AGE_VALUE = 'ACCEPTABLE_AGE_VALUE';
export const INVALID_AGE_VALUE = 'INVALID_AGE_VALUE';

export const isOfEligibleAge = (age, minAge, maxAge) => {
  if (Number.isFinite(age) && Number.isInteger(minAge) && Number.isInteger(maxAge)) {
    if (age >= minAge && age <= maxAge) {
      return ACCEPTABLE_AGE_VALUE;
    }
    if (age < minAge) {
      return LESS_THAN_MINIMUM_AGE;
    }
    return GREATER_THAN_MAXIMUM_AGE;
  }
  return INVALID_AGE_VALUE;
};

export const validateAgeInYears = (minAgeInYears, maxAgeInYears, dateOfBirthString) => {
  const ageInYears = getAgeInYears(dateOfBirthString);
  const isValidAge = isOfEligibleAge(ageInYears, minAgeInYears, maxAgeInYears);
  return isValidAge;
};

export const validateAgeInMonths = (minAgeInMonths, maxAgeInMonths, dateOfBirthString) => {
  const { years, months } = getAgeInMonthsAndYears(dateOfBirthString) || {};

  if ((!years && years !== 0) || (!months && months !== 0)) {
    return INVALID_AGE_VALUE;
  }

  const ageInMonths = (months || 0) + (years || 0) * 12;
  const isValidAge = isOfEligibleAge(ageInMonths, minAgeInMonths, maxAgeInMonths);

  return isValidAge;
};

export const validateAgeInDays = (minAgeInDays, maxAgeInDays, dateOfBirthString) => {
  const ageInDays = getAgeInDays(dateOfBirthString);

  if (ageInDays) {
    const isValidAge = isOfEligibleAge(ageInDays, minAgeInDays, maxAgeInDays);

    return isValidAge;
  }

  return INVALID_AGE_VALUE;
};

export const getDateDiffFromToday = (date, unit) => {
  const today = DateTime.now();

  const difference = today.diff(date, unit)?.toObject() || {};

  return difference;
};

export const getAgeDisplayText = (
  dateOfBirthString,
  yearSuffix,
  yearsSuffix,
  monthSuffix,
  monthsSuffix
) => {
  if (dateOfBirthString) {
    const { years, months } = getAgeInMonthsAndYears(dateOfBirthString);
    const yearsDisplayText = years > 0 ? `${years} ${years === 1 ? yearSuffix : yearsSuffix} ` : '';
    const monthsDisplayText =
      months > 0 ? `${months} ${months === 1 ? monthSuffix : monthsSuffix} ` : '';
    return `${yearsDisplayText}${monthsDisplayText}`;
  }
  return '';
};
