import { useDispatch, useSelector } from 'react-redux';
import useLeadAPI from '../../api/useLeadAPI';
import { saveLeadDataToStore } from './leadSlice';
import { setBackendErrorOnPage } from '../errorValidation/errorValidationSlice';
import useUpdateLeadDataPayload from './useUpdateLeadDataPayload';
import { CUSTOMER_ALREADY_CREATED_ERROR } from '../../constants/Constants';

const useUpdateLeadData = (shouldCreateLead) => {
  const dispatch = useDispatch();
  const [, , /* createLeadAPI */ /* getLeadAPI */ updateLeadAPI] = useLeadAPI();

  const minimizeApiRequests = useSelector(
    (state) => state?.schema?.schemaConfig?.minimizeApiRequests
  );

  const prepareUpdateLeadDataPayload = useUpdateLeadDataPayload();

  const updateLeadData = async (webhooks = [], emailTriggers = []) => {
    // Update lead when explicitly mentioned in the page schema or when minimizeApiRequests is not enabled
    if (!!shouldCreateLead || !minimizeApiRequests) {
      const prepraredLeadData = prepareUpdateLeadDataPayload(webhooks, emailTriggers);
      const updatedLeadDataResponse = await updateLeadAPI(prepraredLeadData);
      const status = updatedLeadDataResponse?.status;
      const updatedLeadData = updatedLeadDataResponse?.lead;
      const errors = updatedLeadDataResponse?.errors;

      if (status === 'success' && !!updatedLeadData) {
        dispatch(saveLeadDataToStore(updatedLeadData));
        return updatedLeadData;
      }
      if (status === 'fail' && !!errors) {
        if (typeof errors === 'string' && errors === CUSTOMER_ALREADY_CREATED_ERROR) {
          // We ignore the error if customer is already created
          return {};
        }
        const erroredFieldNames = Object.keys(errors);
        dispatch(setBackendErrorOnPage({ fields: erroredFieldNames }));
        return null;
      }
      return null;
    }
    return {};
  };

  return updateLeadData;
};

export default useUpdateLeadData;
