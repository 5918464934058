import { datadogLogs } from '@datadog/browser-logs';

import { extractQueryParamsFromString } from '../utils/URLHelper';
import { getProductName } from '../utils/ProductHelper';
import {
  TYRION_GLOBAL_PARAMETERS_KEY,
  TYRION_GLOBAL_PARAMETERS_PRODUCT_KEY,
} from '../constants/Constants';
import { DEFAULT_INSURANCE_TYPE, INSURANCE_TYPES } from '../features/insuranceType/InsuranceTypes';
import {
  APP_ENV,
  DATADOG_CLIENT_TOKEN,
  DATADOG_ENV,
  DATADOG_SERVICE,
  DATADOG_SITE,
  NODE_ENV,
} from '../app/environment';

// eslint-disable-next-line import/prefer-default-export
export const logWindowErrorToDatadog = (message, url, _, __, error) => {
  // This checks whether the error is caused by a js file that's inside the /static/js folder
  const errorOccuredInsideTyrion = /.*\/static\/js\/[0-9]+.chunk.js$/g.test(url);

  if (!errorOccuredInsideTyrion) return;

  const nameToUse = `TyrionError${message ? ` - ${message}` : ''}`;
  const errorDetails = { url, message, error };

  // log error to datadog
  datadogLogs.logger.error(nameToUse, { errorDetails });
};

const search = window?.location?.search || '';
const queryParamsString = search.length && search[0] === '?' ? search.substring(1) : '';

const { product: productFromUrl } = extractQueryParamsFromString(queryParamsString);
const productFromPage =
  window?.[`${TYRION_GLOBAL_PARAMETERS_KEY}`]?.[`${TYRION_GLOBAL_PARAMETERS_PRODUCT_KEY}`];

const productLabel = productFromUrl || productFromPage;
const product = getProductName(productLabel);

const insuranceType =
  !!product && INSURANCE_TYPES.includes(product) ? product : DEFAULT_INSURANCE_TYPE;

if ((APP_ENV === 'beta' || NODE_ENV === 'production') && DATADOG_CLIENT_TOKEN) {
  // # init Datadog Logs
  datadogLogs.init({
    site: DATADOG_SITE,
    service: DATADOG_SERVICE,
    env: DATADOG_ENV || NODE_ENV,
    clientToken: DATADOG_CLIENT_TOKEN,
    forwardReports: 'all', // ['intervention', 'csp_violation', 'deprecation']
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['error', 'warn'],
    sessionSampleRate: 100,
  });

  datadogLogs.setGlobalContext({ product: insuranceType });
}
