import { useSelector } from 'react-redux';

const useMultipleContracts = () => {
  const isMultipleContracts = useSelector(
    (state) => state?.schema?.activeSchema?.SCHEMA?.multipleContracts
  );

  return isMultipleContracts === true;
};

export default useMultipleContracts;
