import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { applyTheme, palette } from '../features/theme/Theme';
import { THEME } from '../constants/Constants';
import { fontP } from '../features/theme/typography';
import { SCHEMA_LOADING_TEXT } from '../constants/DefaultValues';

const background1 = (props) =>
  applyTheme({
    [THEME.DENTOLO_THEME]: palette.dark_green,
    [THEME.PETOLO_THEME]: palette.dark_orange,
    [THEME.DA_DIREKT_THEME]: palette.dark_orange,
    [THEME.VITOLO_THEME]: palette.dusty_blue,
  })(props);

const background2 = (props) =>
  applyTheme({
    [THEME.DENTOLO_THEME]: palette.vitalized_green,
    [THEME.PETOLO_THEME]: palette.vitalized_orange,
    [THEME.DA_DIREKT_THEME]: palette.da_direkt_bright_orange,
    [THEME.VITOLO_THEME]: palette.vibrant_light_violet,
  })(props);

const bounce = (bg1, bg2) => keyframes`
  0%, 100% {
    transform: scale(0);
    background: ${bg1};
  }

  50% {
    transform: scale(1);
    background: ${bg2};
  }
`;

const StyledLoadingContainer = styled.div`
  width: 4em;
  height: 4em;
  position: absolute;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const StyledLoadingCircle1 = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  opacity: 0.6;
  animation: ${(props) => bounce(background1(props), background2(props))} 2s infinite ease-in-out;
`;

const StyledLoadingCircle2 = styled(StyledLoadingCircle1)`
  animation-delay: -1s;
`;

const StyledLoadingText = styled.span`
  position: absolute;
  width: 100%;
  margin: 4em auto 0;
  top: 50%;
  left: 50%;
  ${fontP};
  color: ${palette.gray};
  transform: translate(-50%, -50%);
  text-align: center;
`;

function LoadingAnimation() {
  return (
    <>
      <StyledLoadingContainer>
        <StyledLoadingCircle1 />
        <StyledLoadingCircle2 />
      </StyledLoadingContainer>

      <StyledLoadingText>
        <FormattedMessage id={SCHEMA_LOADING_TEXT} />
      </StyledLoadingText>
    </>
  );
}

export default LoadingAnimation;
