import { useEffect, useState } from 'react';
import { APP_ENV, PUBLIC_URL } from '../app/environment';

const useFrontendBranchName = () => {
  const [frontendBranchName, setFrontendBranchName] = useState('');

  useEffect(() => {
    if (APP_ENV === 'beta') {
      fetch(`${PUBLIC_URL}/branch-info.json`)
        .then((response) => response.json())
        .then((json) => {
          const deployedBranchName = json?.branch_name;
          if (deployedBranchName) {
            setFrontendBranchName(deployedBranchName);
          }
        })
        .catch(() => null);
    }
  }, []);

  return frontendBranchName;
};

export default useFrontendBranchName;
