import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';

import useLeadData from '../customer/useLeadData';
import useCustomerGenerated from './useCustomerGenerated';
import useUpdateRoute from './useUpdateRoute';
import useOfflineLeadData from '../customer/useOfflineLeadData';
import useScrollToTopOfPage from './useScrollToTopOfPage';
import useLeadDataSynchronizer from '../customer/useLeadDataSynchronizer';
import useAdServiceIdentifiers from '../tracking/useAdServiceIdentifiers';
import useOfflineQuestionAnswers from '../questions/useOfflineQuestionAnswers';
import { useDataDogContext } from '../../hooks/useDataDog';

import Page from '../page/Page';
import SkeletonPage from '../../components/SkeletonPage';
import { StyledPageOuterContainer } from './PagesManager.styled';

function PagesManager() {
  const pages = useSelector((state) => state?.schema?.activeSchema?.SCHEMA?.pages);
  const thumbImage = useSelector((state) => state?.schema?.activeSchema?.SCHEMA?.thumbImage);
  const schemaLevelFlagScrollToTopOfPage = useSelector(
    (state) => state?.schema?.activeSchema?.SCHEMA?.shouldScrollToTopOfPage
  );

  // Set initial page and manage routing and page navigation
  const [currentPage, setCurrentPage, setCurrentPageNumber] = useUpdateRoute(pages);
  const { pageId, shouldScrollToTopOfPage: pageLevelFlagScrollToTopOfPage } = currentPage || {};

  // hook contains the effects that need to be called when widget value change
  useLeadDataSynchronizer();

  // Create lead, get existing lead or restore lead data from sessionStorage to store
  useLeadData();

  // Save lead data from store to session storage
  useOfflineLeadData();

  // Create new lead data if customer is already generated for existing lead
  useCustomerGenerated(setCurrentPageNumber);

  // Store ad service identifiers that'll be used server-to-server tracking
  useAdServiceIdentifiers();

  // // update DataDog context
  useDataDogContext(pageId);

  // Save question answers and webhooks data to session storage
  useOfflineQuestionAnswers();

  const pageLevelOverrideForScrollToTopOfPage = typeof pageLevelFlagScrollToTopOfPage === 'boolean';
  const shouldScrollToTopOfPage = pageLevelOverrideForScrollToTopOfPage
    ? pageLevelFlagScrollToTopOfPage
    : !!schemaLevelFlagScrollToTopOfPage;
  useScrollToTopOfPage(pageId, shouldScrollToTopOfPage);

  return (
    <StyledPageOuterContainer role="main">
      <Suspense fallback={<SkeletonPage thumbImage={thumbImage} />}>
        {!!currentPage && (
          <Page
            pages={pages}
            currentPage={currentPage}
            thumbImage={thumbImage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </Suspense>
    </StyledPageOuterContainer>
  );
}

export default PagesManager;
