import useWindow from '../../hooks/useWindow';
import useSurveyId from '../schema/useSurveyId';

const useFacebookTracking = (page) => {
  const [getFromWindow] = useWindow();
  const surveyId = useSurveyId();
  const pageId = page?.pageId;

  const customEvent = page?.fbCustomEvent;
  const standardEvents = page?.fbStandardEvents;

  const trackCustomEvent = (eventName) => {
    const fbq = getFromWindow('fbq');
    if (typeof fbq === 'function') {
      fbq('trackCustom', eventName);
    }
  };

  const trackStandardEvent = (eventName, eventParams) => {
    const fbq = getFromWindow('fbq');
    if (typeof fbq === 'function') {
      fbq('track', eventName, eventParams);
    }
  };

  const trackFacebookEvents = () => {
    if (!!surveyId && !!pageId) {
      trackCustomEvent(`${surveyId}-${pageId}`);
    }

    if (customEvent) {
      trackCustomEvent(customEvent);
    }

    if (Array.isArray(standardEvents)) {
      standardEvents.forEach((standardEvent) => {
        const eventName = standardEvent?.name;
        const eventParams = standardEvent?.parameters;
        if (!!eventName && !!eventParams) {
          trackStandardEvent(eventName, eventParams);
        }
      });
    }

    return Promise.resolve(null);
  };

  return trackFacebookEvents;
};

export default useFacebookTracking;
