import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useSessionStorage from '../../hooks/useSessionStorage';
import { LEAD_DATA_SESSION_STORAGE_KEY } from '../../constants/Constants';

const useOfflineLeadData = () => {
  const leadData = useSelector((state) => state?.lead?.leadData);

  const [, saveToSessionStorage] = useSessionStorage();

  useEffect(() => {
    if (leadData?.uuid) {
      saveToSessionStorage(LEAD_DATA_SESSION_STORAGE_KEY, leadData);
    }
  }, [leadData, saveToSessionStorage]);
};

export default useOfflineLeadData;
