import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { safeParseInt } from '../../utils/GeneralHelper';
import { extractQueryParams } from '../../utils/URLHelper';
import { DEFAULT_POLICY_CATEGORY } from '../../constants/DefaultValues';
import useWindow from '../../hooks/useWindow';
import useMultipleContracts from '../schema/useMultipleContracts';
import {
  TYRION_GLOBAL_PARAMETERS_KEY,
  TYRION_GLOBAL_PARAMETERS_POLICY_CATEGORY_KEY,
} from '../../constants/Constants';

const usePolicyCategory = () => {
  const location = useLocation();
  const isMultipleContracts = useMultipleContracts();

  const { policy_category: policyCategoryFromUrl = null } = extractQueryParams(location);

  const [getFromWindow] = useWindow();
  const tyrionParamsFromPage = getFromWindow(TYRION_GLOBAL_PARAMETERS_KEY);
  const policyCategoryFromPage =
    tyrionParamsFromPage?.[`${TYRION_GLOBAL_PARAMETERS_POLICY_CATEGORY_KEY}`];

  // #TODO: getting `policy_category` from the first contract as it is same for all the contracts for now.
  const policyCategoryFromLeadData = useSelector((state) =>
    isMultipleContracts
      ? state?.lead?.leadData?.contracts?.[0]?.policy_category
      : state?.lead?.leadData?.contract?.policy_category
  );

  const suppliedPolicyCategory =
    policyCategoryFromUrl || policyCategoryFromPage || policyCategoryFromLeadData;

  const defaultPolicyCategory = useSelector(
    (state) => state?.schema?.activeSchema?.SCHEMA?.default_policy_category
  );

  const availablePolicyCategory = safeParseInt(suppliedPolicyCategory, defaultPolicyCategory);

  const schemaPolicyCategory = useSelector(
    (state) => state?.schema?.activeSchema?.SCHEMA?.policy_category
  );

  return schemaPolicyCategory || availablePolicyCategory || DEFAULT_POLICY_CATEGORY;
};

export default usePolicyCategory;
