import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useWindow from '../../hooks/useWindow';
import { extractQueryParams } from '../../utils/URLHelper';
import { DEFAULT_SIGN_UP_SOURCE } from '../../constants/DefaultValues';
import {
  TYRION_GLOBAL_PARAMETERS_KEY,
  TYRION_GLOBAL_PARAMETERS_SIGN_UP_SOURCE_KEY,
} from '../../constants/Constants';

const useSignupSource = () => {
  const [getFromWindow] = useWindow();
  const tyrionParamsFromPage = getFromWindow(TYRION_GLOBAL_PARAMETERS_KEY);
  const signupSourceFromPage =
    tyrionParamsFromPage?.[`${TYRION_GLOBAL_PARAMETERS_SIGN_UP_SOURCE_KEY}`];

  const location = useLocation();
  const { sign_up_source: signupSourceFromUrl } = extractQueryParams(location);

  const signupSourceFromSchema = useSelector(
    (state) => state?.schema?.activeSchema?.SCHEMA?.sign_up_source
  );

  const signupSource =
    signupSourceFromPage || signupSourceFromUrl || signupSourceFromSchema || DEFAULT_SIGN_UP_SOURCE;
  return signupSource;
};

export default useSignupSource;
