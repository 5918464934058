import { createSlice } from '@reduxjs/toolkit';

const questionsDetails = createSlice({
  name: 'questions',
  initialState: {
    answers: {},
  },
  reducers: {
    answeredQuestion(state, action) {
      const { pageId, questionId, question, answer } = action.payload;

      const newAnswerObj = {
        questionId: questionId || state.answers?.[`${pageId}`]?.questionId || '',
        question: question || state.answers?.[`${pageId}`]?.question || '',
        answer: answer ?? state.answers?.[`${pageId}`]?.answer ?? '',
      };

      if (pageId) {
        state.answers[`${pageId}`] = newAnswerObj;
      }
    },
    restoreQuestionAnswers(state, action) {
      const { answers } = action.payload;
      if (answers) {
        state.answers = answers;
      }
    },
  },
});

export const { answeredQuestion, restoreQuestionAnswers } = questionsDetails.actions;

export default questionsDetails.reducer;
