import 'whatwg-fetch';
import { useCallback } from 'react';

import useConsole from './useConsole';
import { useDataDog } from './useDataDog';
import { promiseWithRetry, safeParseJSON } from '../utils/GeneralHelper';
import { getErrorMessages } from '../utils/LoggerHelper/LoggerHelper';
import { getLocale, HEADER_API_GETOLO_LOCALE } from '../locales/locale.helper';
import { STRYKER_BASE_URL, SURVEY_BASE_URL } from '../app/environment';

// list of urls to skip adding locale header
const skipLocaleHeaderUrl = [STRYKER_BASE_URL, SURVEY_BASE_URL];

const notifyError = (name, errorDetails, notifyErrorToService, consoleError) => {
  notifyErrorToService(name, errorDetails);
  consoleError(errorDetails);
};

const notifyAPIError = (url, options, response, notifyErrorToService, consoleError) => {
  response
    .text()
    .then((responseBody) => {
      const errorName = 'FetchAPIResponseError';
      const errorMessages = getErrorMessages(response);
      const errorDetails = {
        url,
        options,
        response: responseBody,
        ...errorMessages,
      };

      notifyError(errorName, errorDetails, notifyErrorToService, consoleError);
    })
    .catch((error) => {
      const errorName = 'ErrorOnFetchAPIResponseError';
      const errorMessages = getErrorMessages(error);
      const errorDetails = {
        url,
        options,
        ...errorMessages,
      };

      notifyError(errorName, errorDetails, notifyErrorToService, consoleError);
    });
};

const handleAPIResponseError = (url, options, notifyErrorToService, consoleError) => (response) => {
  if (response?.ok) {
    return response;
  }

  notifyAPIError(url, options, response, notifyErrorToService, consoleError);
  return null;
};

const executeFetch = (url, options, notifyErrorToService, consoleError) => {
  const locale = getLocale();

  const shouldSkipHeader = skipLocaleHeaderUrl.some((urlToSkip) => url.includes(urlToSkip));

  // update request option with locale header
  const updatedOptions = shouldSkipHeader
    ? { ...options } // Don't modify headers if the URL is in the skip list
    : {
        ...options,
        headers: {
          ...options?.headers,
          [HEADER_API_GETOLO_LOCALE]: locale,
        },
      };

  const request = new Request(url, updatedOptions);

  return fetch(request)
    .then(handleAPIResponseError(url, options, notifyErrorToService, consoleError))
    .then(async (response) => {
      if (response) {
        const responseBody = await response.text();
        if (responseBody) {
          return safeParseJSON(responseBody);
        }
        return null;
      }
      return null;
    });
};

const handleFetchError = (url, options, notifyErrorToService, consoleError, error, retried) => {
  const errorName = `FetchAPIError${retried ? ' WithRetry' : ''}`;

  const errorMessages = getErrorMessages(error);
  const errorDetails = {
    url,
    options,
    ...errorMessages,
  };

  notifyError(errorName, errorDetails, notifyErrorToService, consoleError);

  return null;
};

export const executeFetchAndHandleError = (url, options, notifyErrorToService, consoleError) => {
  return executeFetch(url, options, notifyErrorToService, consoleError).catch((error) =>
    handleFetchError(url, options, notifyErrorToService, consoleError, error, false)
  );
};

export const useFetch = () => {
  const { notifyErrorToDataDog } = useDataDog();
  const [consoleError] = useConsole();

  const executeFetchRequest = useCallback(
    (url, options) => {
      return executeFetchAndHandleError(url, options, notifyErrorToDataDog, consoleError);
    },
    [consoleError, notifyErrorToDataDog]
  );

  const executeFetchRequestWithRetry = useCallback(
    (url, options) => {
      return promiseWithRetry(() =>
        executeFetch(url, options, notifyErrorToDataDog, consoleError)
      ).catch((error) =>
        handleFetchError(url, options, notifyErrorToDataDog, consoleError, error, true)
      );
    },
    [consoleError, notifyErrorToDataDog]
  );

  return [executeFetchRequest, executeFetchRequestWithRetry];
};
