import { createSlice } from '@reduxjs/toolkit';

const errorValidation = createSlice({
  name: 'errorValidation',
  initialState: {
    // TODO: Tech debt - make this an Array
    widgetErrors: {},
    backendErrors: [],
  },
  reducers: {
    setErrorOnPage(state, action) {
      const { field } = action.payload;
      state.widgetErrors[`${field}`] = true;
    },
    setBackendErrorOnPage(state, action) {
      const { fields } = action.payload;
      if (Array.isArray(fields)) {
        state.backendErrors = [...state.backendErrors, ...fields];
      }
    },
    clearErrorOnPage(state, action) {
      const { field } = action.payload;
      const { [field]: _, ...otherWidgetErrors } = state.widgetErrors;
      const otherBackendErrors = state.backendErrors.filter(
        (backendError) => backendError !== field
      );

      state.widgetErrors = otherWidgetErrors;
      state.backendErrors = otherBackendErrors;
    },
    clearErrorsOnPage(state, action) {
      const { fields } = action.payload;
      if (Array.isArray(fields)) {
        const otherWidgetErrors = fields.reduce((agg, next) => {
          const { [next]: _, ...rest } = agg;
          return rest;
        }, state.widgetErrors);

        state.widgetErrors = otherWidgetErrors;
      }
    },
    clearAllErrorsOnPage(state) {
      state.widgetErrors = {};
      state.backendErrors = [];
    },
    clearErrorsOnDeleteContract(state, action) {
      const { fields, contractIdx } = action.payload;

      let otherWidgetErrors = state.widgetErrors;
      const updatedWidgetErrors = {};

      if (!Array.isArray(fields)) return;

      otherWidgetErrors = fields.reduce((agg, next) => {
        const { [next]: _, ...rest } = agg;
        return rest;
      }, state.widgetErrors);

      Object.keys(otherWidgetErrors).forEach((error) => {
        const s = error.replace(/\d+/g, (match) =>
          Number(match) >= 1 && Number(match) >= contractIdx ? match - 1 : match
        );

        updatedWidgetErrors[`${s}`] = otherWidgetErrors[`${error}`];
      });

      state.widgetErrors =
        Object.keys(updatedWidgetErrors)?.length > 0 ? updatedWidgetErrors : otherWidgetErrors;
    },
  },
});

export const {
  setErrorOnPage,
  setBackendErrorOnPage,
  clearErrorOnPage,
  clearErrorsOnPage,
  clearAllErrorsOnPage,
  clearErrorsOnDeleteContract,
} = errorValidation.actions;

export default errorValidation.reducer;
