import theme from 'styled-theming';

import { THEME } from '../../constants/Constants';

// Breakpoints were chosen according to this guideline by Webflow which we use to host Tyrion application
// https://university.webflow.com/lesson/intro-to-breakpoints
export const MAX_WIDTH_XS = '479px';
export const MIN_WIDTH_SM = '480px';
export const MAX_WIDTH_SM = '768px';
export const MIN_WIDTH_MD = '769px';
export const MAX_WIDTH_MD = '991px';
export const MIN_WIDTH_LG = '992px';

const colors = {
  black: '#000',
  clear_light_blue: '#dff1fd',
  clear_light_green: '#eafff4',
  clear_light_orange: '#fff4d9',
  clear_light_violet: '#f5f2ff',
  dusty_blue: '#6675b1',
  error_red: '#c91d1d',
  focus_state_blue: '#1398eb',
  gray: '#666',
  lavender_indigo: '#956cff',
  light_gray: '#e7e7e8',
  light_peach: '#fed5b7',
  light_spring_green: '#a5ffd2',
  lovely_rose: '#fac8d2',
  periwinkle: '#d1cdff',
  product_blue: '#7c95ae',
  professional_blue: '#003264',
  professional_light_blue: '#335b83',
  spring_green: '#3af396',
  success_green: '#448000',
  ultra_clear_light_blue: '#f3f9fe',
  ultra_clear_light_violet: '#fcfbff',
  ultra_light_gray: '#f7f7f7',
  ultra_light_peach: '#feeadb',
  vibrant_light_violet: '#cbb7ff',
  vitalized_green: '#32ff96',
  vitalized_orange: '#ffa445',
  white: '#fff',

  // da-direkt colors
  da_direkt_background_1: '#f2f5f7',
  da_direkt_bright_light_blue: '#1498eb',
  da_direkt_bright_orange: '#ff5e2e', // rgb: `255, 94, 46`
  da_direkt_bright_orange_opacity: 'rgba(255, 94, 46, 0.8)', // `da_direkt_bright_orange` with opacity
  da_direkt_bright_orange_low_opacity: 'rgba(255, 94, 46, 0.3)', // `da_direkt_bright_orange` with low opacity
  da_direkt_color_1: '#bfccd8', // Light gray blue
  da_direkt_error_red: '#b00020',
  da_direkt_error_red_2: '#ff0000',
  da_direkt_green: '#22bd6e',

  // fressnapf colors
  color_forest_green: '#196428',
  color_grey_b: '#b2b2b2',
  color_greyish_black: '#464646',
  color_light_orange: '#f7ae83',
  color_yellow_light: '#fafcf3',
  color_yellowish_green: '#819F17',
  color_yellowish_green_light: '#c8d86b',

  // misc colors
  dark_green: '#0a4c2a',
  dark_orange: '#ed7800',

  // old colors
  error_color_2: '#e15241', // Faded red - used in combined review page
};

export const palette = {
  ...colors,
  font_family_1: '"Circular TT",Arial',
  font_family_2: 'CentraNo2-Medium,fallback-font,sans-serif',
  font_family_skolar_sans: '"SkolarSans",sans-serif',
  box_shadow_1: `0 0 0.25em 0.0625em ${colors.light_gray}`,
  box_shadow_2: `0 0 0.25em 0.0625em ${colors.vitalized_green}`,
  box_shadow_3: `0 0 1em 0 rgba(0, 0, 0, 0.1)`,
  box_shadow_4: `0 0 0.625em 0 rgba(0, 0, 0, 0.1)`,
  box_shadow_green: `0 0 0.25em 0.0625em ${colors.color_yellowish_green_light}`,
  filter_drop_shadow_4: `0 0 0.315em rgba(0, 0, 0, 0.1)`,
  box_shadow_5: `0 0 0.25em 0.0625em ${colors.vibrant_light_violet}`,
};

export const applyTheme = (themeObject) => (props) => {
  const defaultThemeValue = themeObject?.[THEME.DENTOLO_THEME];

  // Default theme value is always required
  if (defaultThemeValue) {
    return theme('theme', {
      [THEME.DENTOLO_THEME]: themeObject?.[THEME.DENTOLO_THEME] || defaultThemeValue,
      [THEME.PETOLO_THEME]: themeObject?.[THEME.PETOLO_THEME] || defaultThemeValue,
      [THEME.DA_DIREKT_THEME]: themeObject?.[THEME.DA_DIREKT_THEME] || defaultThemeValue,
      [THEME.DENTOLO_LEFT_ALIGN_THEME]:
        themeObject?.[THEME.DENTOLO_LEFT_ALIGN_THEME] || defaultThemeValue,
      [THEME.FRESSNAPF_THEME]: themeObject?.[THEME.FRESSNAPF_THEME] || defaultThemeValue,
      [THEME.VITOLO_THEME]: themeObject?.[THEME.VITOLO_THEME] || defaultThemeValue,
    })(props);
  }
  return '';
};

export const fontFamilyDefaultSet = applyTheme({
  [THEME.DENTOLO_THEME]: palette.font_family_1,
  [THEME.PETOLO_THEME]: palette.font_family_1,
  [THEME.VITOLO_THEME]: palette.font_family_1,
  [THEME.DA_DIREKT_THEME]: palette.font_family_2,
  [THEME.FRESSNAPF_THEME]: palette.font_family_skolar_sans,
  [THEME.DENTOLO_LEFT_ALIGN_THEME]: palette.font_family_1,
});
