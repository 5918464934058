import polyfillReactIntl from '../locales/polyfillReactIntl';

export const polyfillReactIntlWithErrorReporting = async (locale, monitoringService) => {
  try {
    await polyfillReactIntl(locale);
  } catch (error) {
    const name = `PolyfillError${error?.message ? ` - ${error?.message}` : ''}`;

    // log error to datadog
    monitoringService.logger.error(name, { errorDetails: { details: error } });
  }
};

export const initializeShadowDOM = (rootElement) => {
  if (typeof rootElement?.attachShadow !== 'function') return null;

  const shadowRoot = rootElement.attachShadow({ mode: 'open' });
  const styleTag = document.createElement('style');

  styleTag.textContent = ':host { all: initial; }';
  shadowRoot.appendChild(styleTag);

  return shadowRoot;
};
