import { DENTOLO, PETOLO, VITOLO } from '../../features/insuranceType/InsuranceTypes';
import useInsuranceType from '../../features/insuranceType/useInsuranceType';
import useDentoloLeadAPI from './useDentoloLeadAPI';
import usePetoloLeadAPI from './usePetoloLeadAPI';

const useLeadAPI = () => {
  const insuranceType = useInsuranceType();
  const dentoloLeadAPIs = useDentoloLeadAPI();
  const petoloLeadAPIs = usePetoloLeadAPI();

  switch (insuranceType) {
    case DENTOLO:
      return dentoloLeadAPIs;
    case PETOLO:
      return petoloLeadAPIs;
    case VITOLO:
      return dentoloLeadAPIs;
    default:
      return dentoloLeadAPIs;
  }
};

export default useLeadAPI;
