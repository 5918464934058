import { DENTOLO, PETOLO } from '../../insuranceType/InsuranceTypes';
import useInsuranceType from '../../insuranceType/useInsuranceType';
import useDentoloPolicyPriceAPI from './useDentoloPolicyPriceAPI';
import usePetoloPolicyPriceAPI from './usePetoloPolicyPriceAPI';

const usePolicyPriceAPI = () => {
  const insuranceType = useInsuranceType();

  switch (insuranceType) {
    case DENTOLO:
      return useDentoloPolicyPriceAPI;
    case PETOLO:
      return usePetoloPolicyPriceAPI;
    default:
      return useDentoloPolicyPriceAPI;
  }
};

export default usePolicyPriceAPI;
