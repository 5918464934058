import { useSelector } from 'react-redux';
import useDtlTracker from './useDtlTracker';
import useSurveyId from '../schema/useSurveyId';
import useUuid from '../customer/useUuid';
import useWindow from '../../hooks/useWindow';
import useGduid from './useGduid';
import { extractQueryParams } from '../../utils/URLHelper';

const useSchemaTracking = (page) => {
  const [, , /* trackDtlEvents */ /* trackDtlPageLoad */ trackDtlUserData] = useDtlTracker(page);

  const surveryId = useSurveyId();
  const uuid = useUuid();
  const getGduid = useGduid();

  const pages = useSelector((state) => state?.schema?.activeSchema?.SCHEMA?.pages);
  const validatedPages = Array.isArray(pages) ? pages : [];
  const pageId = page?.pageId;
  const pageNumber = validatedPages.findIndex((p) => p?.pageId === pageId) + 1;

  const [getFromWindow] = useWindow();
  const location = getFromWindow('location');

  const schemaHash = useSelector((state) => state?.schema?.schemaHash);

  const trackPageForSchema = () => {
    const gduid = getGduid();
    const userData = {
      eventType: 'click',
      gduid,
      page_id: pageId,
      page_number: pageNumber,
      schema_hash: schemaHash,
      survey_id: surveryId,
      url: location?.href,
      params_string: extractQueryParams(location),
      uuid,
    };
    trackDtlUserData(userData);
  };

  return trackPageForSchema;
};

export default useSchemaTracking;
