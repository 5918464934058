import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useWindow from '../../hooks/useWindow';
import useSurveyId from '../schema/useSurveyId';

const useGoogleAnalyticsTracker = (page) => {
  // The event will be sent when this is set to true
  const [triggerSendEvent, setTriggerSendEvent] = useState(false);

  const [getFromWindow] = useWindow();

  const surveyId = useSurveyId();

  const pageId = page?.pageId || '';
  const pageDescription = page?.description || '';

  const questions = useSelector((state) => state?.questions?.answers);
  const answerForTheQuestionOnThisPage = pageId ? questions[`${pageId}`] || '' : '';

  useEffect(() => {
    if (triggerSendEvent) {
      const ga = getFromWindow('ga');
      if (typeof ga === 'function') {
        ga(
          'send',
          'event',
          surveyId,
          `${pageId} - ${pageDescription}`,
          answerForTheQuestionOnThisPage
        );
      }
      setTriggerSendEvent(false);
    }
  }, [
    triggerSendEvent,
    getFromWindow,
    surveyId,
    pageId,
    pageDescription,
    answerForTheQuestionOnThisPage,
  ]);

  const trackGAEvents = () => {
    setTriggerSendEvent(true);
    return Promise.resolve(null);
  };

  return trackGAEvents;
};

export default useGoogleAnalyticsTracker;
